import { Injectable } from '@angular/core';
import { ListsOverlayService } from '@app/lists/services/lists-overlay.service';
import { ActionSheetController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PanAppState, PlatformEnum, PlatformService } from '@panamax/app-state';
import { customerIsMslRestricted } from '@shared/helpers/customer.helpers';
import { Customer } from '@usf/customer-types';
import {
  MASTER_LIST_ACTIONS,
  MASTER_LIST_ITEM_ACTIONS,
  MasterListState,
  ListTracingConstants,
  displayListDetailsTransformer,
  selectMasterListItemState,
  DownloadListOptions,
  masterListItemsLoaded,
} from '@usf/ngrx-list';
import { State } from 'src/app/store';
import { EllipsisOptionsPopoverComponent } from '../../../../shared/components/popovers/ellipsis-options/ellipsis-options-popover.component';
import {
  ListRow,
  ProductRow,
} from '../../../shared/list-detail-management/model/list-detail-management-view.model';
import { createMasterListItemProductKey } from '../selectors/helpers/master-list.selectors.helpers';
import {
  mslProductLoadCheck,
  selectProductNumbersFromMasterList,
  selectMasterListStatesAreLoaded,
  selectMasterListViewModel,
} from '../selectors/master-list.selectors';
import { LoadingSpinnerService } from '../../../../shared/services/loading-spinner/loading-spinner.service';
import { FEATURES } from '../../../../shared/constants/splitio-features';
import { combineLatest, filter, map, Observable, switchMap, take } from 'rxjs';
import { MasterListViewModel } from '../model/master-list-view.model';
import { getLoadingStateOfProducts, LoadingState } from '@usf/ngrx-product';
import { isPricingLoaded } from '../../../../shared/selectors/product.selectors';

@Injectable({
  providedIn: 'root',
})
export class MasterListService {
  constructor(
    private store: Store<State>,
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    readonly platformService: PlatformService,
    private translateService: TranslateService,
    readonly panAppState: PanAppState,
    private listsOverlayService: ListsOverlayService,
    private loadingSpinnerService: LoadingSpinnerService,
  ) {}

  selectMasterListViewModel = (listId: number, platformType: PlatformEnum) => {
    const masterListViewModel$ = this.store.select(
      selectMasterListViewModel(listId, platformType),
    );
    const orderMinimumMetFeatureFlag$ = this.panAppState.feature$([
      FEATURES.split_global_min_order_met,
    ]);
    return combineLatest([
      masterListViewModel$,
      orderMinimumMetFeatureFlag$,
    ]).pipe(
      map(([masterListViewModel, orderMinimumMetFeatureFlag]) => {
        return {
          ...masterListViewModel,
          orderMinimumMetFeatureFlag,
        } as MasterListViewModel;
      }),
    );
  };

  setShowAlternates(listItemProductKeys: string[], value: boolean) {
    this.store.dispatch(
      MASTER_LIST_ITEM_ACTIONS.setIsShowingAlternates({
        listItemProductKeys,
        value,
      }),
    );
  }

  selectMasterListItemProductState() {
    return this.store.select(selectMasterListItemState);
  }

  selectMasterListStatesAreLoaded() {
    return this.store.select(selectMasterListStatesAreLoaded());
  }

  findAllListItemProductKeysWithSecondaries(items: ListRow[]): string[] {
    const masterListItemProductKeys: string[] = [];
    items?.forEach(item => {
      const masterListProductRow: ProductRow = item as ProductRow;
      if (masterListProductRow?.hasAlternatesToShow) {
        const masterListItemProductKey = createMasterListItemProductKey(
          masterListProductRow.masterListItem.listId,
          masterListProductRow.masterListItem.listGroupId,
          masterListProductRow.masterListItem.listItemId,
          masterListProductRow.masterListItem.listItemProductId,
        );
        masterListItemProductKeys.push(masterListItemProductKey);
      }
    });
    return masterListItemProductKeys;
  }

  async openListOptions(
    event: Event,
    items: ListRow[],
    showingAllSecondaries: boolean,
    list: MasterListState,
    selectedCustomer: Customer,
    downloadPrintFlag: boolean,
    secondariesExist: boolean,
  ) {
    event.stopPropagation();
    const masterListItemProductKeys =
      this.findAllListItemProductKeysWithSecondaries(items);
    const buttons: any[] = await this.constructBannerButtons(
      showingAllSecondaries,
      masterListItemProductKeys,
      list,
      selectedCustomer,
      downloadPrintFlag,
      secondariesExist,
    );
    this.presentBannerOptions(event, buttons);
  }

  async dismissOptions() {
    if (this.platformService.isTouch.value) {
      await this.actionSheetCtrl.dismiss();
    } else {
      await this.popoverCtrl.dismiss();
    }
  }

  downloadMasterList = (downloadListOptions: DownloadListOptions) => {
    this.loadingSpinnerService.createSpinnerModal().then(() => {
      this.store.dispatch(
        MASTER_LIST_ACTIONS.downloadMasterList({ downloadListOptions }),
      );
    });
  };

  async constructBannerButtons(
    showingAllSecondaries: boolean,
    masterListItemProductKeys: string[],
    list: MasterListState,
    selectedCustomer: Customer,
    downloadPrintFlag: boolean,
    secondariesExist: boolean,
  ): Promise<any[]> {
    const buttons: any[] = [];
    if (
      downloadPrintFlag &&
      this.platformService.platformType === PlatformEnum.mobile
    ) {
      buttons.push(
        {
          text: this.translateService.instant('i18n.common.download'),
          handler: () => {
            this.listsOverlayService.openDownloadListModal(
              list.listId,
              'ML',
              list.listName,
            );
            this.dismissOptions();
          },
        },
        {
          text: this.translateService.instant('i18n.lists.print'),
          handler: () => {
            this.listsOverlayService.openPrintListModal(
              list.listId,
              'ML',
              list.listName,
              selectedCustomer.customerNumber,
            );
            this.dismissOptions();
          },
        },
      );
    }

    const copyFlag = await this.panAppState.featureEnabled(
      FEATURES.split_global_copy_list,
    );

    if (copyFlag) {
      buttons.push({
        text: this.translateService.instant('i18n.lists.copy'),
        handler: () => {
          this.listsOverlayService.openCopyListModal(list);
          this.listsOverlayService.dismissListOptions();
        },
      });
    }

    if (secondariesExist && !customerIsMslRestricted(selectedCustomer)) {
      if (showingAllSecondaries) {
        buttons.unshift({
          text: this.translateService.instant(
            'i18n.lists.hideAlternateProducts',
          ),
          handler: () => {
            this.setShowAlternates(masterListItemProductKeys, false);
            this.dismissOptions();
          },
        });
      } else {
        buttons.unshift({
          text: this.translateService.instant(
            'i18n.lists.showAlternateProducts',
          ),
          handler: () => {
            this.setShowAlternates(masterListItemProductKeys, true);
            this.dismissOptions();
          },
        });
      }
    }
    return buttons;
  }

  private async presentBannerOptions(event: any, buttons: any[]) {
    if (!this.platformService.isTouch.value) {
      const listOptionsPopover = await this.popoverCtrl.create({
        component: EllipsisOptionsPopoverComponent,
        event,
        componentProps: {
          buttons,
        },
        cssClass: 'ellipsis-options-popover-desktop',
        mode: 'ios',
        showBackdrop: false,
      });
      await listOptionsPopover.present();
    } else {
      buttons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
      const listOptionsActionSheet = await this.actionSheetCtrl.create({
        cssClass: 'action-sheet-touch',
        mode: 'ios',
        buttons,
      });
      await listOptionsActionSheet.present();
    }
  }

  searchInMasterList(searchKey: string) {
    this.store.dispatch(
      MASTER_LIST_ACTIONS.searchInMasterList({
        searchKey,
      }),
    );
  }

  sortAndFilterInMasterList(
    sortType: string,
    groupToSort: string,
    filters: string[],
  ) {
    this.store.dispatch(
      MASTER_LIST_ACTIONS.sortAndFilterMasterList({
        sortType,
        groupToSort,
        filters,
      }),
    );
  }

  isMasterListAndProductDataLoaded$ = (listId: string): Observable<boolean> => {
    return combineLatest([
      this.store.select(selectProductNumbersFromMasterList(Number(listId))),
      this.store.select(masterListItemsLoaded()),
      this.store.select(mslProductLoadCheck),
    ]).pipe(
      filter(
        ([productNumbers, masterListItems, mslProductsAreLoaded]) =>
          masterListItems && mslProductsAreLoaded,
      ),
      switchMap(([productNumbers, listsAreLoaded]) =>
        this.store.select(getLoadingStateOfProducts(productNumbers)).pipe(
          map((loadingState: LoadingState) => {
            return (
              loadingState === LoadingState.loaded ||
              loadingState === LoadingState.error
            );
          }),
        ),
      ),
    );
  };

  isMasterListAndPricingDataLoaded$ = (listId: string): Observable<boolean> => {
    return combineLatest([
      this.store.select(selectProductNumbersFromMasterList(Number(listId))),
      this.store.select(masterListItemsLoaded()),
      this.store.select(mslProductLoadCheck),
    ]).pipe(
      filter(
        ([productNumbers, masterListItems, mslProductsAreLoaded]) =>
          masterListItems && mslProductsAreLoaded,
      ),
      switchMap(([productNumbers, listsAreLoaded]) =>
        combineLatest([
          this.store.select(getLoadingStateOfProducts(productNumbers)),
          this.store.select(isPricingLoaded(productNumbers)),
        ]).pipe(
          map(([productLoadingState, pricesAreLoaded]) => {
            return (
              (productLoadingState === LoadingState.loaded ||
                productLoadingState === LoadingState.error) &&
              pricesAreLoaded
            );
          }),
        ),
      ),
    );
  };

  trackDisplayListDetails(listKey: { listTypeId: string; listId: string }) {
    this.store.dispatch(
      MASTER_LIST_ACTIONS.displayMasterListDetailsSuccess({
        tracking: {
          tracing: {
            data: {
              traceContext: ListTracingConstants.displayListDetails,
              isEndOfTrace: true,
              isStartOfTrace: false,
              attributes: {
                event:
                  ListTracingConstants.displayMasterListDetailsEvent +
                  ListTracingConstants.eventSuccess,
                spanName:
                  ListTracingConstants.displayMasterListDetailsSpan +
                  ListTracingConstants.spanSuccess,
                listType: listKey.listTypeId,
                listId: listKey.listId,
              },
            },
            transformFunc: displayListDetailsTransformer,
          },
        },
      }),
    );
  }
}
