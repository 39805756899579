import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UsfTokenStorageService } from '@panamax/app-state';
import { Division, DivisionPhoneNumber } from '@usf/customer-types';
import { Customer } from '@usf/customer-types/customer';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { CustomerSwitchStatus } from '../../constants/customer-switch-status';
import { CustomerDivisionDepartment } from '../../models/customer-division-department.model';
import {
  CUSTOMER_SWITCH_ACTIONS,
  DIVISION_PHONE_NUMBER_ACTIONS,
} from '../../store';
import {
  getCustomers,
  selectCustomerSwitchStatus,
  selectCustomerWithNumber,
  selectedCustomer,
} from '../../store/selectors/customer.selectors';
import {
  getDivisions,
  selectedCustomerDivision,
} from '../../store/selectors/division.selectors';
import {
  selectDivisionPhoneNumbersLoading,
  selectPhoneNumbersByDivisionNumber,
} from '../../store/selectors/division-phone-number.selectors';

@Injectable({
  providedIn: 'root',
})
export class CustomerStoreService {
  constructor(
    private store: Store,
    private tokenStorageService: UsfTokenStorageService,
  ) {}

  // OBSERVABLE SELECTORS
  loadCustomers$(): Observable<Customer[]> {
    return this.store.select(getCustomers);
  }

  loadSelectedCustomer$(): Observable<Customer> {
    return this.store.select(selectedCustomer);
  }

  loadSelectedCustomerDivision$(): Observable<Division> {
    return this.store.select(selectedCustomerDivision);
  }

  customerWithNumber$(customerNumber: number): Observable<Customer> {
    return this.store.select(selectCustomerWithNumber(customerNumber));
  }

  customerDeliveryDaysWithNumber(customerNumber: number): Observable<number[]> {
    const customer = selectCustomerWithNumber(customerNumber);
    if (!customer) return null;
    return this.store.select(customer).pipe(
      filter(customer => !!customer),
      take(1),
      map(customer => {
        const days = new Array(
          customer.sunDelivery,
          customer.monDelivery,
          customer.tueDelivery,
          customer.wedDelivery,
          customer.thuDelivery,
          customer.friDelivery,
          customer.satDelivery,
        );
        return days.reduce((accumulator, currentValue, currentIndex) => {
          if (currentValue) {
            accumulator.push(currentIndex);
          }
          return accumulator;
        }, []);
      }),
    );
  }

  selectedCustomerDeliveryDays(): Observable<number[]> {
    return this.store.select(selectedCustomer).pipe(
      filter(customer => !!customer),
      take(1),
      map(customer => {
        const days = new Array(
          customer.sunDelivery,
          customer.monDelivery,
          customer.tueDelivery,
          customer.wedDelivery,
          customer.thuDelivery,
          customer.friDelivery,
          customer.satDelivery,
        );
        return days.reduce((accumulator, currentValue, currentIndex) => {
          if (currentValue) {
            accumulator.push(currentIndex);
          }
          return accumulator;
        }, []);
      }),
    );
  }

  selectedCustomerProntoDeliveryDays$(): Observable<number[]> {
    return this.store.select(selectedCustomer).pipe(
      filter(customer => !!customer),
      take(1),
      map(customer => {
        if (customer.prontoEnabledFlag !== 'Y') return undefined;
        const days = new Array(
          customer.sunCutoff === 9,
          customer.monCutoff === 9,
          customer.tueCutoff === 9,
          customer.wedCutoff === 9,
          customer.thuCutoff === 9,
          customer.friCutoff === 9,
          customer.satCutoff === 9,
        );
        return days.reduce((accumulator, currentValue, currentIndex) => {
          if (currentValue) {
            accumulator.push(currentIndex);
          }
          return accumulator;
        }, []);
      }),
    );
  }

  loadCustomerWithNumber$(customerNumber: number): Observable<Customer> {
    return this.loadCustomers$().pipe(
      mergeMap(customers => customers),
      filter(customer => customer.customerNumber === customerNumber),
    );
  }

  loadDivisions$(): Observable<Division[]> {
    return this.store.select(getDivisions);
  }

  loadDivisionByDivisionNumber$(divisionNumber: number): Observable<Division> {
    return this.loadDivisions$().pipe(
      mergeMap(divisions => divisions),
      filter(division => division.divisionNumber === divisionNumber),
    );
  }

  loadCustomersByDivisionNumber$(
    divisionNumber: number,
  ): Observable<Customer[]> {
    return this.loadCustomers$().pipe(
      map(customers =>
        customers.filter(
          customer => customer.divisionNumber === divisionNumber,
        ),
      ),
    );
  }

  customersWithDivision$(divisionNumber: number): Observable<number> {
    return this.loadCustomers$().pipe(
      map(customers =>
        customers.reduce((accumulator, currentValue) => {
          if (currentValue.divisionNumber === divisionNumber) {
            accumulator++;
          }
          return accumulator;
        }, 0),
      ),
    );
  }

  selectCustomerDivisionDepartment$(): Observable<CustomerDivisionDepartment> {
    return combineLatest([
      this.loadSelectedCustomer$(),
      this.loadDivisions$(),
      this.tokenStorageService.getContext(),
    ]).pipe(
      map(([customer, divisions, tokenAuthContext]) => {
        let divisionName;
        let departmentName;
        for (const division of divisions) {
          if (division.divisionNumber === customer.divisionNumber) {
            divisionName = division.divisionName;
            break;
          }
        }
        for (const department of customer.departments) {
          if (
            department.departmentNumber ===
            tokenAuthContext.departmentNumber?.toString()
          ) {
            departmentName = department.departmentName;
            break;
          }
        }
        return {
          customerName: customer.customerName,
          customerNumber: customer.customerNumber,
          divisionName,
          divisionNumber: customer.divisionNumber,
          departmentName,
          departmentNumber: tokenAuthContext.departmentNumber,
        } as CustomerDivisionDepartment;
      }),
    );
  }

  customerSwitchStatus$(): Observable<CustomerSwitchStatus> {
    return this.store.select(selectCustomerSwitchStatus);
  }

  divisionPhoneNumber$(
    divisionNumber: number,
  ): Observable<DivisionPhoneNumber> {
    return this.store.select(
      selectPhoneNumbersByDivisionNumber(divisionNumber),
    );
  }

  divisionPhoneNumberLoading$(): Observable<boolean> {
    return this.store.select(selectDivisionPhoneNumbersLoading);
  }

  // ACTIONS
  setCustomerSwitchStatus(customerSwitchStatus: CustomerSwitchStatus) {
    this.store.dispatch(
      CUSTOMER_SWITCH_ACTIONS.setCustomerSwitchStatus({ customerSwitchStatus }),
    );
  }

  resetCustomerSwitchStatus() {
    this.store.dispatch(CUSTOMER_SWITCH_ACTIONS.resetCustomerSwitchStatus());
  }

  loadDivisionPhoneNumbers() {
    this.store.dispatch(
      DIVISION_PHONE_NUMBER_ACTIONS.getAllDivisionPhoneNumbers(),
    );
  }
}
