import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PRODUCT_PRICING_ACTIONS } from '@usf/ngrx-pricing';
import {
  BetterBuysActions,
  selectMslProductStateLoaded,
} from '@usf/ngrx-product';
import { ProductPricing } from '@usf/price-types';
import { BetterBuy, BetterBuysResponse } from '@usf/product-types';
import { combineLatest, of } from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeWhile,
  tap,
} from 'rxjs/operators';
import { selectAppStateSelectedCustomer } from '../../ngrx-customer/store';
import {
  customerIsMslRestricted,
  customersAreSameContext,
} from '../../shared/helpers/customer.helpers';
import { Product } from '../../shared/models/product.model';
import { productSelector } from '../../shared/selectors/product.selectors';
import { mslProductHasSecondaries } from '../../shared/selectors/helpers/product-info.selectors.helper';
import { DownloadProductsService } from '@shared/services/document/download-products/download-products.service';
import { ListUpdatesService } from '@app/lists/services/list-updates.service';
import { selectCustomer } from '@panamax/app-state';
@Injectable({
  providedIn: 'root',
})
export class ClientBetterBuyEffects {
  annualSavingsLoadedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BetterBuysActions.savePricing),
      map(action => {
        const productNumbers: number[] = [];
        const productPricing: ProductPricing[] = [];
        for (const betterBuy of action.betterBuysResponse.betterBuys) {
          const originalProductPrice = betterBuy.originalProductPrice;
          const betterBuyProductPrice = betterBuy.betterBuyProductPrice;
          productNumbers.push(
            betterBuy.originalProductNumber,
            betterBuy.betterBuyProductNumber,
          );
          productPricing.push(originalProductPrice, betterBuyProductPrice);
        }
        return PRODUCT_PRICING_ACTIONS.fetchProductPricingSuccess({
          requestedProductNumbers: productNumbers,
          productPricing,
          effectiveDate: Number(action.betterBuysResponse.priceEffectiveDate),
          effectiveTime: Number(action.betterBuysResponse.priceEffectiveTime),
        });
      }),
    ),
  );

  filterOutRestrictedBetterBuys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BetterBuysActions.filterOutRestrictedBetterBuys),
      switchMap(action => {
        const customer$ = this.store.select(selectAppStateSelectedCustomer);
        const products$ = this.store.select(productSelector);
        const masterListItemsAreLoaded$ = this.store.select(
          selectMslProductStateLoaded,
        );
        return combineLatest([
          of(action),
          customer$,
          products$,
          masterListItemsAreLoaded$,
        ]).pipe(
          filter(([action, customer, productsMap, loaded]) => {
            if (customerIsMslRestricted(customer)) {
              return loaded;
            }
            return true;
          }),
          take(1),
          takeWhile(([action, customer, productsMap, loaded]) =>
            customersAreSameContext(action.selectedCustomer, customer),
          ),
        );
      }),
      map(([action, customer, productsMap, loaded]) => {
        let updatedResponse = action.betterBuysResponse;
        if (customerIsMslRestricted(customer)) {
          updatedResponse = this.filterOutNonMasterListPrimaryProducts(
            updatedResponse,
            productsMap,
          );
        }
        return BetterBuysActions.filterOutRestrictedBetterBuysSuccess({
          betterBuysResponse: updatedResponse,
          selectedCustomer: action.selectedCustomer,
        });
      }),
    ),
  );

  downloadProducts$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BetterBuysActions.downloadProductConversion),
        // Load products and prices
        tap(action => {
          let products = [];
          action.products.forEach(element => {
            products.push(
              ...[element.originalProduct, element.replacementProduct],
            );
          });
        }),
        mergeMap(action => {
          let products = [];
          action.products.forEach(element => {
            products.push(
              ...[element.originalProduct, element.replacementProduct],
            );
          });
          return combineLatest([
            of(action),
            this.listupdatesService.getListUpdatesWithDetails$(products),
            this.store.select(selectCustomer()),
          ]).pipe(take(1));
        }),
        tap(([action, viewModel, customer]) => {
          let downloadableProducts = [];
          viewModel.productConversionContainers.forEach(container => {
            downloadableProducts.push({
              ...container.originalProduct,
              isOriginal: true,
            });
            downloadableProducts.push({
              ...container.replacementProduct,
              isOriginal: false,
            });
          });
          const fileName = 'List Updates_' + customer.customerNumber;
          this.downloadProductSerice.downloadProducts(
            'CSV',
            fileName,
            downloadableProducts,
            true,
          );
        }),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private downloadProductSerice: DownloadProductsService,
    private listupdatesService: ListUpdatesService,
  ) {}

  filterOutNonMasterListPrimaryProducts(
    bbResponse: BetterBuysResponse,
    productsMap: Map<number, Product>,
  ): BetterBuysResponse {
    const updatedBetterBuys: BetterBuy[] = [];
    for (const betterBuy of bbResponse.betterBuys) {
      const product = productsMap.get(betterBuy.betterBuyProductNumber);
      if (
        (product && mslProductHasSecondaries(product?.mslProduct)) ||
        betterBuy?.betterBuyProductDetails?.summary?.mslUnrestrict
      ) {
        updatedBetterBuys.push(betterBuy);
      }
    }
    return { ...bbResponse, betterBuys: updatedBetterBuys };
  }
}
