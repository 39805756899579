import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import {
  ModalController,
  PopoverController,
  ViewWillEnter,
} from '@ionic/angular';
import {
  AuthConnectService,
  PanAppState,
  PlatformService,
} from '@panamax/app-state';
import { UpcService } from '@shared/services/upc/upc.service';
import { Observable, Subscription, combineLatest, map, take } from 'rxjs';
import { PunchoutReturnLinkData } from '../../../../punchout/models/punchout-return-link-data.model';
import { PunchoutService } from '../../../../punchout/services/punchout.service';
import { UserService } from '../../../../user/services/user.service';
import { PATHS } from '../../../constants/paths';
import { AppService } from '../../../services/app.service';
import { InviteNewUserService } from '@shared/services/invite-new-user/invite-new-user.service';
import { InviteUserModalComponent } from '@shared/components/invite-user-modal/invite-user-modal.component';
import { ModalWithNavComponent } from '@shared/components/modal-with-nav/modal-with-nav.component';
import { ModalService } from '@shared/services/modal/modal.service';
import { FEATURES } from '@shared/constants/splitio-features';

@Component({
  selector: 'app-user-popover',
  templateUrl: './user-popover.component.html',
  styleUrls: ['./user-popover.component.scss'],
})
export class UserPopoverComponent implements ViewWillEnter, OnDestroy {
  readonly PATHS = PATHS;
  punchoutReturnLinkData: PunchoutReturnLinkData;
  getInviteNewUserVisibility: boolean;
  isSuperUser = false;
  combinedSub$: Subscription = new Subscription();
  supportCenterFlag$: Observable<boolean>;

  constructor(
    private router: Router,
    private popoverController: PopoverController,
    readonly panAppState: PanAppState,
    private appService: AppService,
    private authConnectService: AuthConnectService,
    private punchoutService: PunchoutService,
    private upcService: UpcService,
    private inviteNewUserService: InviteNewUserService,
    private modalService: ModalService,
    protected modalController: ModalController,
    readonly platformService: PlatformService,
    private userService: UserService,
  ) {}

  ionViewWillEnter() {
    this.combinedSub$ = combineLatest([
      this.punchoutService.punchoutReturnLinkData$(),
      this.inviteNewUserService.getInviteNewUserVisibility(),
      this.panAppState?.feature$([FEATURES.split_global_alerts_super_user]),
      this.userService.isSuperUser$(),
    ])
      .pipe(
        map(
          ([
            punchoutReturnLinkData,
            getInviteNewUserVisibility,
            flagEnabled,
            isSuperUser,
          ]) => {
            return {
              punchoutReturnLinkData,
              getInviteNewUserVisibility,
              flagEnabled,
              isSuperUser,
            };
          },
        ),
        take(1),
      )
      .subscribe(res => {
        this.punchoutReturnLinkData = res.punchoutReturnLinkData;
        this.getInviteNewUserVisibility = res.getInviteNewUserVisibility;
        if (res.flagEnabled) {
          this.isSuperUser = res.isSuperUser;
        }
      });
    this.supportCenterFlag$ = this.panAppState.feature$([
      FEATURES.split_global_support_center,
    ]);
  }

  ngOnDestroy() {
    if (this.combinedSub$) {
      this.combinedSub$.unsubscribe();
    }
  }

  openUPC() {
    this.upcService.openUPC();
  }

  async logout(): Promise<void> {
    await this.popoverController.dismiss();
    await this.authConnectService.logout();
  }

  async routeTo(path: string): Promise<void> {
    await this.popoverController.dismiss();
    this.router.navigate([path]);
  }

  returnToPartnerSite(partnerSiteLink: string) {
    window.location.replace(partnerSiteLink);
  }

  async dismiss() {
    await this.popoverController.dismiss();
  }

  async openInviteNewUserModal() {
    this.modalService.setModalOptions(
      this.platformService.platformType ===
        this.platformService.platformEnum.mobile,
      ModalWithNavComponent,
      {
        rootPage: InviteUserModalComponent,
      },
      'invite-new-user-modal',
    );
    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    this.inviteNewUserService.trackInviteNewUserModalOpen();
    return await modal.present();
  }
}
