import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import {
  PanAppState,
  PlatformService,
  SelectedCustomerState,
} from '@panamax/app-state';
import { InviteNewUserService } from '@shared/services/invite-new-user/invite-new-user.service';
import { User } from '@usf/user-types/user';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { InviteExistingUserModalComponent } from '../invite-existing-user-modal/invite-existing-user-modal.component';
import { ToastService } from '@shared/services/toast/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { InviteNewUserModalComponent } from '../invite-new-user-modal/invite-new-user-modal.component';

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss'],
})
export class InviteUserModalComponent implements OnInit, OnDestroy {
  @ViewChild('inviteNewUserTemplate', { static: true })
  inviteNewUserTemplate: TemplateRef<any>;
  @ViewChild('inviteUserTypeSelectionTemplate', { static: true })
  inviteUserTypeSelectionTemplate: TemplateRef<any>;
  @ViewChild('inviteExistingUserTemplate', { static: true })
  inviteExistingUserTemplate: TemplateRef<any>;
  @ViewChild('existingUserModal')
  inviteExistingUserModal!: InviteExistingUserModalComponent;
  @ViewChild('newUserModal')
  inviteNewUserModal!: InviteNewUserModalComponent;

  inviteNewUserForm: FormGroup;
  inviteUserForm: FormGroup;

  inviteUserFlag$: Observable<boolean>;
  customer: SelectedCustomerState;
  selectedUser: User;
  selectedCustomers = [];

  modalTitle = 'i18n.profilePage.inviteUser.inviteAUser';

  mobileExistingUserInfoSelected = false;
  mobileExistingCustomersSelected = false;
  mobileNewUserInfoSelected = false;
  mobileNewCustomersSelected = false;
  inviteUserFlag = false;

  public destroy$ = new Subject<void>();

  customerInfo: Array<{ label: string; value: string | number }>;
  currentView:
    | 'inviteNewUser'
    | 'inviteUserTypeSelection'
    | 'inviteExistingUser' = 'inviteUserTypeSelection';
  userTypes = [
    {
      value: 'newUser',
      cy: 'radio-newUser',
      label: 'i18n.profilePage.inviteUser.newUser',
    },
    {
      value: 'existingUser',
      cy: 'radio-existingUser',
      label: 'i18n.profilePage.inviteUser.existingUser',
    },
  ];

  constructor(
    public platformService: PlatformService,
    public inviteNewUserService: InviteNewUserService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private panAppState: PanAppState,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.inviteUserFlag$ = this.inviteNewUserService.inviteUserFlag$();
    this.inviteNewUserService.setupInviteUserSubscriptions();
    this.initForms();

    this.inviteUserFlag$
      .pipe(
        takeUntil(this.destroy$),
        tap(flag => (this.inviteUserFlag = flag)),
      )
      .subscribe();

    this.panAppState.customer$
      .pipe(
        takeUntil(this.destroy$),
        filter(cust => !!cust),
      )
      .subscribe(customer => {
        if (customer) {
          this.customer = customer;
          this.updateCustomerInfo(customer);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public initForms() {
    this.inviteNewUserForm = this.fb.group({
      newUserEmailAddress: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
    });
    this.inviteUserForm = this.fb.group({
      inviteUserType: ['', Validators.required],
    });
  }

  public updateCustomerInfo(customer: SelectedCustomerState) {
    this.customerInfo = [
      {
        label: 'i18n.profilePage.inviteNewUser.modalCustomerName',
        value: customer.customerName,
      },
      {
        label: 'i18n.profilePage.inviteNewUser.modalCustomerNumber',
        value: customer.customerNumber,
      },
      {
        label: 'i18n.profilePage.inviteNewUser.modalDivisionNumber',
        value: customer.divisionNumber,
      },
    ];
  }

  selectExistingUser({ user, customers }: { user: User; customers: string[] }) {
    this.selectedUser = user;
    this.selectedCustomers = customers;
  }

  selectNewUser({
    newUserEmail,
    customers,
  }: {
    newUserEmail: string;
    customers: [];
  }) {
    this.selectedCustomers = customers;
    this.inviteNewUserForm.get('newUserEmailAddress')?.setValue(newUserEmail);
  }

  resetMobileOptions(isNewUser: boolean) {
    if (isNewUser) {
      this.mobileNewUserInfoSelected = false;
      this.mobileNewCustomersSelected = false;
      this.inviteNewUserModal?.clearSelectedOption();
    } else {
      this.mobileExistingUserInfoSelected = false;
      this.mobileExistingCustomersSelected = false;
      this.inviteExistingUserModal?.clearSelectedOption();
    }
  }

  async dismiss(isMobileSection?: boolean, isNewUser?: boolean) {
    this.inviteUserForm.reset();
    if (isMobileSection) {
      this.resetMobileOptions(isNewUser);
    } else {
      this.inviteNewUserForm.reset();
      await this.modalController.dismiss(true);
    }
  }

  onInviteNewUser() {
    if (!this.inviteUserFlag) {
      if (this.inviteNewUserForm?.valid && this.customer) {
        this.inviteNewUserService.inviteNewUser(
          this.inviteNewUserForm.get('newUserEmailAddress')?.value,
          [
            {
              customerNumber: this.customer?.customerNumber,
              divisionNumber: this.customer?.divisionNumber,
            },
          ],
        );
        this.dismiss();
      }
    } else {
      if (this.inviteNewUserForm?.valid && this.selectedCustomers) {
        this.inviteNewUserService.inviteNewUser(
          this.inviteNewUserForm.get('newUserEmailAddress')?.value,
          this.selectedCustomers,
        );
        this.dismiss();
      }
    }
  }

  onMobileExistingUserInformationNext() {
    this.mobileExistingUserInfoSelected = false;
    this.inviteExistingUserModal.mobileUserInfoSelectedNext();
    this.mobileExistingCustomersSelected = true;
  }

  onMobileExistingCustomersnNext() {
    this.mobileExistingCustomersSelected = false;
    this.inviteExistingUserModal.clearSelectedOption();
  }

  onMobileNewUserInformationNext() {
    this.mobileNewUserInfoSelected = false;
    this.inviteNewUserModal.mobileUserInfoSelectedNext();
    this.mobileNewCustomersSelected = true;
  }

  onMobileNewCustomersnNext() {
    this.mobileNewCustomersSelected = false;
    this.inviteNewUserModal.clearSelectedOption();
  }

  async onNext() {
    const selectedUserType = this.inviteUserForm.get('inviteUserType')?.value;
    if (selectedUserType === 'existingUser') {
      this.modalTitle = 'i18n.profilePage.inviteUser.inviteAnExistingUser';
      this.currentView = 'inviteExistingUser';
      await this.modalController.getTop()?.then(modal => {
        if (modal && this.platformService.platformType === 'desktop') {
          modal.cssClass = 'invite-existing-user-modal';
        }
      });
    } else {
      this.currentView = 'inviteNewUser';
      this.modalTitle = 'i18n.profilePage.inviteNewUser.button';
      await this.modalController.getTop()?.then(modal => {
        if (modal && this.platformService.platformType === 'desktop') {
          modal.cssClass = this.inviteUserFlag
            ? 'invite-existing-user-modal'
            : 'invite-new-user-modal';
        }
      });
    }
  }

  onSubmit(isNew: boolean) {
    if (isNew) {
      this.onInviteNewUser();
    } else {
      console.log(
        `Invite Existing User: ${this.selectedUser?.userName} ${
          this.selectedCustomers?.length
            ? ' with new customers: ' + this.selectedCustomers?.join(', ')
            : ' with no new customers'
        }`,
      );
      this.toastService.presentToastMsg(
        this.translateService.instant(
          'i18n.profilePage.inviteUser.successMsg',
          {
            value: this.selectedUser?.userName,
          },
        ),
        'green-toast',
        MessageTypeEnum.success,
        [],
      );
      this.dismiss();
    }
  }

  isMobile(): boolean {
    return (
      this.platformService.platformType ===
      this.platformService.platformEnum.mobile
    );
  }

  getHeaderClass(): string {
    return `ion-header-modal-${this.platformService.platformType}`;
  }

  getContentClass(): string {
    return `ion-content-modal-${this.platformService.platformType}`;
  }

  getFooterClass(): string {
    return `ion-footer-modal-${this.platformService.platformType}`;
  }

  get currentViewTemplate() {
    if (!this.inviteUserFlag) {
      return this.inviteNewUserTemplate;
    }
    switch (this.currentView) {
      case 'inviteNewUser':
        return this.inviteNewUserTemplate;
      case 'inviteUserTypeSelection':
        return this.inviteUserTypeSelectionTemplate;
      case 'inviteExistingUser':
        return this.inviteExistingUserTemplate;
      default:
        return this.inviteUserTypeSelectionTemplate;
    }
  }

  isInviteNewUserView(): boolean {
    return this.currentView === 'inviteNewUser';
  }

  isInviteUserTypeSelectionView(): boolean {
    return this.currentView === 'inviteUserTypeSelection';
  }
}
