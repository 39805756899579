import { selectAppStateSelectedCustomer } from '@app/ngrx-customer/store';
import { createSelector } from '@ngrx/store';
import { selectOnline } from '@panamax/app-state';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import {
  productDetailViewModelSelector,
  productDetailsViewModelSelector,
} from '@product-detail/selectors/product-detail.selector';
import { getFirstImageURL } from '@product-detail/utils/product-images.util';
import {
  acceptedCount,
  allBetterBuys,
  remainingCount,
  getBetterBuysById,
  BetterBuysState,
} from '@usf/ngrx-product';
import {
  BetterBuyProductDetail,
  BetterBuysPageViewModel,
  BetterBuysPageViewModelV2,
  BetterBuysWithDetails,
} from '../models/better-buys.model';
import {
  ListItemState,
  ListState,
  listAdapter,
  listItemAdapter,
  selectListItemsState,
  selectListsState,
} from '@usf/ngrx-list';
import { Dictionary } from '@ngrx/entity';
import { createListKey } from '../../lists/shared/list-detail-management/selectors/helpers/list-detail-management.selectors.helper';
import { ListItem } from '@usf/list-types';
import { ProductDetailViewModel } from '../../product-detail/models/product-detail-view-model';
import { ProductConversionContainer } from '../../shared/models/product-conversion.model';

// TODO Remove
export const selectBetterBuysWithDetails = (productNumbers: number[]) =>
  createSelector(
    allBetterBuys(),
    productDetailsViewModelSelector(productNumbers),
    selectAppStateSelectedCustomer,
    selectOnline(),
    remainingCount,
    acceptedCount,
    (betterBuys, details, selectedCustomer, isOnline, remaining, accepted) => {
      const betterBuysWithDetails: BetterBuysWithDetails[] = [];
      betterBuys.forEach(betterBuy => {
        const originalProductDetails = details.get(
          betterBuy.originalProductNumber,
        );
        const betterBuyProductDetails = details.get(
          betterBuy.betterBuyProductNumber,
        );
        const originalProduct: BetterBuyProductDetail = {
          ...originalProductDetails,
          smallImageUrl: getFirstImageURL(
            originalProductDetails?.summary,
            ImageVariantEnum.Small,
          ),
          benefits: formatBenefits(originalProductDetails?.detail?.benefits),
        };
        const betterBuyProduct: BetterBuyProductDetail = {
          ...betterBuyProductDetails,
          smallImageUrl: getFirstImageURL(
            betterBuyProductDetails?.summary,
            ImageVariantEnum.Small,
          ),
          benefits: formatBenefits(betterBuyProductDetails?.detail?.benefits),
        };
        const betterBuyWithDetails: BetterBuysWithDetails = {
          ...betterBuy,
          originalProduct,
          betterBuyProduct,
        };
        betterBuysWithDetails.push(betterBuyWithDetails);
      });
      const betterBuysPageViewModel: BetterBuysPageViewModel = {
        betterBuysWithDetails,
        pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
        isOnline,
        remaining,
        accepted,
      };
      return betterBuysPageViewModel;
    },
  );

export const selectBetterBuysViewModelV2 = (productNumbers: number[]) =>
  createSelector(
    selectProductConversionContainerForBetterBuys(productNumbers),
    selectAppStateSelectedCustomer,
    selectOnline(),
    remainingCount,
    acceptedCount,
    (
      productConversionContainers,
      selectedCustomer,
      isOnline,
      remaining,
      accepted,
    ) => {
      const betterBuysPageViewModel: BetterBuysPageViewModelV2 = {
        productConversionContainers,
        pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
        isOnline,
        remaining,
        accepted,
      };
      return betterBuysPageViewModel;
    },
  );

export const selectProductConversionContainerForBetterBuys = (
  productNumbers: number[],
) =>
  createSelector(
    allBetterBuys(),
    productDetailsViewModelSelector(productNumbers),
    (
      betterBuys: BetterBuysState[],
      products: Map<number, ProductDetailViewModel>,
    ) => {
      const productConversionContainers: ProductConversionContainer[] = [];
      betterBuys.forEach(betterBuy => {
        const originalProduct = products.get(betterBuy.originalProductNumber);
        const replacementProduct = products.get(
          betterBuy.betterBuyProductNumber,
        );
        const productConversionContainer: ProductConversionContainer = {
          originalProduct: {
            ...originalProduct,
            smallImageUrl: getFirstImageURL(
              originalProduct?.summary,
              ImageVariantEnum.Small,
            ),
          },
          replacementProduct: {
            ...replacementProduct,
            smallImageUrl: getFirstImageURL(
              replacementProduct?.summary,
              ImageVariantEnum.Small,
            ),
            formattedBenefits: formatBenefits(
              replacementProduct?.detail?.benefits,
            ),
            savings: betterBuy.savings,
            savingsUom: betterBuy.savingsUom,
            annualSavings: betterBuy.annualSavings,
            annualSavingsUom: betterBuy.annualSavingsUom,
            samePackSize:
              replacementProduct?.summary?.salesPackSize ===
              originalProduct?.summary?.salesPackSize,
            reasonCode: 'i18n.betterBuys.productConversionOpportunity',
            pricingAvailableForSavings:
              (replacementProduct?.pricing?.eachPrice > 0 ||
                replacementProduct?.pricing?.unitPrice > 0) &&
              (originalProduct?.pricing?.eachPrice > 0 ||
                originalProduct?.pricing?.unitPrice > 0),
          },
        };
        productConversionContainers.push(productConversionContainer);
      });

      return productConversionContainers;
    },
  );

export const selectBetterBuyDetailsById = (betterBuyProductNumber: number) =>
  createSelector(
    productDetailViewModelSelector(betterBuyProductNumber),
    betterBuyProductDetails => {
      const betterBuyProduct: BetterBuyProductDetail = {
        ...betterBuyProductDetails,
        smallImageUrl: getFirstImageURL(
          betterBuyProductDetails?.summary,
          ImageVariantEnum.Small,
        ),
        benefits: formatBenefits(betterBuyProductDetails?.detail?.benefits),
      };
      return betterBuyProduct;
    },
  );

export const selectBetterBuyListItems = (productNumber: number) =>
  createSelector(
    listAdapter.getSelectors(selectListsState).selectEntities,
    listItemAdapter.getSelectors(selectListItemsState).selectEntities,
    (lists: Dictionary<ListState>, listItems: Dictionary<ListItemState>) => {
      const itemsToSwap: ListItem[] = [];
      for (const listItemKey in listItems) {
        if (listItemKey && listItems[listItemKey] !== undefined) {
          const listItem = listItems[listItemKey];
          const hasSameProductNumber = listItem.productNumber === productNumber;
          const listKey = createListKey(listItem.listKey);
          const list = lists[listKey];
          const isOnPublicList = list?.listState === 'PUBLIC';
          if (hasSameProductNumber && isOnPublicList) {
            itemsToSwap.push(listItem);
          }
        }
      }
      return itemsToSwap;
    },
  );

export const formatBenefits = (benefits: string) => {
  if (benefits) {
    const reg = /\.\s|~\s?/;
    return benefits.split(reg, 3);
  }
};
