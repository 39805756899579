import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageTypeEnum } from '@app/ngrx-message/constants/messageTypeEnum';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ShipToModalSelections } from '@order/models/order-view-model';
import { OrderSubmittedAnalyticsService } from '@order/pages/order-submitted/tracking/analytics/order-submitted-analytics.service';
import { OrderTracingService } from '@order/tracking/tracing/order-tracing.service';
import { selectProductAttributesByIds } from '@shared/selectors/product.selectors';
import { ToastService } from '@shared/services/toast/toast.service';
import {
  MyOrdersStoreService,
  Order,
  OrderItem,
  OrderStoreService,
} from '@usf/ngrx-order';
import {
  BehaviorSubject,
  filter,
  finalize,
  interval,
  Observable,
  of,
  switchMap,
  take,
  takeUntil,
  takeWhile,
  tap,
} from 'rxjs';
import { Product } from 'src/app/shared/models/product.model';

@Injectable({
  providedIn: 'root',
})
export class OrderConversionService {
  private shipToInFlightSubject = new BehaviorSubject<boolean>(false);
  private willCallInFlightSubject = new BehaviorSubject<boolean>(false);
  customerOrdersCallTimeInterval = 10000;

  constructor(
    readonly router: Router,
    private toastService: ToastService,
    private translateService: TranslateService,
    private myOrderStoreService: MyOrdersStoreService,
    private orderStoreService: OrderStoreService,
    private orderTracingService: OrderTracingService,
    private orderSubmittedAnalyticsService: OrderSubmittedAnalyticsService,
    private store: Store,
  ) {}

  isShipToInFlight(): Observable<boolean> {
    return this.shipToInFlightSubject.asObservable();
  }

  setShipToInFlight(inFlight: boolean): void {
    this.shipToInFlightSubject.next(inFlight);
  }

  isWillCallInFlight(): Observable<boolean> {
    return this.willCallInFlightSubject.asObservable();
  }

  setWillCallInFlight(inFlight: boolean): void {
    this.willCallInFlightSubject.next(inFlight);
  }

  submitShipToDetails(
    currentSelections: ShipToModalSelections,
    submittedOrder: Order,
  ): void {
    this.orderSubmittedAnalyticsService.trackShipToSubmit(
      submittedOrder?.orderHeader?.orderId,
    );
    submittedOrder.orderHeader = {
      ...submittedOrder.orderHeader,
      shipToCustomer: currentSelections?.customer?.customerNumber,
      shipToDepartment: currentSelections?.department
        ? parseInt(currentSelections?.department?.departmentNumber)
        : 0,
      shipToDeliveryDate: currentSelections?.updatedDeliveryDate,
      deliveryDateOverride: currentSelections?.isDeliveryDateOverride
        ? 'Y'
        : 'N',
      purchaseOrderNumber:
        currentSelections?.poNumber || currentSelections?.poNumber === ''
          ? currentSelections.poNumber
          : submittedOrder?.orderHeader?.purchaseOrderNumber,
      specialDeliveryInstructions:
        currentSelections?.additionalDeliveryIntructions ||
        submittedOrder?.orderHeader?.specialDeliveryInstructions,
      updateDtm: new Date().toISOString(),
    };
    this.orderStoreService.updateShipToDetails(submittedOrder);
    this.waitForShipToCustomerUpdate(
      currentSelections?.customer?.customerNumber,
      submittedOrder,
    );
  }

  handleShipToConversionResult(
    order: Observable<Order>,
    expectedShipToCustomer: number,
  ) {
    order.pipe(take(1)).subscribe(o => {
      if (o?.orderHeader?.shipToCustomer === expectedShipToCustomer) {
        this.showShipToSuccessToast();
      } else if (this.shipToInFlightSubject.getValue() === true) {
        this.showShipToErrorToast();
      }
      this.getProductMap$(o?.orderItems)
        .pipe(take(1))
        .subscribe(productMap => {
          this.orderTracingService.traceShipToConversionWebsocketResponse(
            o,
            productMap,
            false,
          );
        });
    });
  }

  showShipToSuccessToast(): void {
    this.setShipToInFlight(false);
    this.toastService.presentToastMsg(
      this.translateService.instant(
        'i18n.orderSubmittedPage.shipToModal.shippingInfoUpdated',
      ),
      'green-toast',
      MessageTypeEnum.success,
      [],
    );
  }

  showShipToErrorToast(): void {
    this.setShipToInFlight(false);
    this.toastService.presentToastMsg(
      this.translateService.instant(
        'i18n.orderSubmittedPage.shipToModal.errorTryAgain',
      ),
      'red-toast',
      MessageTypeEnum.warning,
      [],
    );
  }
  waitForShipToCustomerUpdate(
    expectedShipToCustomer: number,
    submittedOrder: Order,
  ) {
    let shipToRetryCount = 0;
    const shipToMaxRetries = 2;
    const fullOrderId =
      `${submittedOrder.orderHeader?.customerNumber}:` +
      `${submittedOrder.orderHeader?.departmentNumber}:` +
      `${submittedOrder.orderHeader?.orderId}`;
    const orderInState = this.myOrderStoreService
      ?.getOrderWithId(fullOrderId)
      ?.pipe(filter(order => !!order));
    let errorDetails = false;
    //If order isn't updated by the websocket event, we make up to 2 customerOrders calls after 10 second intervals.
    const checkOrderUpdates$ = interval(
      this.customerOrdersCallTimeInterval,
    ).pipe(
      takeWhile(
        () =>
          shipToRetryCount < shipToMaxRetries &&
          !errorDetails &&
          this.shipToInFlightSubject.getValue() === true,
      ),
      switchMap(() => this.myOrderStoreService.getOrderWithId(fullOrderId)),
      switchMap(o => {
        //After each customerOrders call we check the order in state to see if shipToCustomer has updated.
        if (
          o?.orderHeader?.shipToCustomer !== expectedShipToCustomer &&
          !o?.orderHeader?.errorDetails
        ) {
          shipToRetryCount++;
          this.myOrderStoreService.retrieveCustomerOrders();
        }
        errorDetails = !!o?.orderHeader?.errorDetails;
        return of(null);
      }),
      takeUntil(
        orderInState.pipe(
          filter(
            order =>
              order.orderHeader.shipToCustomer === expectedShipToCustomer ||
              !!order?.orderHeader?.errorDetails,
          ),
        ),
      ),
      tap(() => {
        if (shipToRetryCount === shipToMaxRetries || errorDetails) {
          this.handleShipToConversionResult(
            orderInState,
            expectedShipToCustomer,
          );
        }
      }),
      finalize(() =>
        this.handleShipToConversionResult(orderInState, expectedShipToCustomer),
      ),
    );
    checkOrderUpdates$.subscribe();
  }

  getProductMap$(orderItems: OrderItem[]): Observable<Map<number, Product>> {
    const productNumbers = orderItems
      ?.filter(item => !!item)
      .map(item => item.productNumber);
    return !!productNumbers
      ? this.store.select(selectProductAttributesByIds(productNumbers))
      : of(new Map());
  }
}
