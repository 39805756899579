import { Injectable } from '@angular/core';
import { State } from 'src/app/store';
import { createSelector, Store } from '@ngrx/store';
import {
  hasListUpdates,
  listUpdates,
  LIST_UPDATES_ACTIONS,
  listUpdatesLoaded,
  IListUpdatesState,
  selectListUpdateState,
} from '@usf/ngrx-list';
import { Observable } from 'rxjs';
import { ForcedUpdate } from '@usf/list-types';
import { ListUpdatesViewModel } from '../model/list-updates.model';
import { selectListUpdatesViewModel } from '../selectors/list-updates.selector';
import { ProductConversionContainer } from '@shared/models/product-conversion.model';
import { BetterBuysActions } from '@usf/ngrx-product';
import { productDetailsViewModelSelector } from '@product-detail/selectors/product-detail.selector';
import { ProductDetailViewModel } from '@product-detail/models/product-detail-view-model';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ListUpdatesService {
  constructor(
    private store: Store<State>,
    private actionsheetController: ActionSheetController,
    private translateService: TranslateService,
  ) {}

  listUpdatesExist$ = (): Observable<boolean> =>
    this.store.select(hasListUpdates);

  getListUpdates$ = (): Observable<ForcedUpdate[]> =>
    this.store.select(listUpdates);

  getListUpdateState$ = (): Observable<IListUpdatesState> =>
    this.store.select(selectListUpdateState);

  listUpdatesLoaded$ = (): Observable<boolean> =>
    this.store.select(listUpdatesLoaded);

  loadListUpdates() {
    this.store.dispatch(LIST_UPDATES_ACTIONS.load());
  }

  getListUpdatesWithDetails$ = (
    productNumbers: number[],
  ): Observable<ListUpdatesViewModel> => {
    return this.store.select(selectListUpdatesViewModel(productNumbers));
  };

  openEllipsis = async (
    productConversionContainers: ProductConversionContainer[],
  ) => {
    const buttons = [
      {
        text: this.translateService.instant('i18n.common.download'),
        handler: () => {
          this.downloadUpdates(productConversionContainers);
        },
      },
      // {
      //   text: this.translateService.instant('i18n.common.print'),
      //   handler: () => {},
      // },
      {
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      },
    ];
    const actionSheet = await this.actionsheetController.create({
      cssClass: 'action-sheet-touch',
      mode: 'ios',
      buttons,
    });
    await actionSheet.present();
  };

  downloadUpdates = (
    productConversionContainers: ProductConversionContainer[],
  ) => {
    const products = productConversionContainers.map(product => {
      return {
        originalProduct: product.originalProduct.productNumber,
        replacementProduct: product.replacementProduct.productNumber,
      };
    });
    this.store.dispatch(
      BetterBuysActions.downloadProductConversion({
        products,
      }),
    );
  };
}
