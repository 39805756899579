<ion-content
  class="invite-new-user-content"
  [ngClass]="{
    'ion-content-modal-tablet':
      platform.platformType === platform?.platformEnum?.tablet,
    'ion-content-modal-desktop':
      platform.platformType === platform?.platformEnum?.desktop,
  }"
>
  <ion-grid>
    <ion-row>
      <!-- Options Column -->
      <ion-col
        size-xl="5.5"
        size-lg="5.5"
        size-md="5.5"
        size-sm="12"
        size-xs="12"
        [ngClass]="{
          hidden:
            platform.platformType === platform?.platformEnum?.mobile &&
            selectedOption !== '',
        }"
        class="invite-new-user-options"
      >
        <ion-item
          *ngFor="let section of SECTIONS"
          [attr.data-cy]="'invite-new-user-' + section + '-option'"
          [class.selected]="selectedOption === section"
          detail
          (click)="setSelectedOption(section)"
        >
          <ion-label>
            {{ 'i18n.profilePage.inviteUser.' + section | translate }}
            <span
              class="selected-user"
              *ngIf="section === 'userInformation' && inviteNewUserForm.valid"
              [attr.data-cy]="'invite-new-user-' + section + '-selected'"
            >
              {{ result?.newUserEmail }}
            </span>
            <span
              class="selected-user"
              *ngIf="
                section === 'customers' && this.result.customers.length > 0
              "
              [attr.data-cy]="'invite-new-user-' + section + '-selected'"
            >
              {{
                this.result.customers.length === 1
                  ? getSingleCustomerSelectedName()
                  : getSelectedCustomersCount()
              }}
            </span>
          </ion-label>
        </ion-item>
      </ion-col>

      <!-- User Information Column -->
      <ion-col
        size-xl="5.5"
        size-lg="5.5"
        size-md="5.5"
        size-sm="12"
        size-xs="12"
        offset-xl="1"
        offset-lg="1"
        offset-md="1"
        offset-sm="0"
        offset-xs="0"
        [ngClass]="{
          hidden:
            platform.platformType === platform?.platformEnum?.mobile &&
            selectedOption === '',
        }"
        class="invite-new-user-input"
      >
        <!-- Users -->
        <div
          *ngIf="selectedOption === 'userInformation'"
          class="user-info-container"
        >
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.newModalDescription' | translate
          }}</ion-label>
          <p class="input-title">
            <ion-label class="select-user-label">{{
              'i18n.profilePage.inviteNewUser.modalNewUsersEmail' | translate
            }}</ion-label>
          </p>
          <form [formGroup]="inviteNewUserForm" class="invite-new-user-form">
            <ion-item
              [ngClass]="
                !inviteNewUserForm?.valid && result?.newUserEmail?.length > 0
                  ? 'invalid-input'
                  : ''
              "
            >
              <ion-input
                aria-label="invite-new-user"
                data-cy="invite-new-user-input"
                placeholder="name@example.com"
                type="email"
                maxlength="80"
                formControlName="newUserEmailAddress"
                (ionInput)="onInviteNewUser()"
              ></ion-input>
            </ion-item>
            <span
              *ngIf="
                !inviteNewUserForm?.valid && result?.newUserEmail?.length > 0
              "
              class="max-customers-reached"
            >
              {{ 'i18n.profilePage.inviteNewUser.invalidEmail' | translate }}
            </span>
          </form>
        </div>
        <!-- Customers -->
        <div *ngIf="selectedOption === 'customers'" class="user-info-container">
          <ion-label class="select-user-label">{{
            'i18n.profilePage.inviteNewUser.selectCustomers' | translate
          }}</ion-label>
          <ion-searchbar
            aria-label="customer-searchbar"
            data-cy="customers-searchbar"
            class="search"
            mode="md"
            inputmode="search"
            enterkeyhint="search"
            placeholder="{{ 'i18n.common.searchCustomers' | translate }}"
            (ionBlur)="searchCustomer($event)"
            (ionClear)="resetSearch()"
            (ionInput)="searchCustomer($event)"
            (ionChange)="searchCustomer($event)"
          ></ion-searchbar>
          <span *ngIf="customersMaxLimitReached" class="max-customers-reached">
            {{ 'i18n.profilePage.inviteUser.maxCustomers' | translate }}
          </span>
          <div *ngIf="searchedCustomers.length === 0" class="no-user-found">
            {{ 'i18n.profilePage.inviteUser.noCustomer' | translate }}
          </div>
          <ion-list
            class="customers-list"
            *ngIf="searchedCustomers.length > 0"
            [disabled]="this.result.newUserEmail"
          >
            <ion-item-divider></ion-item-divider>
            <ion-item
              *ngFor="
                let customer of searchedCustomers;
                trackBy: trackByCustomer
              "
              [disabled]="
                customer.disabled ||
                (customersMaxLimitReached && !customer.selected)
              "
              (click)="toggleCustomerSelection(customer, $event)"
            >
              <div id="customer-name">
                <span>
                  {{ customer.customerName | titlecase }}&nbsp;#{{
                    customer.customerNumber
                  }}
                </span>
              </div>
              <ion-checkbox
                mode="ios"
                slot="end"
                [attr.data-cy]="
                  'invite-existing-user-customer-' +
                  customer.customerNumber +
                  '-option'
                "
                [checked]="customer?.selected"
                labelPlacement="start"
                [aria-label]="
                  customer.customerName + ' #' + customer.customerNumber
                "
              ></ion-checkbox>
            </ion-item>
          </ion-list>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-spinner
    *ngIf="isLoading"
    class="loading-spinner"
    name="circles"
    data-cy="loading-spinner-icon"
  ></ion-spinner>
</ion-content>
