import { createSelector } from '@ngrx/store';
import { productDetailsViewModelSelector } from '@product-detail/selectors/product-detail.selector';
import { getFirstImageURL } from '@product-detail/utils/product-images.util';
import { ListUpdatesViewModel } from '../model/list-updates.model';
import { ImageVariantEnum } from '@product-detail/models/image-variant.enum';
import { listUpdates } from '@usf/ngrx-list';
import {
  OriginalProductConversionCard,
  ProductConversionContainer,
  ReplacementProductConversionCard,
} from '../../shared/models/product-conversion.model';
import { formatBenefits } from '../../better-buys/selectors/better-buys-selectors';
import { selectAppStateSelectedCustomer } from '../../ngrx-customer/store';
import { selectOnline } from '@panamax/app-state';

export const selectListUpdatesViewModel = (productNumbers: number[]) =>
  createSelector(
    listUpdates,
    productDetailsViewModelSelector(productNumbers),
    selectAppStateSelectedCustomer,
    selectOnline(),
    (
      listUpdates,
      productDetails,
      selectedCustomer,
      isOnline,
    ): ListUpdatesViewModel => {
      const listUpdatesViewModel: ListUpdatesViewModel = {
        productConversionContainers: [],
        pricingDisabled: selectedCustomer?.ogPrintPriceInd === 'N',
        isOnline,
      };

      listUpdates.forEach(listUpdate => {
        const originalProductDetails = productDetails.get(
          listUpdate.oldProductNumber,
        );
        const replacementProductDetails = productDetails.get(
          listUpdate.newProductNumber,
        );
        const originalProduct: OriginalProductConversionCard = {
          ...originalProductDetails,
          smallImageUrl: getFirstImageURL(
            originalProductDetails?.summary,
            ImageVariantEnum.Small,
          ),
        };
        const replacementProduct: ReplacementProductConversionCard = {
          ...replacementProductDetails,
          smallImageUrl: getFirstImageURL(
            replacementProductDetails?.summary,
            ImageVariantEnum.Small,
          ),
          formattedBenefits: formatBenefits(
            replacementProductDetails?.detail?.benefits,
          ),
          savings: listUpdate.savings,
          savingsUom: listUpdate.savingsUom,
          annualSavings: listUpdate.annualSavings,
          annualSavingsUom: listUpdate.annualSavingsUom,
          samePackSize:
            replacementProductDetails?.summary?.salesPackSize ===
            originalProductDetails?.summary?.salesPackSize,
          reasonCode: listUpdate.reason,
          pricingAvailableForSavings:
            (replacementProductDetails?.pricing?.eachPrice > 0 ||
              replacementProductDetails?.pricing?.unitPrice > 0) &&
            (originalProductDetails?.pricing?.eachPrice > 0 ||
              originalProductDetails?.pricing?.unitPrice > 0),
        };

        const productConversionContainer: ProductConversionContainer = {
          originalProduct,
          replacementProduct,
        };
        listUpdatesViewModel.productConversionContainers.push(
          productConversionContainer,
        );
      });
      console.log(listUpdatesViewModel);
      return {
        ...listUpdatesViewModel,
        updateCount: listUpdatesViewModel.productConversionContainers.length,
      };
    },
  );
