import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { PlatformEnum } from '@panamax/app-state';
import { ForcedUpdate, ListGroupKey, ListKey } from '@usf/list-types';
import {
  IListGroupState,
  IListItemState,
  IListState,
  IOrderGuideGroupState,
  IOrderGuideItemState,
  IOrderGuideState,
  ListConstants,
  ListGroupState,
  ListItemState,
  ListSearchSortFilterOptions,
  ListState,
  listGroupAdapter,
  listItemAdapter,
  orderGuideAdapter,
  orderGuideGroupAdapter,
  orderGuideItemAdapter,
  selectListGroupsState,
  selectListItemsState,
  selectListSearchSortFilterOptions,
  selectListUpdatesByItemIdMap,
  selectListsState,
  selectListsWithUpdatedCounts,
  selectOrderGuideGroupState,
  selectOrderGuideItemState,
  selectOrderGuideState,
} from '@usf/ngrx-list';
import {
  OrderHeaderState,
  OrderItemState,
  orderHeaderSelectors,
  orderItemSelectors,
} from '@usf/ngrx-order';
import { Preferences } from '@usf/user-types/user-preference';
import { SelectedCustUserProfiles } from '../../../../home/models/user-customer-info.model';
import { getCustUserProfiles } from '../../../../home/selectors/home.selectors';
import {
  inventoryGroupScaffold,
  inventoryItemScaffold,
  inventoryScaffold,
} from '../../../../inventory/models/inventory-view.model';
import { Product } from '../../../../shared/models/product.model';
import { productWithAlternativesSelector } from '../../../../shared/selectors/product.selectors';
import {
  loadRecentPurchaseInOg,
  selectUserPreferences,
} from '../../../../user/store';
import {
  GroupRow,
  ListDetailViewModel,
  ListGroupsAndItems,
  ListManagementViewModel,
  ListRow,
} from '../model/list-detail-management-view.model';
import {
  alphabeticalSort,
  calculateDesktopListManagementHeights,
  calculateItemHeightsAndHotkeyIds,
  calculateMobileListManagementHeights,
  calculateTabletListManagementHeights,
  createGroupRow,
  createListGroupKey,
  createListItemKey,
  createProductRow,
  findProductClasses,
  getListSearchSortFilterOptions,
  getSortAndFilterCount,
  groupAlphaSort,
  groupLineSort,
  productIsInSearchAndFilter,
  usFoodsClassesSort,
} from './helpers/list-detail-management.selectors.helper';

export const selectCombinedListAndOgHeaders = () =>
  createSelector(
    selectListsWithUpdatedCounts,
    orderGuideAdapter.getSelectors(selectOrderGuideState).selectEntities,
    (lists, orderGuides) => Object.assign({}, lists, orderGuides),
  );

export const selectCombinedListAndOgGroups = () =>
  createSelector(
    listGroupAdapter.getSelectors(selectListGroupsState).selectEntities,
    orderGuideGroupAdapter.getSelectors(selectOrderGuideGroupState)
      .selectEntities,
    (listGroups, orderGuideGroups) =>
      Object.assign({}, listGroups, orderGuideGroups),
  );

export const selectCombinedListAndOgItems = () =>
  createSelector(
    listItemAdapter.getSelectors(selectListItemsState).selectEntities,
    orderGuideItemAdapter.getSelectors(selectOrderGuideItemState)
      .selectEntities,
    (listItems, orderGuideItems) =>
      Object.assign({}, listItems, orderGuideItems),
  );

export const selectListStatesAreLoaded = () =>
  createSelector(
    selectListsState,
    selectListGroupsState,
    selectListItemsState,
    selectOrderGuideState,
    selectOrderGuideGroupState,
    selectOrderGuideItemState,
    (
      listState: IListState,
      listGroupState: IListGroupState,
      listItemState: IListItemState,
      orderGuideState: IOrderGuideState,
      orderGuideGroupState: IOrderGuideGroupState,
      orderGuideItemState: IOrderGuideItemState,
    ) => listState.loaded && listGroupState.loaded && listItemState.loaded,
  );

export const selectListHeader = (listType: string, listId: number) =>
  createSelector(
    selectCombinedListAndOgHeaders(),
    (listHeaders: Dictionary<ListState>) =>
      listHeaders[listType + '-' + listId],
  );

export const selectListGroup = (
  listTypeId: string,
  listId: number,
  listGroupId: string,
) =>
  createSelector(
    selectCombinedListAndOgGroups(),
    (groups: Dictionary<ListGroupState>) =>
      groups[listTypeId + '-' + listId + '-' + listGroupId],
  );

export const selectListWithGroupsAndItems = (
  listType: string,
  listId: number,
  platformType: PlatformEnum,
) =>
  createSelector(
    selectListHeader(listType, listId),
    selectCombinedListAndOgGroups(),
    selectCombinedListAndOgItems(),
    loadRecentPurchaseInOg,
    selectListUpdatesByItemIdMap,
    (
      list: ListState,
      groups: Dictionary<ListGroupState>,
      items: Dictionary<ListItemState>,
      newlyPurchasedInOg: boolean,
      listUpdatesByItemIdMap: Map<string, ForcedUpdate>,
    ) => {
      let sequenceNumber = 1;
      let numProductsSelected = 0;
      let numExternalProductsSelected = 0;
      const listGroups: ListGroupState[] = [];
      const listItems: ListItemState[] = [];
      const groupNames: string[] = [];
      let unassignedGroup: ListGroupState;
      let selectedGroup: ListGroupState;
      list?.listGroupKeys?.forEach(listGroupKey => {
        const group: ListGroupState =
          groups[createListGroupKey(list?.listKey, listGroupKey)];
        if (group && group.groupName) {
          groupNames.push(group?.groupName);
          if (
            group.groupName === 'Newly Purchased' &&
            !newlyPurchasedInOg &&
            list?.listKey?.listTypeId === ListConstants.orderGuideListType
          ) {
            return;
          }
          listGroups.push(group);
          let groupSequenceNumber = 1;
          if (group.groupName === ListConstants.unassignedGroup) {
            unassignedGroup = group;
          }
          if (group.groupName === list?.selectedGroup) {
            selectedGroup = group;
          } else if (
            !list?.selectedGroup &&
            platformType !== PlatformEnum.mobile
          ) {
            selectedGroup = listGroups[0];
          }
          group?.listItemKeys?.forEach(listItemKey => {
            const item: ListItemState =
              items[
                createListItemKey(
                  group.listKey,
                  group.listGroupKey,
                  listItemKey,
                )
              ];
            if (item?.isSelected && !item?.nonUsfProduct) {
              numProductsSelected++;
            }

            if (item?.isSelected && item?.nonUsfProduct) {
              numExternalProductsSelected++;
            }
            const forcedUpdateLookupKey =
              item?.listKey?.listTypeId +
              '-' +
              item?.listKey?.listId +
              '-' +
              item?.listItemKey?.listItemId;
            const sequencedItem = {
              ...item,
              sequenceNumber: sequenceNumber++,
              groupSequenceNumber: groupSequenceNumber++,
              forcedUpdate: listUpdatesByItemIdMap.get(forcedUpdateLookupKey),
            };
            listItems.push(sequencedItem);
          });
        }
      });
      return {
        list,
        groups: listGroups,
        listItems,
        unassignedGroup,
        selectedGroup,
        numProductsSelected,
        numExternalProductsSelected,
        groupNames,
      } as ListGroupsAndItems;
    },
  );

export const selectListItemsOnList = (listType: string, listId: number) =>
  createSelector(selectCombinedListAndOgItems(), listItems =>
    Object.values(listItems).filter(listItem => {
      return (
        listItem.listKey.listTypeId === listType &&
        listItem.listKey.listId === listId
      );
    }),
  );

export const selectListItemsInGroup = (
  listKey: ListKey,
  groupKey: ListGroupKey,
) =>
  createSelector(
    listItemAdapter.getSelectors(selectListItemsState).selectEntities,
    listItems =>
      Object.values(listItems).filter(
        listItem =>
          listItem.listKey.listTypeId === listKey.listTypeId &&
          listItem.listKey.listId === listKey.listId &&
          listItem.listGroupKey.listTypeId === groupKey.listTypeId &&
          listItem.listGroupKey.listGroupId === groupKey.listGroupId,
      ),
  );

export const productNumbersOnList = (listType: string, listId: number) =>
  createSelector(selectListItemsOnList(listType, listId), items =>
    items.map(item => item.productNumber),
  );

export const selectSortAndFiltersForList = (
  listTypeId: string,
  listId: number,
) =>
  createSelector(
    selectListSearchSortFilterOptions,
    selectUserPreferences,
    (
      listSearchSortFilterOptions: ListSearchSortFilterOptions,
      userPreferences: Preferences,
    ) => {
      const listKey = listTypeId + '-' + listId;
      let preferencesForThisList;

      if (userPreferences?.preferences?.sortAndFilterApplied) {
        preferencesForThisList =
          userPreferences.preferences.sortAndFilterApplied[listKey];
      }

      return getListSearchSortFilterOptions(
        listSearchSortFilterOptions,
        preferencesForThisList,
      );
    },
  );

export const selectListDetailVm = (
  listTypeId: string,
  listId: number,
  platformType: PlatformEnum,
) =>
  createSelector(
    getCustUserProfiles,
    selectListWithGroupsAndItems(listTypeId, listId, platformType),
    productWithAlternativesSelector,
    orderItemSelectors.selectOrderItemState,
    orderHeaderSelectors.selectOrderContextState,
    selectSortAndFiltersForList(listTypeId, listId),
    (
      selectedCustProfiles: SelectedCustUserProfiles,
      listGroupsAndItems: ListGroupsAndItems,
      products: Map<number, Product>,
      orderItemState: OrderItemState,
      orderHeaderState: OrderHeaderState,
      listSearchSortFilterOptions: ListSearchSortFilterOptions,
    ) => {
      let listRows: ListRow[];
      const productClasses = findProductClasses(
        listGroupsAndItems?.listItems,
        products,
      );
      if (listSearchSortFilterOptions.sortType === ListConstants.alphabetical) {
        listRows = alphabeticalSort(
          listGroupsAndItems,
          products,
          listSearchSortFilterOptions,
        );
      } else if (
        listSearchSortFilterOptions.sortType === ListConstants.usFoodsClass
      ) {
        listRows = usFoodsClassesSort(
          products,
          productClasses.productClasses,
          productClasses.itemsInClasses,
          listSearchSortFilterOptions,
        );
      } else if (
        listSearchSortFilterOptions.sortType === ListConstants.groupAlpha
      ) {
        listRows = groupAlphaSort(
          listGroupsAndItems,
          products,
          listSearchSortFilterOptions,
        );
      } else if (
        listSearchSortFilterOptions.sortType === ListConstants.groupLine
      ) {
        listRows = groupLineSort(
          listGroupsAndItems,
          products,
          listSearchSortFilterOptions,
        );
      }

      // After creating items, need to calculate their heights and possible desktopIds
      const { itemHeights, productCardIds } = calculateItemHeightsAndHotkeyIds(
        platformType,
        listRows,
        listGroupsAndItems?.list?.listKey?.listTypeId,
      );
      // Construct view model to return
      const viewModel: ListDetailViewModel = {
        selectedCustomer: selectedCustProfiles.selectedCustomer,
        userProfiles: selectedCustProfiles.userProfiles,
        list: listGroupsAndItems?.list,
        listGroups: listGroupsAndItems?.groups,
        listItems: listGroupsAndItems?.listItems,
        items: listRows,
        itemHeights,
        productCardIds,
        productClasses: productClasses.productClasses,
        groupNames: listGroupsAndItems?.groupNames,
        orderHeader: orderHeaderState?.entities[orderHeaderState?.ids[0]],
        orderItemTallies: orderItemState?.tallies,
        listSearchSortFilterOptions,
        sortAndFilterCount: getSortAndFilterCount(listSearchSortFilterOptions),
        showEllipsis: true, // might need to return false for an og?
      };
      return viewModel;
    },
  );

export const selectListManagementVm = (
  listTypeId: string,
  listId: number,
  platformType: PlatformEnum,
) =>
  createSelector(
    getCustUserProfiles,
    selectListWithGroupsAndItems(listTypeId, listId, platformType),
    productWithAlternativesSelector,
    selectSortAndFiltersForList(listTypeId, listId),
    (
      selectedCustProfiles: SelectedCustUserProfiles,
      listGroupsAndItems: ListGroupsAndItems,
      products: Map<number, Product>,
      listSearchSortFilterOptions: ListSearchSortFilterOptions,
    ) => {
      let listRows: ListRow[] = [];
      let managementSidebarGroups: GroupRow[] = [];
      let itemHeights: number[];
      const managementSortFilterOptions = {
        searchKey: listSearchSortFilterOptions?.searchKey,
        sortType: ListConstants.groupLine,
        groupToSort: ListConstants.allGroups,
        filters: [],
      } as ListSearchSortFilterOptions;
      let i = 0;
      // Construct listRows and managementSidebar groups
      listGroupsAndItems?.groups?.forEach(group => {
        const isSelectedGroup =
          group.groupName === listGroupsAndItems.selectedGroup?.groupName;
        let j;
        j = i + group?.listItemKeys?.length || 0;
        let itemCount = 0;
        for (i; i < j; i++) {
          const item = listGroupsAndItems?.listItems[i];
          const productRow = createProductRow(item, group, products);
          if (
            productIsInSearchAndFilter(productRow, managementSortFilterOptions)
          ) {
            if (isSelectedGroup) {
              listRows.push(productRow);
            }
            itemCount++;
          }
        }
        const groupRow = createGroupRow(group, itemCount);
        managementSidebarGroups.push(groupRow);
        if (isSelectedGroup) {
          listRows.unshift(groupRow);
        }
      });
      // Construct itemHeights
      if (platformType === PlatformEnum.desktop) {
        itemHeights = calculateDesktopListManagementHeights(listRows);
      } else if (platformType === PlatformEnum.tablet) {
        itemHeights = calculateTabletListManagementHeights(listRows);
      } else if (platformType === PlatformEnum.mobile) {
        itemHeights = calculateMobileListManagementHeights(listRows);
      }

      // Construct view model to return
      const viewModel: ListManagementViewModel = {
        selectedCustomer: selectedCustProfiles.selectedCustomer,
        userProfiles: selectedCustProfiles.userProfiles,
        list: listGroupsAndItems?.list,
        items: listRows,
        listGroups: listGroupsAndItems?.groups,
        listItems: listGroupsAndItems?.listItems,
        selectedGroup: listGroupsAndItems?.selectedGroup,
        unassignedGroup: listGroupsAndItems?.unassignedGroup,
        numProductsSelected: listGroupsAndItems?.numProductsSelected,
        itemHeights,
        listSearchSortFilterOptions: managementSortFilterOptions,
        managementSidebarGroups,
      };
      return viewModel;
    },
  );

export const isListAndProductDataLoaded = (
  listTypeId: string,
  listId: string,
) =>
  createSelector(
    selectListItemsOnList(listTypeId, Number(listId)),
    productWithAlternativesSelector,
    selectListStatesAreLoaded(),
    (
      itemState,
      summaryState: Map<number, Product>,
      listSlicesAreLoaded: boolean,
    ) => {
      let isLoaded = true;

      if (!listSlicesAreLoaded) {
        isLoaded = false;
      }

      itemState.forEach(item => {
        if (!summaryState.get(item?.productNumber)) {
          isLoaded = false;
        }
      });
      return isLoaded;
    },
  );

export const isListAndProductAndPricingAndAlternativePricingDataLoaded = (
  listTypeId: string,
  listId: string,
) =>
  createSelector(
    selectListItemsOnList(listTypeId, Number(listId)),
    productWithAlternativesSelector,
    selectListStatesAreLoaded(),
    (
      itemState: ListItemState[],
      summaryState: Map<number, Product>,
      listSlicesAreLoaded: boolean,
    ) => {
      let isLoaded = true;

      if (!listSlicesAreLoaded) {
        isLoaded = false;
      }

      itemState.forEach(item => {
        const product = summaryState.get(item?.productNumber);
        if (
          !product ||
          product?.pricing?.loading ||
          !product?.pricing?.productNumber ||
          (product?.alternative?.available &&
            product?.alternative?.required &&
            product?.alternative?.product?.pricing?.loading)
        ) {
          isLoaded = false;
        }
      });
      return isLoaded;
    },
  );

export const selectGroupsByListKey = (listTypeId: string, listId: number) =>
  createSelector(
    selectListHeader(listTypeId, listId),
    listGroupAdapter.getSelectors(selectListGroupsState).selectEntities,
    (list: ListState, groups: Dictionary<ListGroupState>) => {
      const listGroups: ListGroupState[] = [];
      list?.listGroupKeys?.forEach(listGroupKey => {
        const group = groups[createListGroupKey(list?.listKey, listGroupKey)];
        if (group && group.groupName) {
          listGroups.push(group);
        }
      });
      return listGroups;
    },
  );

export const filterProductNumbersByGroup = (
  listKey: ListKey,
  groupKey: ListGroupKey,
  productNumbers: number[],
) =>
  createSelector(
    selectListItemsInGroup(listKey, groupKey),
    (listItems: ListItemState[]) => {
      if (listItems.length > 0) {
        const itemsProdNumbers = listItems.map(item => item.productNumber);
        return productNumbers.filter(
          prodNumber => !itemsProdNumbers.includes(prodNumber),
        );
      } else {
        return productNumbers;
      }
    },
  );

export const selectListInventoryScaffold = (listType: string, listId: number) =>
  createSelector(
    selectListHeader(listType, listId),
    selectCombinedListAndOgGroups(),
    selectCombinedListAndOgItems(),
    (
      list: ListState,
      groups: Dictionary<ListGroupState>,
      itemsState: Dictionary<ListItemState>,
    ): inventoryScaffold => {
      const inventoryGroups: inventoryGroupScaffold[] = [];
      const productsToLoad: Set<number> = new Set();
      list?.listGroupKeys?.forEach(listGroupKey => {
        const group = groups[createListGroupKey(list?.listKey, listGroupKey)];
        if (group && group.groupName) {
          const inventoryGroupItems: inventoryItemScaffold[] = [];
          if (
            group.listItemKeys !== undefined &&
            group.listItemKeys.length > 0
          ) {
            group?.listItemKeys?.forEach(listItemKey => {
              const key = createListItemKey(
                group.listKey,
                group.listGroupKey,
                listItemKey,
              );
              const item: ListItemState = itemsState[key];
              inventoryGroupItems.push({
                productNumber: item.productNumber,
                nonUSF: false,
              } as inventoryItemScaffold);
              productsToLoad.add(item.productNumber);
            });
          }
          const groupWithItems: inventoryGroupScaffold = {
            groupName: group.groupName,
            items: inventoryGroupItems,
          };
          inventoryGroups.push(groupWithItems);
        }
      });

      return {
        productsToLoad: Array.from(productsToLoad),
        inventoryGroups,
      } as inventoryScaffold;
    },
  );
