import { Capacitor } from '@capacitor/core';
import { CostReportSortOptions } from '../../../../inventory/models/cost-report-model.model';
import {
  InventoryGroupViewModel,
  InventoryItem,
  InventoryProductViewModel,
} from '../../../../inventory/models/inventory-view.model';
import { ItemTypes } from '../../../../lists/shared/list-detail-management/model/list-detail-management-view.model';
import { CustomerDivisionDepartment } from '../../../../ngrx-customer/models/customer-division-department.model';
import {
  addValueToColumns,
  getDocumentMultipleCurrency,
  getDocumentStatus,
  getDocumentStatusImage,
  getDocumentString,
  getRawDocumentCurrency,
} from '../../../helpers/document-creation.helpers';
import { desiredColumn } from '../../../models/desired-column';
import { Product } from '../../../models/product.model';
import { SeperatedColumn } from '../../../models/seperated-columns';
import { CurrencyService } from '../../currency.service';
import { InventoryColumns } from './document-helper-models-and-enums';
import {
  operateOnColumnImageType,
  operateOnColumnProductNumber,
  operateOnColumnProductType,
} from './download-list-helper';

export const operateOnColumnCaseCount = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let caseValue = inventoryItem.caseOnHand?.currentValue?.toString();
  if (inventoryItem.caseOnHand?.currentValue > 0) {
    caseValue = parseFloat(caseValue).toFixed(2);
  }
  return addValueToColumns(columnName, columnMap, getDocumentString(caseValue));
};

export const operateOnColumnEachCount = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let eachValue = inventoryItem.eachOnHand?.currentValue?.toString();
  if (inventoryItem.eachOnHand?.currentValue > 0) {
    eachValue = parseFloat(eachValue).toFixed(2);
  }
  return addValueToColumns(columnName, columnMap, getDocumentString(eachValue));
};

export const operateOnColumnGroupName = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(inventoryItem.groupName),
  );
};

export const operateOnColumnProductDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  const productDescription = getProductDescriptionForSort(inventoryItem);
  return addValueToColumns(columnName, columnMap, productDescription);
};

export const isNonUsf = (productNumber: number): boolean => {
  const prodNumberString = productNumber?.toString();
  return prodNumberString?.length === 10;
};

export const operateOnColumnProductBrand = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(
      !inventoryItem.productName ? '' : (inventoryItem?.productBrand ?? ''),
    ),
  );
};

export const operateOnColumnUnitDescription = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value = unitDescriptionDictionary[inventoryItem.uom];
  if (!value) {
    value = inventoryItem.customUOM;
  }
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(value ?? ''),
  );
};

export const operateOnColumnUnitPriceUom = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value = inventoryItem.uom;
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(value ?? ''),
  );
};

export const operateOnColumnProductPackageSize = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(
      !inventoryItem.productName
        ? ''
        : (inventoryItem?.productPackSizeLabel ?? ''),
    ),
  );
};

const unitDescriptionDictionary = {
  LB: 'Pound',
  EA: 'Each',
  OZ: 'Ounce',
  G: 'Grams',
  GA: 'Gallon',
  Batch: 'Batch',
  Piece: 'Piece',
};

export const operateOnColumnUnitByCase = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(
      inventoryItem.displayConvFactor ? inventoryItem.displayConvFactor : 1,
    ),
  );
};

export const operateOnColumnProductCost = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value;
  const price =
    inventoryItem.priceOverrideInd === 'Y'
      ? inventoryItem.overrideCasePrice
      : inventoryItem.productCatchWeightFlag
        ? inventoryItem.productUnitPrice
        : inventoryItem.productCasePrice;
  value = getDocumentString(getRawDocumentCurrency(price >= 0 ? price : 0));
  return addValueToColumns(columnName, columnMap, value);
};

export const operateOnColumnCasePrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value;
  let price =
    inventoryItem.priceOverrideInd === 'Y'
      ? inventoryItem.overrideCasePrice
      : inventoryItem.productCatchWeightFlag
        ? inventoryItem.productUnitPrice
        : inventoryItem.productCasePrice;
  let unit = inventoryItem.productCatchWeightFlag ? 'LB' : 'CS';
  if (price >= 0) {
    value = getDocumentString(getDocumentMultipleCurrency([price], [unit]));
  } else {
    value = `"$0.00/${unit}"`;
  }

  return addValueToColumns(columnName, columnMap, value);
};

export const operateOnColumnCasePriceWithoutUom = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value;
  let price =
    inventoryItem.priceOverrideInd === 'Y'
      ? inventoryItem.overrideCasePrice
      : inventoryItem.productCatchWeightFlag
        ? inventoryItem.productUnitPrice
        : inventoryItem.productCasePrice;
  if (price >= 0) {
    value = getRawDocumentCurrency(price);
  } else {
    value = `"$0.00"`;
  }

  return addValueToColumns(columnName, columnMap, value);
};

export const operateOnColumnCasePriceUom = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value = 'CS';
  if (inventoryItem.productCatchWeightFlag) {
    value = 'LB';
  }
  return addValueToColumns(columnName, columnMap, getDocumentString(value));
};

export const operateOnColumnProductUom = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
  product: Product,
) => {
  let value = product?.summary?.priceUom
    ? product.summary.priceUom.toUpperCase()
    : 'CS';
  if (inventoryItem.productCatchWeightFlag) {
    value = 'LB';
  }
  return addValueToColumns(columnName, columnMap, getDocumentString(value));
};

export const operateOnColumnExtendedCost = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getRawDocumentCurrency(inventoryItem.value),
  );
};

export const operateOnColumnProductStatus = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  product: Product,
  inventoryItem: InventoryProductViewModel,
) => {
  const isNonUsfProduct = isNonUsf(product?.productNumber);
  if (isNonUsfProduct && !inventoryItem.productMissingFlag) {
    // Need empty string for non usf products
    return addValueToColumns(columnName, columnMap, '');
  } else {
    return addValueToColumns(columnName, columnMap, getDocumentStatus(product));
  }
};

export const operateOnColumnLineNumber = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(inventoryItem.itemSequenceNumber),
  );
};

export const operateOnColumnImageStatus = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  product: Product,
  inventoryItem: InventoryProductViewModel,
) => {
  const isNonUsfProduct = isNonUsf(product?.productNumber);
  if (isNonUsfProduct && !inventoryItem.productMissingFlag) {
    return addValueToColumns(columnName, columnMap, '');
  } else {
    return addValueToColumns(
      columnName,
      columnMap,
      getDocumentStatusImage(product),
    );
  }
};

export const operateOnColumnVendor = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  if (inventoryItem.nonUSFFlag) {
    const vendorName = inventoryItem?.vendorName;
    const vendor = vendorName ? vendorName : 'Non-USF';
    return addValueToColumns(columnName, columnMap, getDocumentString(vendor));
  } else {
    return addValueToColumns(columnName, columnMap, getDocumentString('USF'));
  }
};

export const operateOnColumnUnitPrice = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value: string;
  if (inventoryItem.unitPrice >= 0) {
    value =
      '"' +
      getDocumentMultipleCurrency(
        [inventoryItem.unitPrice],
        [inventoryItem.uom],
      ) +
      '"';
  } else {
    value = '"$0.00"';
  }
  return addValueToColumns(columnName, columnMap, value);
};

export const operateOnColumnUnitPriceWithoutUom = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  let value: string;
  if (inventoryItem.unitPrice >= 0) {
    value = getRawDocumentCurrency(inventoryItem.unitPrice);
  } else {
    value = '"$0.00"';
  }
  return addValueToColumns(columnName, columnMap, value);
};

export const operateOnColumnFreehandCount = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(columnName, columnMap, '');
};

export const operateOnColumnGlCode = (
  columnName: string,
  columnMap: Map<string, SeperatedColumn>,
  inventoryItem: InventoryProductViewModel,
) => {
  return addValueToColumns(
    columnName,
    columnMap,
    getDocumentString(inventoryItem?.glString),
  );
};

export const inventoryFunctionMap = {
  [InventoryColumns.caseCount]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnCaseCount(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.glCode]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnGlCode(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.lineNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnLineNumber(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.groupName]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnGroupName(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.productNumber]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductNumber(columnName, columnMap, product);
  },
  [InventoryColumns.productDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductDescription(
      columnName,
      columnMap,
      inventoryItem,
    );
  },
  [InventoryColumns.productStatus]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductStatus(
      columnName,
      columnMap,
      product,
      inventoryItem,
    );
  },
  [InventoryColumns.productType]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductType(columnName, columnMap, product);
  },
  [InventoryColumns.productBrand]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductBrand(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.productPackageSize]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductPackageSize(
      columnName,
      columnMap,
      inventoryItem,
    );
  },
  [InventoryColumns.unitDescription]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnUnitDescription(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.unitByCase]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnUnitByCase(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.eachCount]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnEachCount(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.productCost]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductCost(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.casePrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnCasePrice(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.casePriceWithoutUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnCasePriceWithoutUom(
      columnName,
      columnMap,
      inventoryItem,
    );
  },

  [InventoryColumns.casePriceUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnCasePriceUom(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.productUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnProductUom(
      columnName,
      columnMap,
      inventoryItem,
      product,
    );
  },
  [InventoryColumns.extendedCost]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnExtendedCost(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.imageStatus]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnImageStatus(
      columnName,
      columnMap,
      product,
      inventoryItem,
    );
  },
  [InventoryColumns.imageType]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnImageType(columnName, columnMap, product);
  },
  [InventoryColumns.vendor]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnVendor(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.unitPrice]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnUnitPrice(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.unitPriceWithoutUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnUnitPriceWithoutUom(
      columnName,
      columnMap,
      inventoryItem,
    );
  },
  [InventoryColumns.unitPriceUom]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnUnitPriceUom(columnName, columnMap, inventoryItem);
  },
  [InventoryColumns.freehandCount]: (
    columnName: string,
    columnMap: Map<string, SeperatedColumn>,
    product: Product,
    inventoryItem: InventoryProductViewModel,
  ) => {
    return operateOnColumnFreehandCount(columnName, columnMap, inventoryItem);
  },
};

/**
 *
 * @param separatedColumns Contains Inventory Data
 * @returns
 */
export const stringifyCSV = (separatedColumns: SeperatedColumn[]): string => {
  // Variable to hold the seperated columns as one string
  let stringified = '';

  // Create the Header Row
  const headerRow = separatedColumns.map(pMap => pMap.columnName).join(',');
  // Add the header row to return string
  stringified += headerRow + '\r\n';
  // Add every row of data to return string
  for (let i = 0; i < separatedColumns[0].columnValues.length; i++) {
    const line = separatedColumns
      .map(pm => (pm.columnValues ? pm.columnValues[i] : pm.singleValue))
      .join(',')
      .replace('GL Total: ', '');
    stringified += line + '\r\n';
  }
  return stringified;
};

export const getTitleString = (
  customerDivisionDepartment: CustomerDivisionDepartment,
  inventoryWorksheetName: string,
): string => {
  let title = '';
  title += inventoryWorksheetName + ',\r\n';
  const customer =
    'Customer: ' +
    customerDivisionDepartment.customerName +
    ' (' +
    customerDivisionDepartment.customerNumber +
    ') ';
  const division =
    'Division: ' +
    customerDivisionDepartment.divisionName +
    ' (' +
    customerDivisionDepartment.divisionNumber +
    ') ';
  let department = '';
  if (customerDivisionDepartment.departmentName) {
    department =
      'Department: ' +
      customerDivisionDepartment.departmentName +
      ' (' +
      customerDivisionDepartment.departmentNumber +
      ') ';
  }

  title += customer + division + department + ', \r\n';
  return title;
};

export const sortInventoryItemsForCostReportCSVFile = (
  selectedSort: string,
  items: InventoryItem[],
) => {
  let filteredItems: InventoryItem[] = items.filter(item => {
    return item.itemType === ItemTypes.product;
  });
  const sortedItems = executeSort(
    selectedSort,
    filteredItems as InventoryProductViewModel[],
  );
  return selectedSort === CostReportSortOptions.glCodesWithoutProductDetail
    ? flattenedGlProductsNoDetails(sortedItems)
    : sortedItems;
};

export const flattenedGlProductsNoDetails = (
  sortedItems: InventoryProductViewModel[],
): InventoryProductViewModel[] => {
  const glSortedNoProductDetails: InventoryProductViewModel[] = [];

  let currGlString = sortedItems[0].glString;
  let sum = sortedItems[0].value;

  // Iterate through the sorted array starting from the second element
  for (let i = 1; i < sortedItems.length; i++) {
    const { glString, value } = sortedItems[i];

    // If the glString is the same as the currGlString, accumulate the value
    if (glString === currGlString) {
      sum += value;
    } else {
      // If a different glString is encountered, push the current accumulated sum to the simplified array
      glSortedNoProductDetails.push({
        glString: 'GL Total: ' + currGlString,
        value: sum,
        productNumber: 0,
        productName: '',
        groupName: currGlString,
        itemSequenceNumber: 0,
        itemAbsoluteIndex: 0,
        displayConvFactor: 0,
        casePrice: 0,
        unitPrice: 0,
        total: '',
        itemType: ItemTypes.product,
        uom: '',
        isSelected: false,
        productConvFactor: 0,
        productBreakable: false,
        productPackSizeLabel: '',
        productImageThumbnail: '',
        productBrand: '',
        productCatchWeightFlag: false,
        productMarketBreakFlag: false,
        productBadPricingCaseFlag: false,
        productBadPricingUnitFlag: false,
        nonUSFFlag: false,
        productMissingFlag: false,
        overrideFlag: false,
      });
      // Reset sum for the new glString
      sum = value;
      // Update currGlString to the new glString
      currGlString = glString;
    }
  }

  // Push the last accumulated sum to the simplified array
  glSortedNoProductDetails.push({
    glString: 'GL Total: ' + currGlString,
    value: sum,
    productNumber: 0,
    productName: '',
    groupName: currGlString,
    itemSequenceNumber: 0,
    itemAbsoluteIndex: 0,
    displayConvFactor: 0,
    casePrice: 0,
    unitPrice: 0,
    total: '',
    itemType: ItemTypes.product,
    uom: '',
    isSelected: false,
    productConvFactor: 0,
    productBreakable: false,
    productPackSizeLabel: '',
    productImageThumbnail: '',
    productBrand: '',
    productCatchWeightFlag: false,
    productMarketBreakFlag: false,
    productBadPricingCaseFlag: false,
    productBadPricingUnitFlag: false,
    nonUSFFlag: false,
    productMissingFlag: false,
    overrideFlag: false,
  });

  return glSortedNoProductDetails;
};

export const sortInventoryItemsForCostReportPDFFile = (
  selectedSort: string,
  items: InventoryItem[],
): InventoryItem[] => {
  let arrayOfGroupsAndItems: InventoryItem[][] = [];
  let currentGroup: InventoryItem;
  let itemsInGroup: InventoryProductViewModel[] = [];

  if (
    selectedSort === CostReportSortOptions.glCodesWithProductDetail ||
    selectedSort === CostReportSortOptions.glCodesWithoutProductDetail
  ) {
    itemsInGroup = sortInventoryItemsForCostReportCSVFile(selectedSort, items);
    return itemsInGroup;
  }
  for (const item of items) {
    if (item.itemType === ItemTypes.group) {
      if (currentGroup && itemsInGroup.length > 0) {
        let groupAndItems: InventoryItem[] = [];
        itemsInGroup = executeSort(selectedSort, itemsInGroup);
        groupAndItems.push(currentGroup);
        groupAndItems = groupAndItems.concat(itemsInGroup);
        arrayOfGroupsAndItems.push(groupAndItems);
        currentGroup = item;
        itemsInGroup = [];
      } else {
        currentGroup = item;
      }
    }
    if (item.itemType === ItemTypes.product) {
      const productItem = item as InventoryProductViewModel;
      itemsInGroup.push(productItem);
    }
  }
  if (itemsInGroup.length > 0) {
    let groupAndItems: InventoryItem[] = [];
    itemsInGroup = executeSort(selectedSort, itemsInGroup);
    groupAndItems.push(currentGroup);
    groupAndItems = groupAndItems.concat(itemsInGroup);
    arrayOfGroupsAndItems.push(groupAndItems);
  }
  if (selectedSort === CostReportSortOptions.groupsWithSubTotal) {
    arrayOfGroupsAndItems = groupsWithSubTotalAscendingSort(
      arrayOfGroupsAndItems,
    );
  }
  return flattenProducts(arrayOfGroupsAndItems);
};

const flattenProducts = (
  arrayToFlatten: InventoryItem[][],
): InventoryItem[] => {
  let inventoryItemArr: InventoryItem[] = [];
  arrayToFlatten.forEach((inventoryItems: InventoryItem[]) => {
    inventoryItemArr = inventoryItemArr.concat(inventoryItems);
  });
  inventoryItemArr = inventoryItemArr.filter((item: InventoryItem) => {
    return item.itemType !== ItemTypes.group;
  });
  return inventoryItemArr;
};

export const executeSort = (
  selectedSort: string,
  items: InventoryProductViewModel[],
) => {
  let sortedItems: InventoryProductViewModel[];
  switch (selectedSort) {
    case CostReportSortOptions.descriptionAsc:
      sortedItems = productDescriptionAscendingSort(items);
      break;
    case CostReportSortOptions.extendedDesc:
      sortedItems = extendedValueDescendingSort(items);
      break;
    case CostReportSortOptions.groupsWithSubTotal:
      sortedItems = productDescriptionAscendingSort(items);
      break;
    case CostReportSortOptions.productNumAsc:
      sortedItems = productNumberAscendingSort(items);
      break;
    case CostReportSortOptions.lineNumberAsc:
      sortedItems = lineNumberAscendingSort(items);
      break;
    case CostReportSortOptions.glCodesWithProductDetail:
      sortedItems = glCodesWithProductDetailAscendingSort(items);
      break;
    case CostReportSortOptions.glCodesWithoutProductDetail:
      sortedItems = glCodesWithProductDetailAscendingSort(items);
      break;
  }
  return sortedItems;
};

export const glCodesWithProductDetailAscendingSort = (
  items: InventoryProductViewModel[],
) => {
  const sortedItems: InventoryProductViewModel[] = items.sort((a, b) => {
    const glCodeA = a.glString;
    const glCodeB = b.glString;

    // Check if both strings contain GL: Unassigned
    const hasUnassignedGroupA = glCodeA === 'Unassigned';
    const hasUnassignedGroupB = glCodeB === 'Unassigned';

    // Compare Unassigned Group
    if (hasUnassignedGroupA && !hasUnassignedGroupB) return -1;
    if (!hasUnassignedGroupA && hasUnassignedGroupB) return 1;

    //function to check if string begins with a number
    const startsWithNumber = (str: string) => /^\d/.test(str);

    // Perform Secondary Comparison
    if (startsWithNumber(glCodeA) && !startsWithNumber(glCodeB)) return -1;
    if (!startsWithNumber(glCodeA) && startsWithNumber(glCodeB)) return 1;

    return glCodeA.localeCompare(glCodeB);
  });
  return sortedItems;
};

export const lineNumberAscendingSort = (items: InventoryProductViewModel[]) => {
  const sortedItems: InventoryProductViewModel[] = items.sort((a, b) => {
    return a.itemSequenceNumber > b.itemSequenceNumber ? 1 : -1;
  });
  return sortedItems;
};

export const productDescriptionAscendingSort = (
  items: InventoryProductViewModel[],
) => {
  const sortedItems: InventoryProductViewModel[] = items.sort((a, b) => {
    const descriptionA = getProductDescriptionForSort(a).toLowerCase();
    const descriptionB = getProductDescriptionForSort(b).toLowerCase();
    if (descriptionA === descriptionB && a.productNumber === b.productNumber) {
      return 1;
    } else if (descriptionA === descriptionB) {
      // Second sort
      return a.productNumber > b.productNumber ? 1 : -1;
    } else {
      // First sort
      return descriptionA > descriptionB ? 1 : -1;
    }
  });
  return sortedItems;
};

export const getProductDescriptionForSort = (
  item: InventoryProductViewModel,
) => {
  const isNonUsfProduct = isNonUsf(item.productNumber);
  if (isNonUsfProduct) {
    if (!item.productName) {
      return getDocumentString('Non-US Foods Product Deleted');
    } else {
      return getDocumentString('Non-USF: ' + item.productName);
    }
  } else if (!item.productName) {
    return getDocumentString('Not Available');
  } else {
    return getDocumentString(item.productName, 75);
  }
};

export const extendedValueDescendingSort = (
  items: InventoryProductViewModel[],
) => {
  const sortedItems: InventoryProductViewModel[] = items.sort((a, b) => {
    let valueToReturn;
    const descriptionA = getProductDescriptionForSort(a).toLowerCase();
    const descriptionB = getProductDescriptionForSort(b).toLowerCase();
    if (
      a.value === b.value &&
      descriptionA === descriptionB &&
      a.productNumber === b.productNumber
    ) {
      valueToReturn = 1;
    } else if (a.value === b.value) {
      if (descriptionA === descriptionB) {
        // Third sort
        valueToReturn = a.productNumber > b.productNumber ? 1 : -1;
      } else {
        // Second sort
        valueToReturn = descriptionA > descriptionB ? 1 : -1;
      }
    } else {
      // First sort
      valueToReturn = a.value < b.value ? 1 : -1;
    }
    return valueToReturn;
  });
  return sortedItems;
};

export const productNumberAscendingSort = (
  items: InventoryProductViewModel[],
) => {
  const sortedItems: InventoryProductViewModel[] = items.sort((a, b) => {
    if (a.productNumber === b.productNumber) {
      return 1;
    } else {
      return a.productNumber > b.productNumber ? 1 : -1;
    }
  });
  return sortedItems;
};

export const groupsWithSubTotalAscendingSort = (
  arrayOfGroupsAndItems: InventoryItem[][],
): InventoryItem[][] => {
  return arrayOfGroupsAndItems.sort((a, b) => {
    const group1 = a[0] as InventoryGroupViewModel;
    const group2 = b[0] as InventoryGroupViewModel;
    if (group1.totalPrice === group2.totalPrice) {
      // Second Sort
      return group1.groupName > group2.groupName ? 1 : -1;
    } else {
      // First Sort
      return group1.totalPrice > group2.totalPrice ? 1 : -1;
    }
  });
};

export const getCSVColumns = (includeStatus?: boolean): desiredColumn[] => {
  const separatedColumns: desiredColumn[] = [];
  separatedColumns.push({
    columnName: 'Line Number',
    columnType: InventoryColumns.lineNumber,
  });
  separatedColumns.push({
    columnName: 'Group Name',
    columnType: InventoryColumns.groupName,
  });
  separatedColumns.push({
    columnName: 'Product Number',
    columnType: InventoryColumns.productNumber,
  });
  separatedColumns.push({
    columnName: 'Product Description',
    columnType: InventoryColumns.productDescription,
  });
  if (includeStatus) {
    separatedColumns.push({
      columnName: 'Product Status',
      columnType: InventoryColumns.productStatus,
    });
    separatedColumns.push({
      columnName: 'Product Type',
      columnType: InventoryColumns.productType,
    });
  }
  separatedColumns.push({
    columnName: 'Brand',
    columnType: InventoryColumns.productBrand,
  });
  separatedColumns.push({
    columnName: 'Pack Size',
    columnType: InventoryColumns.productPackageSize,
  });
  separatedColumns.push({
    columnName: 'Unit Description',
    columnType: InventoryColumns.unitDescription,
  });
  separatedColumns.push({
    columnName: 'Case Price',
    columnType: InventoryColumns.casePriceWithoutUom,
  });

  separatedColumns.push({
    columnName: 'Price UOM',
    columnType: InventoryColumns.casePriceUom,
  });
  separatedColumns.push({
    columnName: 'Unit By Case',
    columnType: InventoryColumns.unitByCase,
  });
  separatedColumns.push({
    columnName: 'Unit Price',
    columnType: InventoryColumns.unitPriceWithoutUom,
  });
  separatedColumns.push({
    columnName: 'Unit Price UOM',
    columnType: InventoryColumns.unitPriceUom,
  });
  separatedColumns.push({
    columnName: 'Vendor',
    columnType: InventoryColumns.vendor,
  });
  return separatedColumns;
};

export const getCostReportCSVColumnsNoProductDetails = (): desiredColumn[] => {
  const separatedColumns: desiredColumn[] = [];
  separatedColumns.push({
    columnName: 'GL Code',
    columnType: InventoryColumns.glCode,
  });
  separatedColumns.push({
    columnName: 'Extended Cost',
    columnType: InventoryColumns.extendedCost,
  });
  return separatedColumns;
};

export const getCostReportCSVColumns = (
  includeGlCodes: boolean,
): desiredColumn[] => {
  const separatedColumns: desiredColumn[] = [];
  separatedColumns.push({
    columnName: 'Line Number',
    columnType: InventoryColumns.lineNumber,
  });
  if (includeGlCodes) {
    separatedColumns.push({
      columnName: 'GL Code',
      columnType: InventoryColumns.glCode,
    });
  }
  separatedColumns.push({
    columnName: 'Group Name',
    columnType: InventoryColumns.groupName,
  });
  separatedColumns.push({
    columnName: 'Product Number',
    columnType: InventoryColumns.productNumber,
  });
  separatedColumns.push({
    columnName: 'Product Description',
    columnType: InventoryColumns.productDescription,
  });
  separatedColumns.push({
    columnName: 'Product Status',
    columnType: InventoryColumns.productStatus,
  });
  separatedColumns.push({
    columnName: 'Product Type',
    columnType: InventoryColumns.productType,
  });
  separatedColumns.push({
    columnName: 'Product Brand',
    columnType: InventoryColumns.productBrand,
  });
  separatedColumns.push({
    columnName: 'Product Package Size',
    columnType: InventoryColumns.productPackageSize,
  });
  separatedColumns.push({
    columnName: 'Unit Description',
    columnType: InventoryColumns.unitDescription,
  });
  separatedColumns.push({
    columnName: 'Unit By Case',
    columnType: InventoryColumns.unitByCase,
  });
  separatedColumns.push({
    columnName: 'Case Count',
    columnType: InventoryColumns.caseCount,
  });
  separatedColumns.push({
    columnName: 'Each Count',
    columnType: InventoryColumns.eachCount,
  });
  separatedColumns.push({
    columnName: 'Product Cost',
    columnType: InventoryColumns.productCost,
  });
  separatedColumns.push({
    columnName: 'Product UOM',
    columnType: InventoryColumns.productUom,
  });
  separatedColumns.push({
    columnName: 'Extended Cost',
    columnType: InventoryColumns.extendedCost,
  });
  return separatedColumns;
};

export const getPDFColumns = (
  includeStatus?: boolean,
  includeFreehandBoxes?: boolean,
): desiredColumn[] => {
  const isMobile = Capacitor.isNativePlatform();
  const separatedColumns: desiredColumn[] = [];
  separatedColumns.push({
    columnName: 'Line #',
    columnType: InventoryColumns.lineNumber,
    cellWidth: 60,
  });
  separatedColumns.push({
    columnName: 'Product #',
    columnType: InventoryColumns.productNumber,
    cellWidth: 95,
  });
  separatedColumns.push({
    columnName: 'Product Description',
    columnType: InventoryColumns.productDescription,
    cellWidth: includeFreehandBoxes ? 240 : undefined,
    halign: 'left',
    cellPadding: 10,
  });
  if (includeStatus) {
    separatedColumns.push({
      columnName: 'Product Status',
      columnType: isMobile
        ? InventoryColumns.productStatus
        : InventoryColumns.imageStatus,
      cellWidth: 105,
    });
    separatedColumns.push({
      columnName: 'Product Type',
      columnType: isMobile
        ? InventoryColumns.productType
        : InventoryColumns.imageType,
      cellWidth: 105,
    });
  }
  separatedColumns.push({
    columnName: 'Brand',
    columnType: InventoryColumns.productBrand,
    cellPadding: 5,
    cellWidth: 100,
  });
  separatedColumns.push({
    columnName: 'Pack Size',
    columnType: InventoryColumns.productPackageSize,
    cellWidth: 100,
  });
  separatedColumns.push({
    columnName: 'Unit Description',
    columnType: InventoryColumns.unitDescription,
    cellWidth: 100,
  });
  if (includeFreehandBoxes) {
    separatedColumns.push({
      columnName: 'On Hand/Manual Count',
      columnType: InventoryColumns.freehandCount,
    });
  }
  separatedColumns.push({
    columnName: 'Case Price',
    columnType: InventoryColumns.casePrice,
    cellWidth: 100,
  });
  separatedColumns.push({
    columnName: 'Unit By Case',
    columnType: InventoryColumns.unitByCase,
    cellWidth: 90,
  });
  separatedColumns.push({
    columnName: 'Unit Price',
    columnType: InventoryColumns.unitPrice,
    cellWidth: 100,
  });
  separatedColumns.push({
    columnName: 'Vendor',
    columnType: InventoryColumns.vendor,
    cellWidth: 80,
  });
  return separatedColumns;
};

export const getCostReportPDFColumnsNoProductDetails = (): desiredColumn[] => {
  const isMobile = Capacitor.isNativePlatform();
  const separatedColumns: desiredColumn[] = [];
  separatedColumns.push({
    columnName: 'GL Code',
    columnType: InventoryColumns.glCode,
    cellWidth: 710,
    halign: 'left',
    cellPadding: 10,
  });
  separatedColumns.push({
    columnName: 'Extended Cost',
    columnType: InventoryColumns.extendedCost,
    cellWidth: 710,
  });
  return separatedColumns;
};

export const getCostReportPDFColumns = (
  includeGlCodes: boolean,
): desiredColumn[] => {
  const isMobile = Capacitor.isNativePlatform();
  const separatedColumns: desiredColumn[] = [];
  separatedColumns.push({
    columnName: 'Line #',
    columnType: InventoryColumns.lineNumber,
    cellWidth: 60,
  });
  if (includeGlCodes) {
    separatedColumns.push({
      columnName: 'GL Code',
      columnType: InventoryColumns.glCode,
      cellWidth: 120,
      halign: 'left',
      cellPadding: 10,
    });
  }
  separatedColumns.push({
    columnName: 'Product #',
    columnType: InventoryColumns.productNumber,
    cellWidth: 95,
  });
  separatedColumns.push({
    columnName: 'Product Description',
    columnType: InventoryColumns.productDescription,
    cellWidth: includeGlCodes ? 235 : undefined,
    halign: 'left',
    cellPadding: 10,
  });
  separatedColumns.push({
    columnName: 'Product Status',
    columnType: isMobile
      ? InventoryColumns.productStatus
      : InventoryColumns.imageStatus,
    cellWidth: 105,
  });
  separatedColumns.push({
    columnName: 'Product Type',
    columnType: isMobile
      ? InventoryColumns.productType
      : InventoryColumns.imageType,
    cellWidth: 105,
  });
  separatedColumns.push({
    columnName: 'Brand',
    columnType: InventoryColumns.productBrand,
    cellPadding: 5,
    cellWidth: 90,
  });
  separatedColumns.push({
    columnName: 'Pack Size',
    columnType: InventoryColumns.productPackageSize,
    cellWidth: 90,
  });
  separatedColumns.push({
    columnName: 'Unit Description',
    columnType: InventoryColumns.unitDescription,
    cellWidth: 90,
  });
  separatedColumns.push({
    columnName: 'Unit By Case',
    columnType: InventoryColumns.unitByCase,
    cellWidth: 60,
  });
  separatedColumns.push({
    columnName: 'Case Count',
    columnType: InventoryColumns.caseCount,
    cellWidth: 60,
  });
  separatedColumns.push({
    columnName: 'Each Count',
    columnType: InventoryColumns.eachCount,
    cellWidth: 60,
  });
  separatedColumns.push({
    columnName: 'Product Cost',
    columnType: InventoryColumns.productCost,
    cellWidth: 80,
  });
  separatedColumns.push({
    columnName: 'Product UOM',
    columnType: InventoryColumns.productUom,
    cellWidth: 80,
  });
  separatedColumns.push({
    columnName: 'Extended Cost',
    columnType: InventoryColumns.extendedCost,
    cellWidth: 90,
  });
  return separatedColumns;
};

export const createMultipleSeperatedColumnsForInventory = (
  items: InventoryItem[],
  productsMap: Map<number, Product>,
  columnConfig: desiredColumn[],
): SeperatedColumn[] => {
  let columnMap = new Map<string, SeperatedColumn>();
  let runningLineNumber = 1;
  items.forEach((item: InventoryProductViewModel) => {
    const product = productsMap.get(item.productNumber);
    columnConfig.forEach(column => {
      columnMap = inventoryFunctionMap[column.columnType](
        column.columnName,
        columnMap,
        product,
        item,
      );
      if (column.columnType === InventoryColumns.lineNumber) {
        runningLineNumber++;
      }
    });
  });

  let separatedColumns: SeperatedColumn[] = [];
  columnMap.forEach(column => {
    separatedColumns.push(column);
  });
  return separatedColumns;
};

export const getInventoryGroupSubTotals = (
  items: InventoryProductViewModel[],
  isGlSort: boolean,
): string[] => {
  const groupSubTotals: string[] = [];
  let currentGroup = isGlSort ? items[0].glString : items[0].groupName;
  let currentSubTotal = 0;
  items.forEach((item: InventoryProductViewModel) => {
    const groupName = isGlSort ? item.glString : item.groupName;
    if (currentGroup !== groupName) {
      groupSubTotals.push(CurrencyService.toUSDString(currentSubTotal));
      currentGroup = groupName;
      currentSubTotal = 0;
    }
    currentSubTotal += item.value;
  });
  groupSubTotals.push(CurrencyService.toUSDString(currentSubTotal));
  return groupSubTotals;
};

export const getInventoryTotal = (
  price: number,
  inventoryName: string,
  noProductDetails: boolean,
  includeGlCodes: boolean,
) => {
  const commas = includeGlCodes ? ',,,,,,,,,,,,,,,' : ',,,,,,,,,,,,,,';
  return (
    'Inventory Total: ' +
    inventoryName +
    (noProductDetails ? ',' : commas) +
    getRawDocumentCurrency(price) +
    '\r\n'
  );
};

export const getGrandTotal = (
  price: number,
  noProductDetails: boolean,
  includeGlCodes: boolean,
) => {
  const commas = includeGlCodes ? ',,,,,,,,,,,,,,,' : ',,,,,,,,,,,,,,';
  return (
    'Grand Total: ' +
    (noProductDetails ? ',' : commas) +
    getRawDocumentCurrency(price) +
    '\r\n'
  );
};

export const getCostReportFileName = (formattedDate: string): string => {
  const dateFormattedWithNoSlashes = formattedDate.split('/').join('');
  return 'Inventory_Cost_Report_' + dateFormattedWithNoSlashes;
};

export const filterInventoryItems = (
  items: InventoryItem[],
  singleGroup: string,
) => {
  if (singleGroup) {
    return items.filter(
      item =>
        item.itemType === ItemTypes.product && item.groupName === singleGroup,
    );
  }
  return items.filter(item => item.itemType === ItemTypes.product);
};
