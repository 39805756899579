import {
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import {
  Platform,
  PopoverController,
  ToastController,
  ModalController,
} from '@ionic/angular';
import {
  PanAppState,
  PlatformService,
  PageInfoService,
  UserState,
  UsfTokenService,
  TokenAuthMode,
  TokenAuthOptions,
  LOCAL_STORAGE_KEYS,
  Customizations,
} from '@panamax/app-state';
import {
  combineLatest,
  fromEvent,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  withLatestFrom,
  take,
  tap,
  skip,
  takeUntil,
} from 'rxjs/operators';
import { Message } from './ngrx-message/models/client/message';
import { MessageStoreService } from './ngrx-message/services/message/message-store.service';
import { NotificationsService } from './notifications/services/notifications.service';
import { PowerReviewService } from './shared/services/power-review.service';
import { BaseComponent } from './shared/components/base/base.component';
import { MessageComponent } from './shared/components/popovers/message/message.component';
import { BrowserPageTitle } from './shared/constants/browser-page-title.enum';
import { AppService } from './shared/services/app.service';
import { I18nConfigService } from './shared/services/i18n-config.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { HotKeyService } from './shared/services/hotkey/hotkey.service';
import { HotKeys } from './shared/constants/hot-key.enum';
import { PromoteAppComponent } from './shared/components/popovers/promote-app/promote-app.component';
import { Device } from '@capacitor/device';
import { FEATURES } from './shared/constants/splitio-features';
import {
  DeliveredNotifications,
  PushNotifications,
} from '@capacitor/push-notifications';
import { RegisterDeviceRequest } from '@usf/alert-types';
import { PATHS } from './shared/constants/paths';
import { CustomerSwitchForbidden } from '@shared/constants/app_error_messages';
import { CustomerStoreService } from './ngrx-customer/services';
import { ToastService } from './shared/services/toast/toast.service';
import { OrderService } from './order/services/order.service';
import { KiboTagService } from './shared/services/kibo-tracking.service';
import { AlertTypeEnum } from './notifications/constants/constants';
import { MessageTypeEnum } from './ngrx-message/constants/messageTypeEnum';
import { ToastMessages } from '@shared/constants/toast-messages.enum';
import { AppErrorService } from './ngrx-message/services/app-error/app-error.service';
import { v4 as uuidv4 } from 'uuid';
import { Appcues } from '@appcues/capacitor';
import { UpdateMoxeService } from './shared/services/update-moxe.service';
import { Script } from './shared/models/script.model';
import { Capacitor } from '@capacitor/core';
import { LoginService } from './login/services';
import { UpcService } from '@shared/services/upc/upc.service';
import { UserService } from '@app/user/services';
import { UserKinds } from '@usf/user-types/user';
import { CustomerSwitchStatus } from './ngrx-customer/constants/customer-switch-status';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  alertCountMessageSub$: Subscription = new Subscription();
  claimTicketSub$: Subscription = new Subscription();
  currentUrlSub$: Subscription = new Subscription();
  customersSub$: Subscription = new Subscription();
  keydownSub$: Subscription = new Subscription();
  loadSubmittedOrderSub$: Subscription = new Subscription();
  messageSub$: Subscription = new Subscription();
  pushActionPerformedUserSub$: Subscription = new Subscription();
  pushReceivedUserSub$: Subscription = new Subscription();
  toastMessageSub$: Subscription = new Subscription();
  queryParam$: Subscription = new Subscription();
  featureFlagAppErrorSub$: Subscription = new Subscription();
  featureFlagAppcuesSub$: Subscription = new Subscription();
  featureFlagBackgroundRefreshSub$: Subscription = new Subscription();
  featureFlagForceAppUpdateSub$: Subscription = new Subscription();
  appcuesIdentifySub$: Subscription;
  appcuesDivisionFlagSub$: Subscription;
  userCustomizationsSub$: Subscription = new Subscription();
  customerSwitchStatus$: Observable<CustomerSwitchStatus>;
  private destroy$ = new Subject<void>();

  logRocketUser: string;
  isDesktop: Boolean;
  customersAvail: any[];
  queryParams: Params;
  currentUserKind: string;

  keydown$: Observable<Event> = fromEvent(document, 'keydown').pipe(
    // Is this an element that we understand and care about?
    filter((event: KeyboardEvent) => {
      // need to use target if srcElement is undefined for older versions of firefox
      const target = event.target as HTMLElement;
      const parent = target.offsetParent as HTMLElement;
      return (
        parent?.hasAttribute(HotKeys.globalKeyHandler) ||
        parent?.offsetParent?.hasAttribute(HotKeys.globalKeyHandler) ||
        event.ctrlKey ||
        event.key === HotKeys.tab ||
        event.key === HotKeys.escape ||
        event.key === HotKeys.arrowDown ||
        event.key === HotKeys.arrowUp ||
        event.key === HotKeys.arrowLeft ||
        event.key === HotKeys.arrowRight ||
        event.key === HotKeys.enter ||
        event.code === HotKeys.period
      );
    }),
    distinctUntilChanged(),
  );

  appcuesTagScript: Script = {
    name: 'appcuesTag',
    src: environment.appcues?.src,
  };

  constructor(
    private route: ActivatedRoute,
    readonly router: Router,
    public popoverController: PopoverController,
    public modalController: ModalController,
    private toastController: ToastController,
    private zone: NgZone,
    readonly platform: Platform,
    protected panAppState: PanAppState,
    private appService: AppService,
    private updateMoxeService: UpdateMoxeService,
    private customerStoreService: CustomerStoreService,
    private hotKeyService: HotKeyService,
    private i18nConfigService: I18nConfigService,
    private messageStoreService: MessageStoreService,
    private notificationsService: NotificationsService,
    private pageInfoService: PageInfoService,
    public platformService: PlatformService,
    private powerReviewService: PowerReviewService,
    private titleService: Title,
    public toastService: ToastService,
    private orderService: OrderService,
    private kiboTagService: KiboTagService,
    private tokenService: UsfTokenService,
    private appErrorService: AppErrorService,
    private loginService: LoginService,
    private upcService: UpcService,
    private userService: UserService,
  ) {
    console.log('App constructor fired');
    super(panAppState);
    this.initializeApp();
  }

  async loadAppcuesScript(): Promise<Script> {
    return new Promise((resolve, reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = this.appcuesTagScript.src;
      script.onload = () => {
        resolve({ loaded: true, name: this.appcuesTagScript.name });
      };

      script.onerror = error =>
        resolve({ loaded: false, name: this.appcuesTagScript.name });

      document.getElementsByTagName('head')[0].appendChild(script);
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event: any) {
    this.platformService.onResize(event);
  }

  @HostListener('window:load', ['$event'])
  async beforeLoad(event: any) {
    // this dispatches an actions to reconnect websockets and set up state for the home page.  this needs more thought.
    if (
      this.router.url.includes('/logout') ||
      this.router.url.includes(PATHS.SCHEDULED_MAINTENANCE) ||
      this.router.url.includes(PATHS.B2C) ||
      this.router.url.includes(PATHS.LOGIN) ||
      this.router.url.includes(PATHS.LOGIN_ERROR)
    ) {
      return;
    }
    console.log(`Host listener fired on route: ${this.router.url}`);
    this.tokenService
      .authorize(TokenAuthMode.Local, {
        disableErrorRedirect: true,
      } as TokenAuthOptions)
      .then(
        () => {
          this.tokenService.dispatch().toPromise();
        },
        e => {
          console.error('Unable to refresh tokens from host listener', e);
        },
      );
  }

  async ngOnInit() {
    this.addModalListener();
    this.addPopoverListener();
    this.keydownSub$ = this.keydown$
      .pipe(takeUntil(this.destroy$))
      .subscribe((keyboardEvent: KeyboardEvent) =>
        this.hotKeyService.onKeyEvent(keyboardEvent, this.router.url),
      );
    this.i18nConfigService.getDefaultLanguage();

    this.listenForVisibilityChanges();
    this.initializeMessageListeners();
    window.dispatchEvent(new Event('resize'));
    this.changeDocumentTitleOnPageChange();
    this.initAppErrors();
    this.powerReviewService.init();
    this.kiboTagService.init();

    let { operatingSystem, context } =
      await this.platformService.getPlatformAndDeviceInfo();
    if (
      context === 'web' &&
      this.detectBrowser(operatingSystem === 'ios') !== 'safari' &&
      (operatingSystem === 'android' || operatingSystem === 'ios')
    ) {
      this.shouldPromoteApp(operatingSystem);
    }
    if (context !== 'web') {
      this.initializePushNotificationsListeners();
      this.registerDeviceForPushNotifications();
    }

    this.isDesktop =
      this.platformService.platformType ===
      this.platformService.platformEnum.desktop;

    this.customerSwitchStatus$ =
      this.customerStoreService.customerSwitchStatus$();
    this.customerSwitchStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe(status => {
        if (status === CustomerSwitchStatus.complete) {
          this.customerStoreService.loadDivisionPhoneNumbers();
        }
      });
  }

  /** This is needed so that modals display in logrocket */
  addModalListener() {
    document.addEventListener('ionModalDidPresent', (ev: CustomEvent) => {
      const { target } = ev;
      const modal = target as HTMLElement;
      this.makeOverlayAppearInLogrocket(modal);
    });
  }

  /** This is needed so that popovers display in logrocket */
  addPopoverListener() {
    document.addEventListener('ionPopoverDidPresent', (ev: CustomEvent) => {
      const { target } = ev;
      const popover = target as HTMLElement;
      this.makeOverlayAppearInLogrocket(popover);
    });
  }

  /**
   * Adds an opacity of 1 to the ion-overlay-wrapper if it exists
   * Adds an opacity of 1 to the popover-content if it exists
   * Adds a transform of none to the ion-overlay-wrapper if it exists
   */
  makeOverlayAppearInLogrocket(target: HTMLElement) {
    console.log('adding opacity to overlay for logrocket');
    let overlayWrapper = target?.shadowRoot?.querySelector(
      '.ion-overlay-wrapper',
    ) as HTMLElement;
    let popoverContent = overlayWrapper?.querySelector(
      '.popover-content',
    ) as HTMLElement;

    if (popoverContent && popoverContent?.style?.opacity !== undefined) {
      popoverContent.style.opacity = '1';
    }
    if (overlayWrapper && overlayWrapper?.style?.opacity !== undefined) {
      overlayWrapper.style.opacity = '1';
    }
    if (overlayWrapper && overlayWrapper?.style?.transform !== undefined) {
      overlayWrapper.style.transform = 'none';
    }
  }

  async logoutOnError(e) {
    //TODO: extract me
    if (e.message.startsWith('AAD_Custom_Error_SessionRevoked')) {
      console.error('Authentication failed: B2C tokens have been revoked');
    }
    console.error(e);
    console.error('B2C authentication failed, redirecting to login');
    await this.tokenService.navigateToLogin();
  }

  initializeApp() {
    this.featureFlagAppcuesSub$ = this.panAppState
      ?.feature$([FEATURES.split_global_appcues])
      ?.subscribe(async on => {
        if (on) {
          Appcues.initialize({
            accountId: environment.appcuesAccountId.toString(),
            applicationId: environment.appcuesApplicationId,
            config: { logging: environment.appcuesLoggingLevel },
          });
          this.loadAppcuesScript();
        }
      });

    this.platform.ready().then(async () => {
      console.log('Platform is ready in app.component');
      this.addAppUrlOpenListener();
      this.handleRouterEvents();
      this.handlePremierUserLogin();

      this.featureFlagForceAppUpdateSub$ = this.panAppState
        .feature$([FEATURES.split_global_force_app_update])
        .subscribe(async (on: boolean) => {
          if (on && Capacitor.isNativePlatform()) {
            this.addForceUpdateAppResumeListener();
          }
        });

      this.featureFlagBackgroundRefreshSub$ = this.panAppState
        .feature$([FEATURES.split_global_background_refresh])
        .subscribe(async on => {
          if (on) {
            this.addRefreshAppResumeListener();
          }
        });
    });
  }

  addAppUrlOpenListener() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(async () => {
        // Appcues POC
        this.featureFlagAppcuesSub$.add(
          this.panAppState
            .feature$([FEATURES.split_global_appcues])
            .subscribe(async on => {
              if (on) {
                Appcues.didHandleURL({
                  url: event.url,
                });
              }
            }),
        );
        let slug =
          event.url.split(environment.mobileDomain + '://').pop() || '';
        slug = slug?.replace(/\/*$/, '');

        //if handling a callback from B2C login, proceed
        if (slug.startsWith('touch/b2c?error')) {
          await this.router.navigateByUrl(slug);
          return;
        }

        const queryParams = this.pageInfoService.getQueryParams(event.url);

        // DEEP LINK VIA CAPACITOR
        if (
          queryParams['deeplink'] == 'true' ||
          (queryParams['deepLinkFlow'] == 'true' &&
            queryParams['originLinkType'] != 'PREFCENTER')
        ) {
          await this.switchToDeeplinkCustomer(event.url);
        }

        if (slug.includes('http')) {
          // USER OPENED APP FROM UNIVERSAL LINK (HTTP/HTTPS)
          let path = this.pageInfoService.getUrlPathFromLink(event.url);
          path = path?.replace(path.charAt(0), '');
          const route = path.split('/');

          queryParams['returnUrl'] = this.pageInfoService.getUrlPathFromLink(
            event.url,
          );
          localStorage.setItem(
            LOCAL_STORAGE_KEYS.params,
            JSON.stringify(queryParams),
          );
          const newRoute = route.filter(obj => {
            return obj !== environment.webDomain && obj !== '';
          });
          this.router.navigate(newRoute, { queryParams });
        } else {
          // USER OPENED APP FROM APP LINK ex.) com.usfoods.ecomr4://)
          let url = `/${this.pageInfoService.getUrlPathFromLink(event.url)}`;
          if (this.isCurrentUrlInsideApp(url)) {
            const queryParams = this.pageInfoService.getQueryParams(event.url);

            queryParams['returnUrl'] = this.pageInfoService.getUrlPathFromLink(
              event.url,
            );
            //TODO: test me
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.params,
              JSON.stringify(queryParams),
            );
          }
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  addForceUpdateAppResumeListener() {
    App.addListener('resume', async () => {
      await this.updateMoxeService.compareVersions(
        this.platformService.getPlatformType(),
      );
    });
  }

  addRefreshAppResumeListener() {
    App.addListener('resume', async () => {
      this.updateMoxeService.checkForMoxeUpdates();
    });
  }

  handleRouterEvents() {
    this.router.events
      .pipe(
        withLatestFrom(this.platformService.isTouchExperience$()),
        filter(([event, isTouch]) => event instanceof NavigationEnd),
      )
      .subscribe(async ([event, isTouch]) => {
        const url = this.pageInfoService.getUrlPath() || '';
        const path = this.pageInfoService.currentUrl.split('?')[0];
        const location = window.location.href;

        const queryParams = this.pageInfoService.getQueryParams(
          window.location.href,
        );
        const navigationState =
          this.router.getCurrentNavigation()?.extras.state;

        if (
          queryParams['deeplink'] == 'true' ||
          (queryParams['deepLinkFlow'] == 'true' &&
            queryParams['originLinkType'] != 'PREFCENTER')
        ) {
          await this.switchToDeeplinkCustomer(window.location.href);
          return;
        }

        // ionic sends b2c callbacks as fragments for web and qeurystring for mobile
        // in either case, allow navigation to continue normally
        if (window.location.hash) {
          return;
        }

        if (
          queryParams['originLinkType'] === 'PREFCENTER' &&
          this.isCurrentUrlInsideApp(url)
        ) {
          // Prevent UPC from opening when navigating to home on touch
          if (this.isDesktop || path === '/touch') {
            this.openUPC();
          }
        }
        // Store the originType if it's set
        this.panAppState.setOriginType(
          this.pageInfoService.getUrlQueryParamter('originType'),
        );
        //Store the link Source if it's set
        this.panAppState.setLinkSource(
          this.pageInfoService.getUrlQueryParamter('lnksrc'),
        );
        //Store the search rank if it's set
        const rank =
          this.pageInfoService.getUrlQueryParamter('rank') ||
          this.pageInfoService.getUrlQueryParamter('merchRank');
        this.panAppState.setRank(rank);
        if (isTouch) {
          this.router.navigate([url?.replace('desktop', 'touch')], {
            queryParams,
            state: navigationState,
          });
        } else {
          this.router.navigate([url?.replace('touch', 'desktop')], {
            queryParams,
            state: navigationState,
          });
        }

        //Appcues
        if (event instanceof NavigationEnd) {
          this.appcuesIdentifySub$ = combineLatest([
            this.panAppState.user$.pipe(
              filter(user => !!user),
              take(1),
            ),
            this.panAppState.customer$.pipe(
              filter(cust => !!cust),
              take(1),
            ),
          ])
            .pipe(
              map(([user, selectedCustomer]) => {
                return {
                  user,
                  selectedCustomer,
                };
              }),
            )
            .subscribe(res => {
              if (res?.user && res?.selectedCustomer) {
                const appcuesTitle =
                  url === '/'
                    ? 'home'
                    : url.replace('/touch/', '').replace('/desktop/', '');
                this.featureFlagAppcuesSub$ = this.panAppState
                  ?.feature$([FEATURES.split_global_appcues])
                  ?.subscribe(async on => {
                    if (on) {
                      this.appcuesDivisionFlagSub$ = this.panAppState
                        ?.feature$([FEATURES.split_division_alerts_appcues])
                        ?.subscribe(async on => {
                          if (on) {
                            window['Appcues'].page();
                            Appcues.screen({ title: appcuesTitle });
                          }
                        });
                    }
                  });
              }
            });
        }
      });
  }

  /**
   * Redirect premier users trying to login to Moxe via "order.usfoods.com" to "premierfsdo.com"
   */
  handlePremierUserLogin() {
    //do not redirect for mobile or punchout cases
    if (
      Capacitor.isNativePlatform() ||
      this.pageInfoService.getUrlPath()?.match('/login/punch(out|thru)')
    ) {
      return;
    }
    this.userCustomizationsSub$ = this.userService
      .userCustomizations$()
      .pipe(
        filter(siteCustomization => !!siteCustomization),
        take(1),
      )
      .subscribe(async siteCustomization => {
        const urlInfo = this.pageInfoService.getUrlInfo();
        if (
          urlInfo.hostName &&
          urlInfo.hostName.toLocaleLowerCase() ===
            environment.webDomain.toLocaleLowerCase() &&
          siteCustomization.profileName &&
          siteCustomization.profileName.toLocaleLowerCase() ===
            Customizations.PREMIER.toLocaleLowerCase()
        ) {
          console.log(
            'Detected premier user login to ' +
              environment.webDomain +
              '. Redirecting to ' +
              environment.premierWebDomain,
          );
          this.replaceWindowLocation('https://' + environment.premierWebDomain);
        }
      });
  }

  listenForVisibilityChanges() {
    document.onvisibilitychange = ev => {
      this.handleVisibilityChange(document.visibilityState);
    };
  }

  handleVisibilityChange(visibilityState: string) {
    if (visibilityState === 'hidden') {
      this.appService.appBackground();
    } else if (visibilityState === 'visible') {
      this.appService.appForeground();
    }
  }

  initAppErrors() {
    const splitAppErrorModal$ = this.panAppState.feature$([
      FEATURES.split_global_user_app_error_modal,
    ]);
    const appErrors$ = this.appErrorService
      .selectModalErrors$()
      .pipe(filter(errors => !!errors.length));
    combineLatest([splitAppErrorModal$, appErrors$])
      .pipe(
        skip(1),
        tap(appErrors => {
          let count = 0;
          for (const error of appErrors) {
            count++;
            this.appErrorService.setCount(count);
          }
        }),
      )
      .subscribe(([splitFeature, appErrors]) => {
        if (splitFeature) {
          this.appErrorService.presentModal();
        }
      });
  }

  initializeMessageListeners() {
    this.messageSub$ = this.messageStoreService
      .selectMessage$()
      .pipe(filter(msg => !!msg))
      .subscribe(message => {
        this.presentModal(message);
      });
    this.toastMessageSub$ = this.messageStoreService
      .selectToastMessage$()
      .pipe(
        filter(toastMessage => !!toastMessage),
        debounceTime(200),
      )
      .subscribe(toasts => {
        const updateToasts = [];
        for (const toast of toasts) {
          this.openToast(toast);
          const updateToast = {
            ...toast,
            read: true,
          };
          updateToasts.push(updateToast);
        }
        this.messageStoreService.upsertManyMessages(updateToasts);
      });
    this.alertCountMessageSub$ = combineLatest([
      this.messageStoreService
        .selectAlertCountMessage$()
        .pipe(filter(alertCount => !!alertCount)),
      this.panAppState.user$.pipe(
        filter(user => !!user),
        take(1),
      ),
      this.panAppState.customer$.pipe(
        filter(cust => !!cust),
        take(1),
      ),
    ])
      .pipe(
        map(([alertCount, user, selectedCustomer]) => {
          return {
            alertCount,
            user,
            selectedCustomer,
          };
        }),
      )
      .subscribe(res => {
        if (res?.alertCount && res?.user && res?.selectedCustomer) {
          const count: any = { ...res.alertCount };
          if (count?.ids?.indexOf(res.user.ecomUserId.toString()) > -1) {
            this.notificationsService.setUnreadAlertsCount(
              +count?.entities[res.user.ecomUserId].alertCount,
            );
            if (
              count?.entities[res.user.ecomUserId].customerNumber ===
              res.selectedCustomer.customerNumber
            ) {
              this.notificationsService.setUnreadNotesCount(
                +count?.entities[res.user.ecomUserId].noteCount,
              );
            }
          }
        }
      });
  }

  async presentModal(message: Message) {
    const modal = await this.modalController.create({
      component: MessageComponent,
      cssClass: 'error-modal',
      showBackdrop: true,
      backdropDismiss: true,
      componentProps: {
        message,
      },
    });
    if (modal) {
      modal.onDidDismiss().then((resp: any) => {
        if (resp.data) {
          this.messageStoreService.upsertMessage(resp.data);
          if (resp.data.dismissRoute) {
            this.redirectTo(resp.data.dismissRoute);
          }
        }
      });
      return await modal.present();
    }
  }

  async shouldPromoteApp(operatingSystem: string) {
    this.currentUrlSub$ = this.currentUrl$
      .pipe(
        filter(url => url && this.isCurrentUrlInsideApp(url)),
        take(1),
      )
      .subscribe(async () => {
        const modal = await this.modalController.create({
          component: PromoteAppComponent,
          cssClass: 'promote-app-modal',
          showBackdrop: true,
          backdropDismiss: true,
          componentProps: {
            operatingSystem,
          },
        });
        if (
          modal &&
          localStorage.getItem(LOCAL_STORAGE_KEYS.promoAppBanner) !==
            'dismissed'
        ) {
          modal.onDidDismiss().then((resp: any) => {
            localStorage.setItem(
              LOCAL_STORAGE_KEYS.promoAppBanner,
              'dismissed',
            );
          });
          return await modal.present();
        }
      });
  }

  openUPC() {
    this.upcService.openUPC();
  }

  async openToast(toastMessage: Message) {
    this.toastService.presentToast(toastMessage);
  }

  changeDocumentTitleOnPageChange() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return BrowserPageTitle.default;
        }),
      )
      .subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
  }

  redirectTo(path: any) {
    const url = window.location.pathname;
    this.router.navigate([url.split('/')[1], ...path]);
  }

  async initializePushNotificationsListeners() {
    let { platform, operatingSystem } = await Device.getInfo();
    if (platform !== 'web') {
      PushNotifications.removeAllListeners();
      await PushNotifications.addListener('registration', async token => {
        const deviceId = await Device.getId();
        const pushDeviceInfo: RegisterDeviceRequest = {
          deviceId: deviceId?.identifier,
          deviceToken: token?.value,
        };
        localStorage.setItem(
          LOCAL_STORAGE_KEYS.pushDeviceInfo,
          JSON.stringify(pushDeviceInfo),
        );
      });
      await PushNotifications.addListener('registrationError', err => {
        console.log('Registration error: ', JSON.stringify(err));
      });
      await PushNotifications.addListener(
        'pushNotificationReceived',
        notification => {
          this.pushReceivedUserSub$ = this.panAppState.user$
            .pipe(take(1))
            .subscribe(async user => {
              if (user) {
                if (operatingSystem === 'android') {
                  PushNotifications.getDeliveredNotifications().then(
                    deliveredNotifications => {
                      const deliveredNotificationToRemove: DeliveredNotifications =
                        {
                          notifications: [
                            deliveredNotifications.notifications.find(
                              n =>
                                n?.body === notification?.body &&
                                n?.title === notification?.title &&
                                !n.tag,
                            ),
                          ],
                        };
                      PushNotifications.removeDeliveredNotifications(
                        deliveredNotificationToRemove,
                      ).then(() => {
                        this.showPushToast(user, notification);
                      });
                    },
                  );
                } else {
                  this.notificationsService.pushNotificationOpened(
                    notification,
                  );
                }
              }
            });
        },
      );
      await PushNotifications.addListener(
        'pushNotificationActionPerformed',
        notification => {
          this.pushActionPerformedUserSub$ = this.panAppState.user$
            .pipe(debounceTime(600), take(1))
            .subscribe(user => {
              if (user && user?.userId && user?.userKind) {
                this.currentUserKind = user.userKind;
                this.alertUser(user, notification?.notification);
              } else {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.lastTappedPushWhileLoggedOff,
                  JSON.stringify(notification?.notification),
                );
              }
            });
        },
      );
    }
  }

  // need to be able to mock this method in unit tests by creating a wrapper
  getTransactionId() {
    return uuidv4();
  }

  alertUser(user: UserState, notification: any) {
    if (notification?.data?.alertId?.toString() === AlertTypeEnum.TM_NOTE) {
      if (
        user.userId !==
        notification?.data?.userName?.toString().replace('[AD_AUTO]', '').trim()
      ) {
        // notification is intended for another user; route to TM Note tab with error modal
        this.routeToNotificationPath(notification, true);
      } else {
        this.customersSub$ = this.customerStoreService
          .loadCustomers$()
          .pipe(
            filter(customers => !!customers.length),
            take(1),
          )
          .subscribe(async customers => {
            this.customersAvail = customers;
            if (
              notification?.data &&
              !this.customersAvail.find(
                c => c.customerNumber === +notification.data.customerNumber,
              )
            ) {
              // If the user is no longer associated with the customer, route to TM Note tab with error modal
              this.routeToNotificationPath(notification, true);
            } else {
              try {
                await this.tokenService.refresh({
                  customerNumber: +notification.data.customerNumber,
                  departmentNumber: +notification.data.departmentNumber,
                  divisionNumber: +notification.data.divisionNumber,
                });
                this.routeToNotificationPath(notification);
              } catch (e) {
                //catch customer switch errors, notify the user and navigate home
                if (e.status === 403) {
                  this.showCustomerSwitchError(e);
                }
              }
            }
          });
        this.notificationsService.markAsReadTmNote(notification?.data?.noteId);
      }
    } else {
      this.notificationsService.markAsRead(
        notification?.data?.alertBatchId?.toString(),
      );
      this.routeToNotificationPath(notification);
    }
  }

  routeToNotificationPath(notification: any, routeToTmNoteTab?: boolean) {
    this.zone.run(() => {
      this.notificationsService.pushNotificationOpened(notification);
      if (
        notification?.data?.alertId?.toString() ===
          AlertTypeEnum.DELIVERY_EXCEPTION ||
        notification?.data?.alertId?.toString() ===
          AlertTypeEnum.DELIVERY_HEADSUP ||
        notification?.data?.alertId?.toString() ===
          AlertTypeEnum.DIRECT_SHIP_TRACKING ||
        notification?.data?.alertId?.toString() ===
          AlertTypeEnum.DIRECT_STANDARD_DELIVERY ||
        notification?.data?.alertId?.toString() ===
          AlertTypeEnum.DIRECT_ORDER_EXCEPTIONS ||
        notification?.data?.alertId?.toString() ===
          AlertTypeEnum.DIRECT_DELIVERY_EXCEPTIONS
      ) {
        const orderNumbers = notification?.data?.orderNumber?.split(',');
        if (orderNumbers?.length === 1) {
          this.loadSubmittedOrderSub$ = this.orderService
            .selectOrderWithTandemNumber(Number(orderNumbers[0]))
            .subscribe(async order => {
              if (order) {
                const orderInfo =
                  `${order?.orderHeader?.customerNumber}:` +
                  `${order?.orderHeader?.departmentNumber}:` +
                  `${order?.orderHeader?.orderId}`;
                notification.data.path = notification?.data?.path?.replace(
                  '/my-orders',
                  `/order/submitted-order/${orderInfo}`,
                );
                try {
                  await this.loginService.switchToCustomer(
                    order?.orderHeader?.customerNumber,
                    order?.orderHeader?.departmentNumber,
                    order?.orderHeader?.divisionNumber,
                  );
                  if (
                    notification?.data?.path &&
                    notification?.data?.path?.indexOf('?') > -1
                  ) {
                    const path = notification?.data?.path?.split('?')[0];
                    const query = notification?.data?.path?.split('?')[1];
                    const params = query.split('&');
                    const finalParams = this.getQueryParams(params);
                    this.router.navigate([path], {
                      queryParams: finalParams,
                    });
                  }
                } catch (e) {
                  //catch customer switch errors, notify the user and navigate home
                  if (e.status === 403) {
                    this.showCustomerSwitchError(e);
                    return;
                  }
                }
              }
            });
        } else {
          if (
            notification?.data?.path &&
            notification?.data?.path?.indexOf('?') > -1
          ) {
            const path = notification?.data?.path?.split('?')[0];
            const query = notification?.data?.path?.split('?')[1];
            const params = query.split('&');
            const finalParams = this.getQueryParams(params);
            this.router.navigate([path], {
              queryParams: finalParams,
            });
          }
        }
      } else {
        if (routeToTmNoteTab) {
          this.notificationsService.handleNoteError();
        } else if (
          notification?.data?.path &&
          notification?.data?.path?.indexOf('?') > -1
        ) {
          const path = notification?.data?.path?.split('?')[0];
          const query = notification?.data?.path?.split('?')[1];
          const params = query.split('&');
          const finalParams = this.getQueryParams(params);
          this.router.navigate([path], {
            queryParams: finalParams,
          });
        }
      }
    });
  }

  async registerDeviceForPushNotifications() {
    if (
      this.platformService.platformType !==
      this.platformService.platformEnum.desktop
    ) {
      let permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
      }
      await PushNotifications.register();
    }
  }

  async showPushToast(user: UserState, notification: any) {
    const toast = await this.toastController.create({
      header: notification?.title,
      message: notification?.body,
      cssClass: 'push-toast',
      duration: 4000,
      buttons: [
        {
          icon: 'close-circle',
          role: 'cancel',
        },
      ],
      position: 'top',
      icon: 'information-circle-outline',
      mode: 'ios',
    });

    await toast?.present().then(() => {
      const toastElement = document.getElementsByClassName('push-toast');
      const shadowRoot = toastElement[0]?.shadowRoot;
      const childNodes = Array.from(shadowRoot?.childNodes);
      childNodes.forEach((childNode: any) => {
        const container = childNode.getElementsByClassName('toast-container');
        container[0].addEventListener('click', () => {
          this.toastController.dismiss(null, 'deeplink');
        });
      });
    });
    await toast?.onDidDismiss().then(event => {
      if (event?.role === 'deeplink') {
        this.alertUser(user, notification);
      }
    });
  }

  isCurrentUrlInsideApp(url: string) {
    return (
      url?.indexOf('b2c') < 0 &&
      url !== '/' &&
      url !== `/${PATHS.LOGIN}` &&
      url !== `/${PATHS.LOGOUT}` &&
      url !== `/${PATHS.LOGIN_ERROR}` &&
      url?.indexOf('404') < 0
    );
  }

  detectBrowser(isIos: boolean) {
    const agent = navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edg') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1:
        return 'opera';
      case agent.indexOf('chrome') > -1:
      case agent.indexOf('crios') > -1:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
      case agent.indexOf('fxios') > -1:
      case agent.indexOf('Macintosh') > -1 && isIos:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  async switchToDeeplinkCustomer(url): Promise<void> {
    console.log('Switching customers from deep link', url);
    const queryParams = this.pageInfoService.getQueryParams(url);
    const deeplink = queryParams['deeplink'];
    const deepLinkFlow = queryParams['deepLinkFlow'];
    const customerNumber = parseInt(queryParams['customerNumber']);
    const departmentNumber = parseInt(queryParams['departmentNumber'] ?? '0');
    const divisionNumber = parseInt(queryParams['divisionNumber']);

    if (
      (deeplink == 'true' || deepLinkFlow == 'true') &&
      !!customerNumber &&
      !!divisionNumber
    ) {
      if (
        !Number.isInteger(customerNumber) ||
        !Number.isInteger(departmentNumber) ||
        !Number.isInteger(divisionNumber)
      ) {
        this.showCustomerSwitchError(new Error('Invalid customer context'));
        return;
      }

      try {
        await this.loginService.switchToCustomer(
          customerNumber,
          departmentNumber,
          divisionNumber,
        );
        return;
      } catch (e) {
        //catch customer switch errors, notify the user and navigate home
        if (e.status === 403) {
          this.showCustomerSwitchError(e);
          return;
        }
      }
    }
  }

  private showCustomerSwitchError(e) {
    console.error('Unable to switch customers', e);
    this.featureFlagAppErrorSub$ = this.panAppState
      .feature$([FEATURES.split_global_user_app_error_modal])
      .subscribe(async on => {
        if (on) {
          this.appErrorService.appModalErrorHandler(
            CustomerSwitchForbidden.title,
            CustomerSwitchForbidden.description,
            ['home'],
            e,
          );
        } else {
          this.messageStoreService.upsertMessage({
            type: MessageTypeEnum.error,
            read: false,
            toast: true,
            display: ToastMessages.customerSwitchForbidden,
          } as Message);
          this.router.navigate([PATHS.HOME]);
        }
      });
  }

  getQueryParams(query: any) {
    const finalParams = {};
    query.forEach(key => {
      var tokens = key.split('=');
      finalParams[tokens[0]] = tokens[1];
    });
    return finalParams;
  }

  replaceWindowLocation(url: any) {
    window.location.replace(url);
  }

  ngOnDestroy(): void {
    if (this.alertCountMessageSub$) {
      this.alertCountMessageSub$.unsubscribe();
    }
    if (this.claimTicketSub$) {
      this.claimTicketSub$.unsubscribe();
    }
    if (this.currentUrlSub$) {
      this.currentUrlSub$.unsubscribe();
    }
    if (this.customersSub$) {
      this.customersSub$.unsubscribe();
    }
    if (this.keydownSub$) {
      this.keydownSub$.unsubscribe();
    }
    if (this.loadSubmittedOrderSub$) {
      this.loadSubmittedOrderSub$.unsubscribe();
    }
    if (this.messageSub$) {
      this.messageSub$.unsubscribe();
    }
    if (this.pushActionPerformedUserSub$) {
      this.pushActionPerformedUserSub$.unsubscribe();
    }
    if (this.pushReceivedUserSub$) {
      this.pushReceivedUserSub$.unsubscribe();
    }
    if (this.toastMessageSub$) {
      this.toastMessageSub$.unsubscribe();
    }
    if (this.queryParam$) {
      this.queryParam$.unsubscribe();
    }
    if (this.featureFlagAppErrorSub$) {
      this.featureFlagAppErrorSub$.unsubscribe();
    }
    if (this.featureFlagAppcuesSub$) {
      this.featureFlagAppcuesSub$.unsubscribe();
    }
    if (this.featureFlagBackgroundRefreshSub$) {
      this.featureFlagBackgroundRefreshSub$.unsubscribe();
    }
    if (this.featureFlagForceAppUpdateSub$) {
      this.featureFlagForceAppUpdateSub$.unsubscribe();
    }
    if (this.appcuesIdentifySub$) {
      this.appcuesIdentifySub$.unsubscribe();
    }
    if (this.appcuesDivisionFlagSub$) {
      this.appcuesDivisionFlagSub$.unsubscribe();
    }
    if (this.userCustomizationsSub$) {
      this.userCustomizationsSub$.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
