import { Component } from '@angular/core';
import {
  ActivatedRoute,
  Params,
  Router,
  convertToParamMap,
} from '@angular/router';
import { App, AppLaunchUrl } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ViewDidEnter, ViewWillEnter } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  Customizations,
  LOCAL_STORAGE_KEYS,
  PageInfoService,
  PanAppState,
  PlatformService,
  TokenAuthMode,
  TokenAuthOptions,
  UsabillaService,
  UsfTokenService,
  selectMinimumVersionsLoaded,
} from '@panamax/app-state';
import { filter, firstValueFrom, lastValueFrom, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PATHS } from '../shared/constants/paths';
import { FEATURES } from '../shared/constants/splitio-features';
import { UpdateMoxeService } from '../shared/services/update-moxe.service';
import { UserDataService } from '@app/user/services/user-data.service';
import { LoginAnalyticsService } from './analytics/login-analytics.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements ViewWillEnter, ViewDidEnter {
  displayContent = false;
  queryParams: Params;
  hostname = 'http://test';
  currentYear: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    readonly panAppState: PanAppState,
    private pageInfoService: PageInfoService,
    readonly platformService: PlatformService,
    private tokenService: UsfTokenService,
    private updateMoxeService: UpdateMoxeService,
    private usabillaService: UsabillaService,
    private userDataService: UserDataService,
    private loginAnalyticsService: LoginAnalyticsService,
  ) {}

  /**
   * Use ionViewWillEnter instead of ngOnInit as ngOnInit doesn't fire
   * on subsequent visits.
   */
  async ionViewWillEnter(): Promise<void> {
    this.displayContent = true;
    this.usabillaService.hide();
    this.currentYear = new Date().getFullYear();
  }

  private async isNewUserInvitationDeepLink() {
    let isNewUserInvitationDeepLink = false;
    if (Capacitor.isNativePlatform()) {
      const appLaunchUrl: AppLaunchUrl = await App.getLaunchUrl();
      isNewUserInvitationDeepLink =
        appLaunchUrl && appLaunchUrl.url.includes('invitationCode');
    } else {
      const queryParams = this.activatedRoute.snapshot.queryParamMap;
      isNewUserInvitationDeepLink =
        queryParams && queryParams.has('invitationCode');
    }
    console.log(
      '[LoginPage] isNewUserInvitationDeepLink',
      isNewUserInvitationDeepLink,
    );
    return isNewUserInvitationDeepLink;
  }

  private clearEcomR4ParamsOnLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.params);
  }

  private async getNewUserInvitationCode() {
    let invitationCode = '';
    let queryParams;
    if (Capacitor.isNativePlatform()) {
      const appLaunchUrl: AppLaunchUrl = await App.getLaunchUrl();
      if (appLaunchUrl && appLaunchUrl.url.includes('invitationCode')) {
        queryParams = convertToParamMap(
          this.pageInfoService.getQueryParams(appLaunchUrl.url),
        );
        invitationCode = queryParams.get('invitationCode');
      }
    } else {
      queryParams = this.activatedRoute.snapshot.queryParamMap;
      if (queryParams && queryParams.has('invitationCode')) {
        invitationCode = queryParams.get('invitationCode');
      }
    }
    console.log('[LoginPage] New user InvitationCode', invitationCode);
    return invitationCode;
  }

  private async handleNewUserInvitationDeepLink() {
    console.log('[LoginPage] Processing new user invitation deep link');
    try {
      const options: TokenAuthOptions = {};
      const invitationCode = await this.getNewUserInvitationCode();

      const invitationToken = await this.userDataService
        .getInvitationToken(invitationCode)
        .toPromise();
      options.invitationToken = invitationToken.token;
      options.disableErrorRedirect = true;

      //Deleting LOCAL_STORAGE_KEYS.params before navigating to B2C Registration to return to home page on successful user registration
      console.log(
        '[LoginPage] Clearing LOCAL_STORAGE_KEYS before navigating to B2C Registration',
        LOCAL_STORAGE_KEYS.params,
      );
      this.clearEcomR4ParamsOnLocalStorage();

      this.loginAnalyticsService.trackNewUserPageLoad();

      console.log('[LoginPage] Redirecting to B2C new user registration page');
      await this.tokenService.navigateToLogin(options);
    } catch (e) {
      console.error(
        '[LoginPage] Exception occurred when redirecting to B2C new user registration page',
        e,
      );

      this.router.navigate([PATHS.LOGIN_ERROR], {
        queryParams: { errorStatus: 404 },
      });
      throw e;
    }
    return;
  }

  private async isCCANewUserInvitationDeepLink() {
    let isCCANewUserInvitationDeepLink = false;
    if (Capacitor.isNativePlatform()) {
      const appLaunchUrl: AppLaunchUrl = await App.getLaunchUrl();
      isCCANewUserInvitationDeepLink =
        appLaunchUrl && appLaunchUrl.url.includes('ccaInvitationCode');
    } else {
      const queryParams = this.activatedRoute.snapshot.queryParamMap;
      isCCANewUserInvitationDeepLink =
        queryParams && queryParams.has('ccaInvitationCode');
    }
    console.log(
      '[LoginPage] isCCANewUserInvitationDeepLink',
      isCCANewUserInvitationDeepLink,
    );
    return isCCANewUserInvitationDeepLink;
  }

  private async getCCANewUserInvitationCode() {
    let ccaInvitationCode = '';
    let queryParams;
    if (Capacitor.isNativePlatform()) {
      const appLaunchUrl: AppLaunchUrl = await App.getLaunchUrl();
      if (appLaunchUrl && appLaunchUrl.url.includes('ccaInvitationCode')) {
        queryParams = convertToParamMap(
          this.pageInfoService.getQueryParams(appLaunchUrl.url),
        );
        ccaInvitationCode = queryParams.get('ccaInvitationCode');
      }
    } else {
      queryParams = this.activatedRoute.snapshot.queryParamMap;
      if (queryParams && queryParams.has('ccaInvitationCode')) {
        ccaInvitationCode = queryParams.get('ccaInvitationCode');
      }
    }
    console.log('[LoginPage] New user ccaInvitationCode', ccaInvitationCode);
    return ccaInvitationCode;
  }

  private async handleCCANewUserInvitationDeepLink() {
    console.log('[LoginPage] Processing CCA new user invitation deep link');
    try {
      const options: TokenAuthOptions = {};
      const ccaInvitationCode = await this.getCCANewUserInvitationCode();

      const ccaInvitationToken = await this.userDataService
        .getCCAInvitationToken(ccaInvitationCode)
        .toPromise();
      options.ccaInvitationToken = ccaInvitationToken.token;
      options.disableErrorRedirect = true;

      //Deleting LOCAL_STORAGE_KEYS.params before navigating to B2C passwordless policy endpoint to return to home page on successful MFA setup
      console.log(
        '[LoginPage] Clearing LOCAL_STORAGE_KEYS before navigating to B2C passwordless policy endpoint',
        LOCAL_STORAGE_KEYS.params,
      );
      this.clearEcomR4ParamsOnLocalStorage();

      this.loginAnalyticsService.trackNewUserPageLoad();

      console.log(
        '[LoginPage] Redirecting to B2C passwordless policy endpoint',
      );
      await this.tokenService.navigateToLogin(options);
    } catch (e) {
      console.error(
        '[LoginPage] Exception occurred when redirecting to B2C passwordless policy endpoint',
        e,
      );

      this.router.navigate([PATHS.LOGIN_ERROR], {
        queryParams: { errorStatus: 404 },
      });
      throw e;
    }
    return;
  }

  async ionViewDidEnter(): Promise<void> {
    let forceAppUpdateFeatureFlag = await this.getForceAppUpdateFeatureFlag();
    let forcedUpdate = false;
    let redirectUnauthenticatedUserToGuestFeatureFlag =
      await this.getRedirectNewUserToGuestFeatureFlag();

    if (forceAppUpdateFeatureFlag && Capacitor.isNativePlatform()) {
      this.panAppState.getMinimumVersions();
      const minimumVersionsLoaded = await lastValueFrom(
        this.store.select(selectMinimumVersionsLoaded()).pipe(
          filter(minimumVersionsLoaded => minimumVersionsLoaded === true),
          take(1),
        ),
      );

      if (minimumVersionsLoaded === true) {
        forcedUpdate = await this.updateMoxeService.compareVersions(
          this.platformService.getPlatformType(),
        );
        if (forcedUpdate) {
          return;
        }
      }
    }

    let options: TokenAuthOptions;

    try {
      options = {
        ...(await this.getAuthOptions()),
        disableErrorRedirect: true,
      };
    } catch (e) {
      return;
    }

    try {
      // If new user deeplink received, redirect to b2c registration page
      if (await this.isNewUserInvitationDeepLink()) {
        await this.handleNewUserInvitationDeepLink();
      } else if (await this.isCCANewUserInvitationDeepLink()) {
        // If CCAUserInvitation deeplink received to accept new user login, redirect to b2c passwordless policy endpoint
        await this.handleCCANewUserInvitationDeepLink();
      } else if (redirectUnauthenticatedUserToGuestFeatureFlag) {
        // If redirectUnauthenticatedUserToGuestFeatureFlag = true, redirect user to guest page
        this.router.navigate([PATHS.GUEST_LOGIN]);
      } else {
        // Else, try to authorize request and navigate to login or other pages appropriately
        await this.tokenService.authorize(TokenAuthMode.Local, options);
        this.navigate();
      }
    } catch (e) {
      try {
        if (e?.status === 503) {
          console.log('[LoginPage] Navigating to scheduled maintenance page');
          await this.tokenService.navigateToScheduledMaintenance();
          return;
        }
        console.log('[LoginPage] No tokens found, navigating to login', e);
        this.clearEcomR4ParamsOnLocalStorage();
        await this.tokenService.navigateToLogin(options);
      } catch (e) {
        console.error(
          '[LoginPage] ERROR: Problem occurred during auth connect login' +
            '\n',
          e,
        );
        this.clearEcomR4ParamsOnLocalStorage();
        this.router.navigate([PATHS.LOGIN_ERROR], {
          queryParams: { errorStatus: e?.status },
        });
        console.log(
          '***** [LoginPage ionViewDidEnter()] NAVIGATED TO ERROR PAGE CALLED',
        );
      }
    }

    return;
  }

  async getForceAppUpdateFeatureFlag() {
    return await firstValueFrom(
      this.panAppState.feature$([FEATURES.split_global_force_app_update]),
    );
  }

  async getRedirectNewUserToGuestFeatureFlag() {
    return await firstValueFrom(
      this.panAppState.feature$([
        FEATURES.split_global_redirect_unauthenticated_users_to_guest,
      ]),
    );
  }

  // TODO: Duplicated from oauth.page.ts. Refactor into a utility.
  private navigate() {
    let r4params =
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.params)) || {};
    console.log(r4params);

    //handle retrieve deep link navigation params
    if (r4params?.returnUrl) {
      const queryParams = { ...r4params };
      delete queryParams.returnUrl;
      this.router.navigate([r4params.returnUrl], {
        queryParams: queryParams,
      });
      localStorage.removeItem(LOCAL_STORAGE_KEYS.params);
    } else {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.params);
      this.router.navigate([PATHS.HOME]);
    }
  }

  async getAuthOptions(): Promise<TokenAuthOptions> {
    const options: TokenAuthOptions = {};
    const urlInfo = this.pageInfoService.getUrlInfo();
    const queryParams = this.activatedRoute.snapshot.queryParamMap;
    let nativeAppName: string;

    // App.getInfo throws unimplemented on web, so guard here
    if (Capacitor.isNativePlatform()) {
      nativeAppName = (await App.getInfo()).name;
    }

    if (
      environment.premierWebDomain &&
      urlInfo.hostName &&
      urlInfo.hostName.match(environment.premierWebDomain)
    ) {
      options.customization = Customizations.PREMIER;
    }

    if (
      queryParams.has('automation') ||
      (nativeAppName && nativeAppName.indexOf('saucelabs') > -1)
    ) {
      options.testAutomation = true;
    }

    if (
      queryParams.get('impersonation') === 'true' &&
      queryParams.has('targetUsername')
    ) {
      options.impersonationUserId = queryParams.get('targetUsername');
      if (queryParams.get('isInternal') === 'true') {
        options.internalUserImpersonation = true;
      }
      if (queryParams.get('isPunchout') === 'true') {
        options.punchoutUserImpersonation = true;
      }
    }
    return options;
  }

  //NOTE: commented out during login refactor.  revisit.
  // emailSignup(url: string): void {
  //   let tempTarget = new URL(this.hostname + url);
  //   let emailToken = tempTarget.searchParams.get('id_token_hint');
  //   this.microsoftLogin('emailSignup', undefined, undefined, emailToken);
  // }
}
