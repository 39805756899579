import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Tracking } from '@panamax/app-state';
import { Product } from '@shared/models/product.model';
import { SharedTrackingService } from '@shared/services/analytics/shared-tracking.service';
import { genericTracingTransformer } from '@shared/tracking/tracing/generic-tracing-transformer';
import { Order, OrderHeader, OrderItem } from '@usf/ngrx-order';
import { ORDER_TRACING_ACTIONS } from './order-tracing.actions';
import { OrderTracingMessages } from './order-tracing.constants';
import { getOrderDetailTrace } from './trace-models/get-order-detail.trace';
import { TracingProduct } from '@shared/models/tracking/tracing/tracing-product';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { cancelOrderTrace } from './trace-models/cancel-order.trace';
import { OrderTracingMessagesTest } from 'src/app/order/pages/order-submitted/tracking/tracing/order-submitted-tracing.constants';
import { ImportOrderTransaction } from '@usf/ngrx-order/lib/reducers/import-orders/import-orders.reducer';
import { IMPORT_ORDER_VALIDATION_TRACING_ACTIONS } from '@order/pages/import-order-validation/tracking/tracing/import-order-validation-tracing.actions';
import { ImportOrderValidationTracingConstants } from '@order/pages/import-order-validation/tracking/tracing/import-order-validation-tracing.constants';
import { shipToConversionWebsocketTrace } from './trace-models/ship-to-conversion.trace';

@Injectable({
  providedIn: 'root',
})
export class OrderTracingService {
  constructor(
    private store: Store,
    private productAnalyticsService: ProductAnalyticsService,
  ) {}

  static deepRemoveUndefined(obj) {
    if (typeof obj !== 'object' || obj === null || Array.isArray(obj)) {
      return obj;
    }

    return Object.entries(obj).reduce((acc, [key, value]) => {
      if (
        value !== undefined &&
        value !== null &&
        JSON.stringify(value) !== '{}'
      ) {
        acc[key] = OrderTracingService.deepRemoveUndefined(value);
      }
      return acc;
    }, {});
  }

  static createAddToOrderTrace(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    productMap: any,
    isStartOfTrace: boolean,
    isEndOfTrace: boolean,
  ) {
    const tracingProducts = SharedTrackingService.tracingProductData(
      orderItems,
      productMap,
    );

    const tracking = {
      tracing: {
        data: {
          isEndOfTrace,
          isStartOfTrace,
          traceContext: OrderTracingMessages.capability,
          attributes: {
            event: OrderTracingMessages.addToOrder,
            order: {
              orderId: orderHeader?.orderId,
              totalAmount: orderHeader?.totalPrice,
              origOrderId: orderHeader?.origOrderId,
              origSplitOrderId: orderHeader?.origSplitOrderId,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
            products: tracingProducts,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    return OrderTracingService.deepRemoveUndefined(tracking);
  }

  static createCancelIPOrderTrace(
    payload: any,
    productMap: any,
    isStartOfTrace: boolean,
    isEndOfTrace: boolean,
  ) {
    const trace: Tracking = JSON.parse(JSON.stringify(cancelOrderTrace));
    trace.tracing.data.isStartOfTrace = isStartOfTrace;
    trace.tracing.data.isEndOfTrace = isEndOfTrace;
    trace.tracing.data.attributes.order = {
      orderId: payload.order.orderHeader.orderId,
      origOrderId: payload.order.orderHeader.origOrderId,
      origSplitOrderId: payload.order.orderHeader.origSplitOrderId,
      totalAmount:
        Math.round(payload.order.orderHeader.totalDollars * 100) / 100,
      requestedDeliveryDate: payload.order.orderHeader.requestedDeliveryDate,
      confirmedDeliveryDate: payload.order.orderHeader.confirmedDeliveryDate,
    };
    trace.tracing.data.attributes.error = {
      details:
        payload.error || payload.order?.orderHeader?.errorDetails || undefined,
    };
    trace.tracing.data.attributes.products = payload.order.orderItems
      ? SharedTrackingService.tracingProductData(
          payload.order.orderItems,
          productMap,
        )
      : [];
    trace.tracing.transformFunc = genericTracingTransformer;
    return OrderTracingService.deepRemoveUndefined(trace);
  }

  trackGetOrderDetailClick(
    orderId: string,
    tandemOrderId: number,
    orderTotal: number,
    requestedDeliveryDate: string | Date,
    confirmedDeliveryDate: string | Date,
  ): void {
    let tracking = JSON.parse(JSON.stringify(getOrderDetailTrace));
    tracking.tracing.data.isEndOfTrace = true;
    tracking.tracing.data.isStartOfTrace = true;
    tracking.tracing.data.attributes.order.orderId = orderId;
    tracking.tracing.data.attributes.order.tandemOrderNumber = tandemOrderId;
    tracking.tracing.data.attributes.order.requestedDeliveryDate =
      requestedDeliveryDate;
    tracking.tracing.data.attributes.order.confirmedDeliveryDate =
      confirmedDeliveryDate;
    tracking.tracing.data.attributes.order.totalAmount = orderTotal;
    tracking.tracing.transformFunc = genericTracingTransformer;

    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceGetOrderDetailClick({
        tracking,
      }),
    );
  }

  traceOrderPageLoad = (
    orderId: string,
    origOrderId: string,
    origSplitOrderId: string,
    orderTotal: number,
    orderItems: OrderItem[],
    requestedDeliveryDate: string | Date,
    confirmedDeliveryDate: string | Date,
    tandemOrderNumber?: number,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              totalAmount: orderTotal,
              origOrderId: origOrderId,
              origSplitOrderId: origSplitOrderId,
              requestedDeliveryDate: requestedDeliveryDate,
              confirmedDeliveryDate: confirmedDeliveryDate,
              tandemOrderNumber: tandemOrderNumber,
            },
            event: OrderTracingMessages.reviewOrderPageLoad,
            products: orderItems,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(ORDER_TRACING_ACTIONS.traceOrderPageLoad({ tracking }));
  };

  traceIPOrderCancel = (
    orderId: string,
    orderTotal: number,
    orderItems: OrderItem[],
    tandemOrderNumber?: number,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: false,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              totalAmount: orderTotal,
            },
            event: OrderTracingMessages.cancelIPOrder,
            products: orderItems,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(ORDER_TRACING_ACTIONS.traceCancelIPOrder({ tracking }));
  };
  traceDyfBannerClick = (
    orderId: string,
    tandemOrderNumber: number,
    resultRank: number,
    product: Product,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              tandemOrderNumber: tandemOrderNumber,
            },
            event: OrderTracingMessages.dyfBannerClick,
            results: {
              resultRank: resultRank,
            },
            product: {
              divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
              attributes: product?.trackingAttributes,
            },
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceDyfBannerClick({ tracking }),
    );
  };
  traceInspiredPicksBannerClick = (
    orderId: string,
    tandemOrderNumber: number,
    resultRank: number,
    product: Product,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              tandemOrderNumber: tandemOrderNumber,
            },
            event: OrderTracingMessages.inspiredPicksBannerClick,
            results: {
              resultRank: resultRank,
            },
            product: {
              divisionApn: `${product?.summary?.divisionNumber}-${product?.summary?.productNumber}`,
              attributes: product?.trackingAttributes,
            },
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceInspiredPicksBannerClick({ tracking }),
    );
  };
  traceOrderCardClicked = (
    orderId: string,
    origOrderId: string,
    origSplitOrderId: string,
    orderTotal: number,
    tandemOrderNumber: number,
    cusNumber: number,
    depNumber: number,
    divNumber: number,
    requestedDeliveryDate: string | Date,
    confirmedDeliveryDate: string | Date,
    isCustomerOrderCard: boolean,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              origOrderId: !!origOrderId ? origOrderId : '',
              origSplitOrderId: !!origSplitOrderId ? origSplitOrderId : '',
              totalAmount: orderTotal,
              tandemOrderNumber: tandemOrderNumber,
              customerNumber: cusNumber,
              departmentNumber: depNumber,
              divisionNumber: divNumber,
              requestedDeliveryDate: requestedDeliveryDate,
              confirmedDeliveryDate: confirmedDeliveryDate,
            },
            event: isCustomerOrderCard
              ? OrderTracingMessages.orderCardClickedOnOrderStatus
              : OrderTracingMessages.orderCardClickedOnMyOrders,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      isCustomerOrderCard
        ? ORDER_TRACING_ACTIONS.traceOrderCardClickedOnOrderStatus({ tracking })
        : ORDER_TRACING_ACTIONS.traceOrderCardClickedOnMyOrders({ tracking }),
    );
  };

  traceOrderCardClickedOnPastOrders = (
    orderId: string,
    origOrderId: string,
    origSplitOrderId: string,
    orderTotal: number,
    tandemOrderNumber: number,
    cusNumber: number,
    depNumber: number,
    divNumber: number,
    requestedDeliveryDate: string | Date,
    confirmedDeliveryDate: string | Date,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              origOrderId: !!origOrderId ? origOrderId : '',
              origSplitOrderId: !!origSplitOrderId ? origSplitOrderId : '',
              totalAmount: orderTotal,
              tandemOrderNumber: tandemOrderNumber,
              customerNumber: cusNumber,
              departmentNumber: depNumber,
              divisionNumber: divNumber,
              requestedDeliveryDate: requestedDeliveryDate,
              confirmedDeliveryDate: confirmedDeliveryDate,
            },
            event: OrderTracingMessages.orderCardClickedOnPastOrders,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceOrderCardClickedOnPastOrders({ tracking }),
    );
  };

  traceCancelCopyOrderModal = (orderheader: OrderHeader) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderheader?.orderId,
              origOrderId: !!orderheader?.origOrderId
                ? orderheader?.origOrderId
                : '',
              origSplitOrderId: !!orderheader?.origSplitOrderId
                ? orderheader?.origSplitOrderId
                : '',
              totalAmount: orderheader?.totalPrice,
              tandemOrderNumber: orderheader?.tandemOrderNumber,
              customerNumber: orderheader?.customerNumber,
              departmentNumber: orderheader?.departmentNumber,
              divisionNumber: orderheader?.divisionNumber,
              requestedDeliveryDate: orderheader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderheader?.confirmedDeliveryDate,
            },
            event: OrderTracingMessages.cancelCopyOrderModal,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceCancelCopyOrderModal({ tracking }),
    );
  };

  traceCopyOrderClickedOnModal = (orderheader: OrderHeader) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderheader?.orderId,
              origOrderId: !!orderheader?.origOrderId
                ? orderheader?.origOrderId
                : '',
              origSplitOrderId: !!orderheader?.origSplitOrderId
                ? orderheader?.origSplitOrderId
                : '',
              totalAmount: orderheader?.totalPrice,
              tandemOrderNumber: orderheader?.tandemOrderNumber,
              customerNumber: orderheader?.customerNumber,
              departmentNumber: orderheader?.departmentNumber,
              divisionNumber: orderheader?.divisionNumber,
              requestedDeliveryDate: orderheader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderheader?.confirmedDeliveryDate,
            },
            event: OrderTracingMessages.copyOrderClickedOnModal,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceCopyOrderClickedOnModal({ tracking }),
    );
  };

  traceEditOrderButtonClicked = (
    orderId: string,
    origOrderId: string,
    origSplitOrderId: string,
    orderTotal: number,
    tandemOrderNumber: number,
    cusNumber: number,
    depNumber: number,
    divNumber: number,
    requestedDeliveryDate: string | Date,
    confirmedDeliveryDate: string | Date,
    isOrderStatusPage: boolean,
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId,
              origOrderId: !!origOrderId ? origOrderId : '',
              origSplitOrderId: !!origSplitOrderId ? origSplitOrderId : '',
              totalAmount: orderTotal,
              tandemOrderNumber: tandemOrderNumber,
              customerNumber: cusNumber,
              departmentNumber: depNumber,
              divisionNumber: divNumber,
              requestedDeliveryDate: requestedDeliveryDate,
              confirmedDeliveryDate: confirmedDeliveryDate,
            },
            event: isOrderStatusPage
              ? OrderTracingMessages.editOrderButtonClickedOnOrderStatus
              : OrderTracingMessages.editOrderButtonClickedOnMyOrders,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      isOrderStatusPage
        ? ORDER_TRACING_ACTIONS.traceEditOrderButtonClickedOnOrderStatus({
            tracking,
          })
        : ORDER_TRACING_ACTIONS.traceEditOrderButtonClickedOnMyOrders({
            tracking,
          }),
    );
  };
  traceOrderCPROverrideCall = (
    orderHeader: OrderHeader,
    productTracingData: TracingProduct[],
    isManualApproval?: boolean,
  ) => {
    const origOrderId = orderHeader.origOrderId;
    const origSplitOrderId = orderHeader.origSplitOrderId;
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: false,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderHeader.orderId,
              origOrderId: !!origOrderId ? origOrderId : '',
              origSplitOrderId: !!origSplitOrderId ? origSplitOrderId : '',
              totalAmount: orderHeader.totalPrice,
              tandemOrderNumber: orderHeader.tandemOrderNumber,
              customerNumber: orderHeader.customerNumber,
              departmentNumber: orderHeader.departmentNumber,
              divisionNumber: orderHeader.divisionNumber,
              requestedDeliveryDate: orderHeader.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader.confirmedDeliveryDate,
            },
            products: productTracingData,

            event: !isManualApproval
              ? OrderTracingMessages.orderCPROverrideCall
              : OrderTracingMessages.orderCPRManualOverrideCall,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      !isManualApproval
        ? ORDER_TRACING_ACTIONS.traceOrderCprOverrideCall({ tracking })
        : ORDER_TRACING_ACTIONS.traceOrderCprManualOverrideCall({ tracking }),
    );
  };
  traceOrderCPROverrideCallSuccess = (
    orderHeader: OrderHeader,
    errorDetails,
    productTracingData: TracingProduct[],
    isManualApproval?: boolean,
  ) => {
    const origOrderId = orderHeader.origOrderId;
    const origSplitOrderId = orderHeader.origSplitOrderId;
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: false,
          attributes: {
            order: {
              orderId: orderHeader.orderId,
              origOrderId: !!origOrderId ? origOrderId : '',
              origSplitOrderId: !!origSplitOrderId ? origSplitOrderId : '',
              totalAmount: orderHeader.totalPrice,
              tandemOrderNumber: orderHeader.tandemOrderNumber,
              customerNumber: orderHeader.customerNumber,
              departmentNumber: orderHeader.departmentNumber,
              divisionNumber: orderHeader.divisionNumber,
              requestedDeliveryDate: orderHeader.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader.confirmedDeliveryDate,
              errorDetails: errorDetails,
            },
            products: productTracingData,

            event: !isManualApproval
              ? OrderTracingMessages.orderCPROverrideCall
              : OrderTracingMessages.orderCPRManualOverrideCall,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      !isManualApproval
        ? ORDER_TRACING_ACTIONS.traceOrderCprOverrideCallSuccess({ tracking })
        : ORDER_TRACING_ACTIONS.traceOrderCprManualOverrideCallSuccess({
            tracking,
          }),
    );
  };
  traceOrderCPROverrideCallFailure = (
    orderHeader: OrderHeader,
    errorDetails,
    productTracingData: TracingProduct[],
    isManualApproval?: boolean,
  ) => {
    const origOrderId = orderHeader.origOrderId;
    const origSplitOrderId = orderHeader.origSplitOrderId;
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: false,
          attributes: {
            order: {
              orderId: orderHeader.orderId,
              origOrderId: !!origOrderId ? origOrderId : '',
              origSplitOrderId: !!origSplitOrderId ? origSplitOrderId : '',
              totalAmount: orderHeader.totalPrice,
              tandemOrderNumber: orderHeader.tandemOrderNumber,
              customerNumber: orderHeader.customerNumber,
              departmentNumber: orderHeader.departmentNumber,
              divisionNumber: orderHeader.divisionNumber,
              requestedDeliveryDate: orderHeader.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader.confirmedDeliveryDate,
              errorDetails: errorDetails,
            },
            products: productTracingData,

            event: !isManualApproval
              ? OrderTracingMessages.orderCPROverrideCall
              : OrderTracingMessages.orderCPRManualOverrideCall,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      !isManualApproval
        ? ORDER_TRACING_ACTIONS.traceOrderCprOverrideCallFail({ tracking })
        : ORDER_TRACING_ACTIONS.traceOrderCprManualOverrideCallFail({
            tracking,
          }),
    );
  };

  traceResolveButtonClickedOnHomepage(orderHeader: OrderHeader) {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderHeader?.orderId,
              totalAmount: orderHeader?.totalPrice,
              tandemOrderNumber: orderHeader.tandemOrderNumber,
              origOrderId: !!orderHeader?.origOrderId
                ? orderHeader.origOrderId
                : '',
              origSplitOrderId: !!orderHeader?.origSplitOrderId
                ? orderHeader.origSplitOrderId
                : '',
              customerNumber: orderHeader?.customerNumber,
              departmentNumber: orderHeader?.departmentNumber,
              divisionNumber: orderHeader?.divisionNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
            event: OrderTracingMessages.resolveExceptionsFromHomepageTile,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceResolveButtonClickedOnHomepage({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  traceGoToCartClickedOnHomepage(orderHeader: OrderHeader) {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderHeader?.orderId,
              totalAmount: orderHeader?.totalPrice,
              origOrderId: !!orderHeader?.origOrderId
                ? orderHeader.origOrderId
                : '',
              origSplitOrderId: !!orderHeader?.origSplitOrderId
                ? orderHeader.origSplitOrderId
                : '',
              customerNumber: orderHeader?.customerNumber,
              departmentNumber: orderHeader?.departmentNumber,
              divisionNumber: orderHeader?.divisionNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
            event: OrderTracingMessages.goToCartOnHomepageTile,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceGoToCartClickedOnHomepage({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  trackSubmitOrderStart = (orderHeader: OrderHeader | any) => {
    const orderId = orderHeader?.orderId;
    const origOrderId = orderHeader?.origOrderId;
    const origSplitOrderId = orderHeader?.origSplitOrderId;
    const order = {
      orderId: orderId,
      origOrderId: origOrderId || undefined,
      origSplitOrderId: origSplitOrderId || undefined,
      totalAmount: orderHeader?.totalPrice,
      tandemOrderNumber: orderHeader?.tandemOrderNumber,
      customerNumber: orderHeader?.customerNumber,
      departmentNumber: orderHeader?.departmentNumber,
      divisionNumber: orderHeader?.divisionNumber,
      requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
      confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
      numberOfShipments: orderHeader?.numberOfShipments,
    };
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: 'order',
          isEndOfTrace: false,
          isStartOfTrace: true,
          attributes: {
            order,
            event: !!orderHeader?.tandemOrderNumber
              ? OrderTracingMessagesTest.editOrderEvent
              : OrderTracingMessagesTest.submitOrderEvent,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    sessionStorage.setItem(
      'submitOrderTrace',
      JSON.stringify([order, false, false]),
    );
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceSubmitOrderStart({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  };

  trackSubmitOrderApiResponse = (
    orderHeader: OrderHeader,
    productTracingData: TracingProduct[],
  ) => {
    const orderId = orderHeader?.orderId;
    const origOrderId = orderHeader?.origOrderId;
    const origSplitOrderId = orderHeader?.origSplitOrderId;
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: 'order',
          isEndOfTrace: false,
          isStartOfTrace: false,
          attributes: {
            order: {
              orderId: orderId || undefined,
              origOrderId: origOrderId || undefined,
              origSplitOrderId: origSplitOrderId || undefined,
              totalAmount: orderHeader?.totalPrice,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              customerNumber: orderHeader?.customerNumber,
              departmentNumber: orderHeader?.departmentNumber,
              divisionNumber: orderHeader?.divisionNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
            products: productTracingData,

            event: !!orderHeader?.tandemOrderNumber
              ? OrderTracingMessagesTest.editOrderEvent
              : OrderTracingMessagesTest.submitOrderEvent,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      !!orderHeader?.tandemOrderNumber
        ? ORDER_TRACING_ACTIONS.traceSubmitEditedOrderApiResponse({
            tracking: OrderTracingService.deepRemoveUndefined(tracking),
          })
        : ORDER_TRACING_ACTIONS.traceSubmitOrderApiResponse({
            tracking: OrderTracingService.deepRemoveUndefined(tracking),
          }),
    );
  };

  trackSubmitOrderApiFail(error: any) {
    const tracking: Tracking = {
      tracing: {
        data: {
          isEndOfTrace: true,
          isStartOfTrace: false,
          traceContext: 'order',
          attributes: {
            error: !!error ? error : {},
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceSubmitOrderApiResponseFail({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  trackMonetateSubmitOrder(order) {
    this.productAnalyticsService.trackMonetateSubmitOrder(order);
  }

  trackSubmitOrderWebsocket(trackingData: Tracking) {
    const tracking: Tracking = {
      tracing: {
        data: {
          ...trackingData.tracing.data,
          isEndOfTrace: true,
          isStartOfTrace: false,
          traceContext: 'order',
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.trackSubmitOrderWebsocket({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  trackModifyButtonClickFromHomePageTile(
    orderHeader: OrderHeader,
    orderItems: OrderItem[],
    products: Map<number, Product>,
  ) {
    const orderId = orderHeader?.orderId;
    const origOrderId = orderHeader?.origOrderId;
    const origSplitOrderId = orderHeader?.origSplitOrderId;
    const tracingProducts = SharedTrackingService.tracingProductData(
      orderItems,
      products,
    );
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderId || undefined,
              totalAmount: orderHeader?.totalPrice,
              origOrderId: origOrderId || undefined,
              origSplitOrderId: origSplitOrderId || undefined,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              customerNumber: orderHeader?.customerNumber,
              departmentNumber: orderHeader?.departmentNumber,
              divisionNumber: orderHeader?.divisionNumber,
              requestedDeliveryDate: orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: orderHeader?.confirmedDeliveryDate,
            },
            event: OrderTracingMessages.modifyLinkClickOnHomePageTile,
            products: tracingProducts,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceModifyLinkClickOnHomePageTile({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  trackCancelSubmittedOrderWebsocket(
    trackingData: Tracking,
    hasErrorDetails: boolean,
  ) {
    const tracking: Tracking = {
      tracing: {
        data: {
          ...trackingData.tracing.data,
          isEndOfTrace: true,
          isStartOfTrace: false,
          traceContext: OrderTracingMessages.cancelSubmittedOrderContext,
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      !hasErrorDetails
        ? ORDER_TRACING_ACTIONS.traceCancelSubmittedOrderWebsocketSuccess({
            tracking: OrderTracingService.deepRemoveUndefined(tracking),
          })
        : ORDER_TRACING_ACTIONS.traceCancelSubmittedOrderWebsocketFail({
            tracking: OrderTracingService.deepRemoveUndefined(tracking),
          }),
    );
  }

  trackSubmitImportOrderStart = (
    importOrdersToSubmit: ImportOrderTransaction[],
  ) => {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext:
            ImportOrderValidationTracingConstants.submitImportOrderContext,
          isEndOfTrace: false,
          isStartOfTrace: true,
          attributes: {
            order: {
              importedOrdersCount: importOrdersToSubmit?.length,
              importOrderId: importOrdersToSubmit[0]?.importOrderIdentifier,
            },
            event: ImportOrderValidationTracingConstants.submitImportOrderEvent,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      IMPORT_ORDER_VALIDATION_TRACING_ACTIONS.traceSubmitImportOrderStart({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  };

  trackSubmitImportOrderWebsocket(trackingData: Tracking) {
    const tracking: Tracking = {
      tracing: {
        data: {
          ...trackingData.tracing.data,
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      IMPORT_ORDER_VALIDATION_TRACING_ACTIONS.trackSubmitImportOrderWebsocket({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  trackQEProductSearch(
    qoeProduct: Product,
    alertMsg: string,
    isCpnFlow: boolean,
  ): void {
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            event: isCpnFlow
              ? OrderTracingMessages.qeCustomerProductSearch
              : OrderTracingMessages.qeProductSearch,
            order: {
              errorDetails: alertMsg,
            },
            products: [
              {
                apn:
                  qoeProduct?.productNumber ||
                  qoeProduct?.summary?.productNumber,
                attributes: qoeProduct?.trackingAttributes,
              },
            ],
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };

    if (isCpnFlow) {
      this.store.dispatch(
        ORDER_TRACING_ACTIONS.traceQECustomerProductSearch({
          tracking: OrderTracingService.deepRemoveUndefined(tracking),
        }),
      );
    } else {
      this.store.dispatch(
        ORDER_TRACING_ACTIONS.traceQEProductSearch({
          tracking: OrderTracingService.deepRemoveUndefined(tracking),
        }),
      );
    }
  }

  traceConfirmUQEButtonClickedOnOrderPage(
    orderHeader: OrderHeader,
    orderItem: OrderItem,
    products: Map<number, Product>,
  ) {
    const tracingProducts = SharedTrackingService.tracingProductData(
      [orderItem],
      products,
    );
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.capability,
          isEndOfTrace: true,
          isStartOfTrace: true,
          attributes: {
            order: {
              orderId: orderHeader?.orderId,
              tandemOrderNumber: orderHeader?.tandemOrderNumber,
              origOrderId: !!orderHeader?.origOrderId
                ? orderHeader.origOrderId
                : '',
              origSplitOrderId: !!orderHeader?.origSplitOrderId
                ? orderHeader.origSplitOrderId
                : '',
            },
            products: tracingProducts,
            event: OrderTracingMessages.confirmUQEButtonClick,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };

    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceConfirmUQEButtonClickedOnOrderPage({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }
  trackSubmitVptOrderWebsocket(trackingData: Tracking) {
    const tracking: Tracking = {
      tracing: {
        data: {
          ...trackingData.tracing.data,
          isEndOfTrace: true,
          isStartOfTrace: false,
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceSubmitVptOrderWebsocketResponse({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }

  traceShipToConversionWebsocketResponse(
    order: Order,
    productMap: Map<number, Product>,
    isWebsocket: boolean,
  ) {
    const shipToId = sessionStorage.getItem('shipToConversionTrace');
    if (!shipToId) {
      return;
    } else {
      sessionStorage.removeItem('shipToConversionTrace');
    }
    const tracingProducts = SharedTrackingService.tracingProductData(
      order?.orderItems,
      productMap,
    );
    const tracking: Tracking = {
      tracing: {
        data: {
          traceContext: OrderTracingMessages.submitShipToOrder,
          isEndOfTrace: true,
          isStartOfTrace: false,
          attributes: {
            order: {
              isWebsocket,
              orderId: order?.orderHeader?.orderId,
              tandemOrderNumber: order?.orderHeader?.tandemOrderNumber,
              origOrderId: !!order?.orderHeader?.origOrderId
                ? order?.orderHeader.origOrderId
                : '',
              origSplitOrderId: !!order?.orderHeader?.origSplitOrderId
                ? order?.orderHeader.origSplitOrderId
                : '',
              totalAmount: order?.orderHeader?.totalPrice,
              requestedDeliveryDate: order?.orderHeader?.requestedDeliveryDate,
              confirmedDeliveryDate: order?.orderHeader?.confirmedDeliveryDate,
              errorDetails: order?.orderHeader?.errorDetails,
            },
            products: tracingProducts,
            event: OrderTracingMessages.submitShipToOrder,
          },
        },
        transformFunc: genericTracingTransformer,
      },
    };
    this.store.dispatch(
      ORDER_TRACING_ACTIONS.traceShipToConversionWebsocketResponse({
        tracking: OrderTracingService.deepRemoveUndefined(tracking),
      }),
    );
  }
}
