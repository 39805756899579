import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import {
  PlatformEnum,
  PlatformService,
  UsabillaService,
} from '@panamax/app-state';
import { combineLatest, Subscription } from 'rxjs';
import { getCustomers, selectedCustomer } from '@app/ngrx-customer/store';
import { Customer } from '@usf/customer-types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-invite-new-user-modal',
  templateUrl: './invite-new-user-modal.component.html',
  styleUrls: ['./invite-new-user-modal.component.scss'],
})
export class InviteNewUserModalComponent
  implements OnInit, OnDestroy, ViewWillEnter
{
  @Output() selectNewUserEmail = new EventEmitter<any>();
  @Output() dismissEvent: EventEmitter<void> = new EventEmitter();
  @Output() mobileNewUserInfoSelected = new EventEmitter<boolean>();
  @Output() mobileNewCustomersSelected = new EventEmitter<boolean>();

  SECTIONS = ['userInformation', 'customers'];
  MAX_CUSTOMERS = 100;

  inviteNewUserForm: FormGroup;

  selectedOption = '';
  searchKey = '';

  customers = [];
  searchedCustomers = [];
  selectedCustomer: Customer;

  result: {
    newUserEmail: string;
    customers: { customerNumber: number; divisionNumber: number }[];
  } = { newUserEmail: null, customers: [] };

  customers$: Subscription = new Subscription();

  isLoading = true;
  customersMaxLimitReached = false;

  constructor(
    readonly modalController: ModalController,
    public platform: PlatformService,
    public translateService: TranslateService,
    public store: Store,
    private usabillaService: UsabillaService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    if (!Capacitor.isNativePlatform()) {
      this.usabillaService.hide();
    }

    this.initForms();

    this.selectedOption =
      this.platform.platformType === PlatformEnum.mobile
        ? ''
        : 'userInformation';

    this.customers$ = combineLatest([
      this.store.select(getCustomers),
      this.store.select(selectedCustomer),
    ]).subscribe(([customers, selectedCustomer]) => {
      this.selectedCustomer = selectedCustomer;

      const selectedCustomerData = selectedCustomer
        ? {
            customerNumber: selectedCustomer.customerNumber,
            divisionNumber: selectedCustomer.divisionNumber,
          }
        : null;

      this.result.customers = selectedCustomerData
        ? [selectedCustomerData]
        : [];

      this.customers = customers.map(customer => {
        const isSelected = this.result.customers.some(
          selected =>
            selected.customerNumber === customer.customerNumber &&
            selected.divisionNumber === customer.divisionNumber,
        );

        return {
          ...customer,
          selected: isSelected,
        };
      });

      this.searchedCustomers = this.customers;
      this.isLoading = false;
    });
  }

  public initForms() {
    this.inviteNewUserForm = this.fb.group({
      newUserEmailAddress: [
        '',
        [Validators.required, Validators.email, Validators.maxLength(80)],
      ],
    });
  }

  searchCustomer(event: any) {
    this.searchKey = event?.target?.value || '';
    if (this.searchKey !== '') {
      this.searchedCustomers = this.customers
        .filter(
          c =>
            !this.searchKey ||
            c.customerName
              .toLowerCase()
              .includes(this.searchKey.toLowerCase()) ||
            c.customerNumber.toString().includes(this.searchKey) ||
            c.divisionNumber.toString().includes(this.searchKey),
        )
        .map(customer => {
          const isSelected = this.result.customers.some(
            selected =>
              selected.customerNumber === customer.customerNumber &&
              selected.divisionNumber === customer.divisionNumber,
          );
          return {
            ...customer,
            selected: isSelected,
          };
        });
    } else {
      this.searchedCustomers = this.customers.map(customer => ({
        ...customer,
        selected: this.result.customers.some(
          selected =>
            selected.customerNumber === customer.customerNumber &&
            selected.divisionNumber === customer.divisionNumber,
        ),
      }));
    }
  }

  isCustomerChecked(customer: any) {
    return this.result.customers.some(
      c =>
        c.customerNumber === customer.customerNumber &&
        c.divisionNumber === customer.divisionNumber,
    );
  }

  toggleCustomerSelection(customer: any, event: Event): void {
    event.stopPropagation();
    const isSelected = !customer.selected;
    customer.selected = isSelected;

    this.updateCustomersSelected(
      customer.customerNumber,
      customer.divisionNumber,
    );

    this.customers = this.customers.map(c => ({
      ...c,
      selected: this.result.customers.some(
        selected =>
          selected.customerNumber === c.customerNumber &&
          selected.divisionNumber === c.divisionNumber,
      ),
    }));

    this.searchedCustomers = this.searchedCustomers.map(c => ({
      ...c,
      selected: this.result.customers.some(
        selected =>
          selected.customerNumber === c.customerNumber &&
          selected.divisionNumber === c.divisionNumber,
      ),
    }));
  }

  updateCustomersSelected(customerNumber: number, divisionNumber: number) {
    const customerKey = { customerNumber, divisionNumber };
    const newCustomerIndex = this.result.customers.findIndex(
      c =>
        c.customerNumber === customerNumber &&
        c.divisionNumber === divisionNumber,
    );

    if (newCustomerIndex > -1) {
      this.result.customers.splice(newCustomerIndex, 1);
    } else {
      if (this.result.customers.length >= this.MAX_CUSTOMERS) {
        this.customersMaxLimitReached = true;
        return;
      }
      this.result.customers.push(customerKey);
    }

    this.customersMaxLimitReached =
      this.result.customers.length >= this.MAX_CUSTOMERS;
    this.selectNewUserEmail.emit(this.result);
  }

  getSingleCustomerSelectedName() {
    const selectedCustomer = this.result.customers[0];
    return this.customers.find(
      c =>
        c.customerNumber === selectedCustomer.customerNumber &&
        c.divisionNumber === selectedCustomer.divisionNumber,
    )?.customerName;
  }

  getSelectedCustomersCount() {
    return (
      this.translateService.instant('i18n.notifications.selected') +
      ': ' +
      this.result.customers.length
    );
  }

  setSelectedOption(option: string) {
    if (
      option === 'userInformation' &&
      this.platform.platformType === PlatformEnum.mobile
    ) {
      this.mobileNewUserInfoSelected.emit(true);
    } else if (
      option === 'customers' &&
      this.platform.platformType === PlatformEnum.mobile
    ) {
      this.mobileNewCustomersSelected.emit(true);
    }
    if (option === 'userInformation') {
      this.searchedCustomers = this.customers;
    }
    this.selectedOption = option;
  }

  resetSearch() {
    this.searchKey = '';
  }

  resetCustomerSearch() {
    const selectedCustomerNumber = this.selectedCustomer?.customerNumber;
    const selectedDivisionNumber = this.selectedCustomer?.divisionNumber;

    this.customers = this.customers.map(customer => {
      const isSelected =
        customer.customerNumber === selectedCustomerNumber &&
        customer.divisionNumber === selectedDivisionNumber;
      return {
        ...customer,
        selected: isSelected ? isSelected : false,
        disabled: false,
      };
    });

    this.searchedCustomers = this.customers.map(customer => ({
      ...customer,
      selected: this.result.customers.some(
        selected =>
          selected.customerNumber === customer.customerNumber &&
          selected.divisionNumber === customer.divisionNumber,
      ),
    }));

    this.result.customers =
      selectedCustomerNumber && selectedDivisionNumber
        ? [
            {
              customerNumber: selectedCustomerNumber,
              divisionNumber: selectedDivisionNumber,
            },
          ]
        : [];

    this.customersMaxLimitReached = false;
  }

  clearSelectedOption() {
    this.selectedOption = '';
    this.searchedCustomers = this.customers;
  }

  mobileUserInfoSelectedNext() {
    this.setSelectedOption('customers');
  }

  onInviteNewUser() {
    this.result.newUserEmail = this.inviteNewUserForm.get(
      'newUserEmailAddress',
    )?.value;
    this.selectNewUserEmail.emit(this.result);
  }

  dismiss() {
    this.modalController.dismiss();
  }

  ngOnDestroy() {
    this.customers$.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.resetSearch();
  }

  trackByCustomer(index: number, customer: any): number {
    return customer.customerNumber;
  }
}
