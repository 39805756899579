<ion-content
  class="works-well-with-modal-container"
  [scrollY]="isLandscapeTablet"
>
  <ion-header
    *ngIf="platform !== PlatformEnum.tablet"
    class="ion-no-border"
    [class.ion-header-modal-desktop]="platform === PlatformEnum.desktop"
    [class.ion-header-modal-mobile]="platform === PlatformEnum.mobile"
  >
    <ion-toolbar class="toolbar-container">
      <ion-buttons *ngIf="platform === PlatformEnum.desktop" slot="end">
        <ion-button
          id="close-modal-button"
          data-cy="simple-action-close-modal-icon"
          (click)="dismiss()"
        >
          <ion-icon slot="icon-only" name="close-outline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content
    class="product-information-table-container"
    [scrollY]="false"
    [ngClass]="platformService.getPlatformType() === platformService.platformEnum.mobile ? 'www-mobile-container' : 'www-tablet-container'"
  >
    <ion-grid class="product-information-table">
      <ion-row class="works-well-heading-row">
        <ion-col>
          <span class="works-well-heading">
            {{ "i18n.worksWellWith.worksWellWith" | translate }} ({{
            worksWellWithProducts?.length }})
          </span>
        </ion-col>
      </ion-row>
      <ion-row class="product-information-row">
        <ion-col class="product-information-column">
          <div class="original-product-image">
            <app-product-card-image
              [isOnline]="true"
              [imageThumbnail]="product?.imageThumbnail"
              [productNumber]="product?.productNumber"
              [clickable]="false"
            >
            </app-product-card-image>
          </div>
          <app-product-card-information
            class="original-product-information"
            [brand]="product?.summary?.brand"
            [description]="product?.summary?.productDescTxtl"
            [productNumber]="product?.productNumber"
            [salesPackSize]="product?.summary?.salesPackSize"
            [showNote]="false"
            [notFound]="product?.notFound"
            [customerProductNumber]="product?.customerProductNumber"
            [showPowerReview]="false"
            [price]="product?.pricing"
            [clickable]="false"
          >
          </app-product-card-information>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <hr
            class="solid"
            [ngClass]="this.platformService.getPlatformType() === this.platformService.platformEnum.tablet ? 'www-solid-tablet' : ''"
          />
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div
            class="works-well-with-carousel-content"
            [ngClass]="
            (platformService?.isTouchExperience$() | async)
              ? platformService?.platformType ===
                platformService?.platformEnum?.tablet
                ? 'tablet'
                : 'mobile'
              : 'desktop'
          "
          >
            <button
              *ngIf="(platformService?.platformType === platformService.platformEnum.desktop) && (platformService.isTouchExperience$() | async) === false"
              class="previous-arrow"
              [ngClass]="
              recentSwiperRef?.swiper?.isBeginning ? 'swiper-button-disabled' : ''
            "
              (click)="recentSwiperRef?.swiper?.slidePrev()"
            >
              <ion-icon
                slot="icon-only"
                name="arrow-back-circle-outline"
                data-cy="swiper-back-icon"
              ></ion-icon>
            </button>
            <swiper-container
              [hidden]="!(platformService.platformType === platformService.platformEnum.desktop)"
              #recentSwiperRef
              navigation-prev-el=".previous-arrow"
              navigation-next-el=".next-arrow"
              resize-observer="true"
              observer="true"
              slides-per-view="auto"
              center-insufficient-slides="true"
              centered-slids="true"
              data-cy="card-swiper"
              class="www-swiper"
            >
              <swiper-slide
                *ngFor="
                let product of worksWellWithProducts;
                let index = index
              "
              >
                <app-product-detail-card
                  [productDetails]="product"
                  [navigationDisabled]="false"
                  (quantityUpdateEmitter)="orderQuantityUpdated($event, index)"
                  (initializedElementEmitter)="emitProductCardInitialization($event)"
                  (clickEventEmitter)="productDetailCardClick()"
                  [carouselPosition]="index + 1"
                  [linkSource]="linkSource"
                  [merchFeature]="WORKS_WELL_WITH_FEATURE_NAME"
                  [addMethod]="WORKS_WELL_WITH_FEATURE_NAME"
                  [showPills]="true"
                  [parentApn]="parentApn"
                >
                </app-product-detail-card>
              </swiper-slide>
            </swiper-container>
            <swiper-container
              [hidden]="!(platformService.platformType === platformService.platformEnum.tablet)"
              #recentSwiperRef
              resize-observer="true"
              observer="true"
              slides-per-view="auto"
              center-insufficient-slides="true"
              data-cy="card-swiper"
              class="www-swiper"
              (touchmove)="scrollBlock($event)"
            >
              <swiper-slide
                *ngFor="
                let product of worksWellWithProducts;
                let index = index
              "
                class="product-card-slide"
              >
                <app-product-detail-card
                  [productDetails]="product"
                  [navigationDisabled]="false"
                  (quantityUpdateEmitter)="orderQuantityUpdated($event, index)"
                  (initializedElementEmitter)="emitProductCardInitialization($event)"
                  (clickEventEmitter)="productDetailCardClick()"
                  [carouselPosition]="index + 1"
                  [linkSource]="linkSource"
                  [merchFeature]="WORKS_WELL_WITH_FEATURE_NAME"
                  [addMethod]="WORKS_WELL_WITH_FEATURE_NAME"
                  [useQuantityButtons]="true"
                  [showPills]="true"
                  [parentApn]="parentApn"
                >
                </app-product-detail-card>
              </swiper-slide>
            </swiper-container>
            <ion-content
              class="mobile-card-scroll-container"
              (touchmove)="scrollBlock($event)"
            >
              <span
                *ngFor="
            let product of worksWellWithProducts;
            let index = index"
                class="mobile-product-card-container"
              >
                <app-usf-product-card-touch
                  [vm]="{
              mode: UsfProductCardModeEnum.worksWellWith,
              product: product,
              index: index,
              pricingDisabled: false,}"
                  (quantityUpdateEmitter)="orderQuantityUpdated($event.quantityUpdate, index)"
                  (productCardClickEmitter)="clickCard($event)"
                  [showAddToList]="true"
                  [useOrderObservable]="true"
                >
                </app-usf-product-card-touch>
              </span>
              <span
                *ngIf="this.worksWellWithProducts?.length > 1"
                class="spacer"
              ></span>
            </ion-content>

            <button
              *ngIf="(platformService?.platformType === platformService.platformEnum.desktop) && (platformService?.isTouchExperience$() | async) === false"
              class="next-arrow"
              [ngClass]="recentSwiperRef?.swiper?.isEnd ? 'swiper-button-disabled' : ''"
              (click)="recentSwiperRef?.swiper?.slideNext()"
            >
              <ion-icon
                slot="icon-only"
                name="arrow-forward-circle-outline"
                data-cy="forward-swiper-icon"
              ></ion-icon>
            </button>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>

  <ion-footer
    *ngIf="platformService.platformType !== platformService.platformEnum.desktop"
    class="works-well-with-modal-footer"
  >
    <ion-button
      *ngIf="(platformService.platformType === platformService.platformEnum.mobile || platformService.platformType === platformService.platformEnum.tablet)"
      (click)="dismiss()"
      class="usf-fill-green-button"
    >
      {{ 'i18n.common.close' | translate}}
    </ion-button>
  </ion-footer>
</ion-content>
