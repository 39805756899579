import { Injectable } from '@angular/core';
import { DownloadPrintBaseService } from '../download-print-base.service';
import { DatePipe } from '@angular/common';

import { stringifyCSV } from '../helpers/download-inventory-helpers';
import { downloadableConversionProduct } from '@shared/models/product-conversion.model';
import { desiredColumn } from '@shared/models/desired-column';
import { DownloadRequestFileType } from '@usf/list-types';
import { SeperatedColumn } from '@shared/models/seperated-columns';
import { documentFunctionMap } from '../helpers/download-product-helper';
import { productConversionColumns } from '../helpers/document-helper-models-and-enums';

@Injectable({
  providedIn: 'root',
})
export class DownloadProductsService {
  constructor(
    private downloadPrintBaseService: DownloadPrintBaseService,
    public datePipe: DatePipe,
  ) {}

  public downloadProducts = async (
    fileType: DownloadRequestFileType,
    fileName: string,
    donwloadProductsRow: downloadableConversionProduct[],
    isDownload: boolean,
  ) => {
    try {
      switch (fileType) {
        case 'CSV':
          await this.generateAndSaveCSV(
            fileName,
            donwloadProductsRow,
            isDownload,
          );
          break;
        default:
          this.downloadPrintBaseService.cleanUp();
          break;
      }
    } catch (error) {
      this.downloadPrintBaseService.cleanUp();
      // Update error toast
      this.downloadPrintBaseService.showErrorToast(
        isDownload ? 'i18n.lists.downloadError' : 'i18n.lists.printListError',
      );
      console.error('Error creating file', error);
    }
  };

  generateAndSaveCSV = async (
    fileName: string,
    listRows: downloadableConversionProduct[],
    isDownload: boolean,
  ) => {
    const csv = this.generateCSV(listRows);
    await this.downloadPrintBaseService.saveDocument(
      csv,
      fileName,
      'CSV',
      isDownload,
    );
  };

  private generateCSV(productRows: downloadableConversionProduct[]) {
    const columnsConfig = this.getConversionCSVConfig();
    const seperatedColumns =
      this.createMultipleSeperatedColumnsForConversionProducts(
        productRows,
        columnsConfig,
        'CSV',
      );
    return stringifyCSV(seperatedColumns);
  }

  createMultipleSeperatedColumnsForConversionProducts(
    products: downloadableConversionProduct[],
    columnConfig: desiredColumn[],
    fileType: DownloadRequestFileType,
  ): SeperatedColumn[] {
    let columnMap = new Map<string, SeperatedColumn>();

    products.forEach((productRow: downloadableConversionProduct) => {
      columnConfig.forEach(column => {
        columnMap = documentFunctionMap[column.columnType](
          column.columnName,
          columnMap,
          productRow,
          fileType,
        );
      });

      // add an empty line of data
      if (!productRow.isOriginal) {
        columnMap.forEach(col => {
          col.columnValues?.push('""');
        });
      }
    });

    let seperatedColumns: SeperatedColumn[] = [];
    columnMap.forEach(column => {
      seperatedColumns.push(column);
    });
    return seperatedColumns;
  }

  // Configs:
  getConversionCSVConfig = (): desiredColumn[] => {
    const seperatedColumns = [
      {
        columnName: '',
        columnType: productConversionColumns.isOriginal,
      },
      {
        columnName: 'PRODUCT NUMBER',
        columnType: productConversionColumns.productNumber,
      },
      {
        columnName: 'PRODUCT DESCRIPTION',
        columnType: productConversionColumns.productDescription,
      },
      {
        columnName: 'BRAND NAME',
        columnType: productConversionColumns.productBrand,
      },
      {
        columnName: 'PACK SIZE',
        columnType: productConversionColumns.productPackageSize,
      },
      {
        columnName: 'CS / LB PRICE',
        columnType: productConversionColumns.casePrice,
      },
      {
        columnName: 'UOM',
        columnType: productConversionColumns.caseUOM,
      },
      {
        columnName: 'EA PRICE',
        columnType: productConversionColumns.eachPrice,
      },
      // can't have two of the same column name
      {
        columnName: 'UOM ',
        columnType: productConversionColumns.eachUOM,
      },
      {
        columnName: 'PORTION PRICE',
        columnType: productConversionColumns.portionPrice,
      },
      {
        columnName: 'PRODUCT STATUS CODE',
        columnType: productConversionColumns.statusCode,
      },
      {
        columnName: 'PRODUCT STATUS DESCRIPTION',
        columnType: productConversionColumns.productStatus,
      },
      {
        columnName: 'UPDATED LISTS',
        columnType: productConversionColumns.updatedLists,
      },
      {
        columnName: 'MLM PRODUCT',
        columnType: productConversionColumns.mlmProduct,
      },
      {
        columnName: 'STORAGE CODE',
        columnType: productConversionColumns.storageCode,
      },
      {
        columnName: 'USF STORAGE',
        columnType: productConversionColumns.usfStorage,
      },
      {
        columnName: 'NET WEIGHT (lb)',
        columnType: productConversionColumns.netWeight,
      },
      {
        columnName: 'CONTRACT ICON',
        columnType: productConversionColumns.contractIcon,
      },
      {
        columnName: 'LOCAL',
        columnType: productConversionColumns.local,
      },
      {
        columnName: 'JIT',
        columnType: productConversionColumns.jit,
      },
      {
        columnName: 'DIRECT',
        columnType: productConversionColumns.direct,
      },
      // {
      //   columnName: 'CUSTOMER MANAGED PILL',
      //   columnType: productConversionColumns.customerPill,
      // },
      {
        columnName: 'PRODUCT CLASS #',
        columnType: productConversionColumns.classNum,
      },
      {
        columnName: 'PRODUCT CLASS',
        columnType: productConversionColumns.classDesc,
      },
      {
        columnName: 'PRODUCT CATEGORY #',
        columnType: productConversionColumns.categoryNum,
      },
      {
        columnName: 'PRODUCT CATEGORY',
        columnType: productConversionColumns.categoryDesc,
      },
      {
        columnName: 'PRODUCT GROUP #',
        columnType: productConversionColumns.groupNum,
      },
      {
        columnName: 'PRODUCT GROUP',
        columnType: productConversionColumns.groupDesc,
      },
      {
        columnName: 'SERVING SIZE',
        columnType: productConversionColumns.servingSize,
      },
      {
        columnName: 'SERVING SIZE UOM',
        columnType: productConversionColumns.ServingUOM,
      },
      {
        columnName: 'SERVINGS PER TRADE ITEM',
        columnType: productConversionColumns.servingPerTrade,
      },
      {
        columnName: 'CALORIES',
        columnType: productConversionColumns.calories,
      },
      {
        columnName: 'CALORIES FROM FAT (g)',
        columnType: productConversionColumns.caloriesFat,
      },
      {
        columnName: 'TOTAL FAT (g)',
        columnType: productConversionColumns.totalFat,
      },
      {
        columnName: 'SATURATED FAT (g)',
        columnType: productConversionColumns.satFat,
      },
      {
        columnName: 'TRANS FATTY ACIIDS (g)',
        columnType: productConversionColumns.transFat,
      },
      {
        columnName: 'CHOLESTEROL (mg)',
        columnType: productConversionColumns.cholest,
      },
      {
        columnName: 'SODIUM (mg)',
        columnType: productConversionColumns.sodium,
      },
      {
        columnName: 'TOTAL CARBOHYDRATES (g)',
        columnType: productConversionColumns.totalCarb,
      },
      {
        columnName: 'DIETRY FIBER (g)',
        columnType: productConversionColumns.dietFiber,
      },
      {
        columnName: 'TOTAL SUGAR (g)',
        columnType: productConversionColumns.totalSug,
      },
      {
        columnName: 'PROTEIN (g)',
        columnType: productConversionColumns.protein,
      },
      // {
      //   columnName: 'ALLERGENS - CRUSTACEAN',
      //   columnType: productConversionColumns.alCrust,
      // },
      // {
      //   columnName: 'ALLERGENS - EGGS',
      //   columnType: productConversionColumns.alEgg,
      // },
      // {
      //   columnName: 'ALLERGENS - FISH',
      //   columnType: productConversionColumns.alFish,
      // },
      // {
      //   columnName: 'ALLERGENS - MILK',
      //   columnType: productConversionColumns.alMilk,
      // }, {
      //   columnName: 'ALLERGENS - PEANUTS',
      //   columnType: productConversionColumns.alPeanuts,
      // },
      // {
      //   columnName: 'ALLERGENS - SOY',
      //   columnType: productConversionColumns.alSoy,
      // },
      // {
      //   columnName: 'ALLERGENS - TREE NUTS',
      //   columnType: productConversionColumns.alTreeNut,
      // },
      // {
      //   columnName: 'ALLERGENS - WHEAT',
      //   columnType: productConversionColumns.alWheat,
      // }, {
      //   columnName: 'ALLERGENS - MUSTARD',
      //   columnType: productConversionColumns.alMust,
      // },
      {
        columnName: 'CHILD NUTRITION CERTIFICATION',
        columnType: productConversionColumns.childNutrition,
      },
      {
        columnName: 'CHOLESTEROL FREE',
        columnType: productConversionColumns.cholestFree,
      },
      {
        columnName: 'FAT FREE',
        columnType: productConversionColumns.fatFree,
      },
      {
        columnName: 'LOW FAT',
        columnType: productConversionColumns.lowFat,
      },
      {
        columnName: 'MSG FREE',
        columnType: productConversionColumns.msgFree,
      },
      {
        columnName: 'NO SUGAR ADDED',
        columnType: productConversionColumns.noSug,
      },
      {
        columnName: 'TRANS FAT FREE',
        columnType: productConversionColumns.transFatFree,
      },
      {
        columnName: 'LOCALLY SOURCED',
        columnType: productConversionColumns.localSource,
      },
      {
        columnName: 'KOSHER',
        columnType: productConversionColumns.kosher,
      },
      {
        columnName: 'HALAL',
        columnType: productConversionColumns.halal,
      },
      {
        columnName: 'RECYCLABLE',
        columnType: productConversionColumns.recycle,
      },
      {
        columnName: 'CERTIFIED ANGUS BEEF',
        columnType: productConversionColumns.certBeef,
      },
      {
        columnName: 'ORGANIC',
        columnType: productConversionColumns.organic,
      },
      // {
      //   columnName: 'NO ADDED SYNTHETIC HORMONES',
      //   columnType: productConversionColumns.noAddedHorm,
      // },
      {
        columnName: 'ADDITIONAL INFO',
        columnType: productConversionColumns.addInfo,
      },
      {
        columnName: 'INGREDIIENTS STATEMENT',
        columnType: productConversionColumns.ingStatement,
      },
    ];
    return seperatedColumns;
  };
}
